import { Snackbar, Chip } from "@mui/material";
import { red } from "@mui/material/colors";
import React from "react";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import lang from "../../asset/Language.json"

export default function ErrorMessage(props){
    const {msg, error, setError} = props;

    return <Snackbar open={error} 
            autoHideDuration={5000}
            onClose={e => setError(false)}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Chip onClick={e => setError(false)} icon={<ErrorOutlineIcon style={{
                    color: "#fff"
                }}/>} label={msg || lang.lotrinh.generalError} style={{
                    backgroundColor: red[500],
                    color: "#fff"
                }}/>
                
            </Snackbar>
}