import { Dialog, TextField,DialogTitle, DialogContent,DialogActions, Button, DialogContentText } from "@mui/material"
import React, { useEffect, useState } from "react"
import { UserTypes  } from "../../Contants/UserTypeEnum";
import lang from "../../asset/Language.json";
import UserService from "../../services/UserService";
import red from '@mui/material/colors/red';


export default function CreateAccount(props){
    const {onClose, user, roleList, disabledRole, defaultRole} = props;
    const [usrName, setUsrName] = useState();
    const [pwd, setPwd] = useState();
    const [pwdConfirm, setPwdConfirm] = useState();
    const [role, setRole] = useState(defaultRole);
    const [invalidPwd, setInvalidPwd] = useState(false);
    const [invalidUser, setInvalidUser] = useState(false);
    const [pwdNotMatch, setPwdNotMatch] = useState(false);
    const [createAccount, setCreateAccount] = useState(false);
    const [createAccountFail, setCreateAccountFail] = useState(false);

    const onhandleInvalidUser = (onOk) =>{
        
        setInvalidUser(!usrName);
        const pwdOk = pwd && pwdConfirm && pwdConfirm === pwd && pwd.length > 7;
        setInvalidPwd(!pwdOk);

        if(onOk && pwdOk && usrName) {
            onOk();
        }
    }

    useEffect(async () => {
        if(!createAccount) {
            return;
        }
        setCreateAccount(false)
        const uSv = new UserService();
        const res = await uSv.createAccount({
            userName: usrName,
            password: pwd,
            refId: user.usrId,
            usrTypeId: parseInt(role || 6)
        });

        if(res.error) {
            setCreateAccountFail(true);
            return;
        }
        
        onClose();
        return () => {
           
        }
    },[createAccount]);

    return <Dialog open={true} >
        <DialogTitle id="draggable-dialog-title">
          {lang.StaffList.taotaikhoan}
          <br/>
          <b>{user.name}</b>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {createAccountFail && <span style={{
                color: red["A400"]
            }}>{lang.StaffList.taotaikhoanfail}</span>}
          <div className="flex-column">
                <TextField id="usrName" 
                onChange={e => setUsrName(e.target.value)} 
                error={invalidUser} type="email" 
                fullWidth={true} variant="outlined" 
                label={lang.LoginPage.UserName} 
                placeholder={lang.LoginPage.UserNamePlaceholder} 
                autoComplete="off"
                value={usrName}
                required={true}
                onBlur={e => setInvalidUser(!e.target.value)}
                onFocus={e => {setInvalidUser(false);}}
                />
                <span className="v-space"></span>
                <TextField id="password" 
                onInput={e=> setPwd(e.target.value) } 
                error={invalidPwd} type="password" fullWidth={true}
                variant="outlined" 
                label={lang.DangKyTaiKhoan.matkhau} 
                placeholder={lang.DangKyTaiKhoan["matkhau-placeholder"]} 
                required={true}
                autoComplete="off"
                onBlur={e => onhandleInvalidUser() }
                onFocus={e => { setInvalidPwd(false); setPwdNotMatch(false)}}/>
                <span className="v-space"></span>
                <TextField id="password2" 
                onInput={e=> setPwdConfirm(e.target.value) } 
                error={invalidPwd} type="password" fullWidth={true}
                variant="outlined" 
                label={lang.DangKyTaiKhoan.matkhaulai} 
                placeholder={lang.DangKyTaiKhoan["matkhau-placeholder"]} 
                required={true}
                autoComplete="off"
                onBlur={e => onhandleInvalidUser() }
                onFocus={e => { setInvalidPwd(false); setPwdNotMatch(false)}}/>
                <span className="v-space"></span>
                {!disabledRole && <TextField
                    id="dsrole"
                    select
                    label={lang.StaffList.quyen}
                    value= {role}
                    required={true}
                    onChange={e => setRole(e.target.value)}
                    SelectProps={{
                        native: true,
                    }}
                    helperText=""
                    variant="standard"
                    color="primary"
                    >
                    {(roleList || UserTypes).map((option) => (
                        <option key={option.key} value={option.key}>
                        {option.text}
                        </option>
                    ))}
                </TextField>}

            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button disabled={invalidPwd || invalidUser} variant="outlined" onClick={evt => onhandleInvalidUser(() => {
            setCreateAccount(true);
           // setRefresh(true);
        })}>{"OK"}</Button>
        <Button variant="outlined" autoFocus onClick={onClose}>
            {"CANCEL"}
          </Button>
          
        </DialogActions>
    </Dialog>
}