import { Stack } from "@mui/material"
import React, { useEffect, useState } from "react"
import lang from "../../../asset/Language.json"
import Dialog from "../../../Components/ConfirmDialog"
import SearchDropDown from "../../../Components/SearchDropDown";
import UserService from "../../../services/UserService";
import Icon from "@mui/icons-material/AccountCircle"
import { lightGreen } from "@mui/material/colors";
import KhuVucService from "../../../services/KhuVucService";

export default function KhuvucSaleAdmin(props){
    const { saleAdmin, open, setClose, setError,setRow, khuvuc } = props;
    
    const [dsSaleAdmin, setDsSaleAdmin] = useState([])
    const [admin, setAdmin] = useState()
    const [changed, setChanged] = useState()

    useEffect(async()=> {
        if(!changed){
            return;
        }
        setChanged()
        const sv = new KhuVucService();
        const req = {
            id: khuvuc.Id,
            admin: saleAdmin ? `${saleAdmin},${admin}`: admin.toString()
        }
        const r  = await sv.setKhuvucAdmin(req)
        if(r.error) {
            setError(true);
            return;
        }

        setRow(Object.assign(khuvuc, {
            SaleAdmin: r ? JSON.parse(r) : "",
            Admin: req.admin
        }));
        setClose({});
    },[changed])
      
    const content = <div style={{
        width:500,
        paddingTop:10
    }}>
        <Stack direction="column" spacing={3}>
           <SearchDropDown
           id="searchbox_saleAdmin"
            className="inp-fld"
            value={admin}
            label={lang.generaltext.saleAdmin}
            options= {dsSaleAdmin.map(t => ({
                key: t.usrId,
                text: t.name,
                secondary: t.email
            }))}
            onChange ={value => setAdmin(value)}
            icon ={<Icon style={{color: lightGreen[600]}}/>}
           />
           
        </Stack>

    </div>;

    useEffect(async() => {
        const usv = new UserService();
        const r = await usv.searchStaff(1,500, {
            chucvu: 2,
            cusId: 0
        });
        if(r.error) {
            setError(true);
            return;
        }
        if(saleAdmin) {
            r.result = r.result.filter(a => saleAdmin.split(',').indexOf(a.usrId.toString()) == -1)
        }
        setDsSaleAdmin(r.result);
    },[])

    return <Dialog
    className="dialog-a-visible"
    title={lang.generaltext.themSaleAdmin}
    open={open}
    disabledYes={!admin}
    okText ={lang.buttons.luu}
    cancelText={lang.buttons.boqua}
    msgContent = {content}
    onClose= {evt => setClose({})}
    onYes ={evt => setChanged(true)}
    />


}