import { Checkbox, Collapse, FormControlLabel, FormGroup } from "@mui/material"
import React, { useEffect, useState } from "react"
import KhuVucService from "../../services/KhuVucService";

export default function SubKhuvucCheckList(props){
    const {onChange, checked, open, parentId, setSelectedSubKVNames} = props;
    const [dskhuvuc, setDskhuvuc] = useState([])

    useEffect(async () => {
        if(!parentId){
            return;
        }
        const sv = new KhuVucService();
        const r = await sv.getAllSubKhuvucByKhuvuc(parentId)
        if(r.error){
            return;
        }
        setDskhuvuc(r);
    },[parentId])
    const handleToggle = (event) => {
        const {
            target: { value },
          } = event;
          const v = parseInt(value);
        const currentIndex = checked.indexOf(v);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
          newChecked.push(v);
        } else {
          newChecked.splice(currentIndex, 1);
        }
        //setSelectedSubKVNames(dskhuvuc.filter(k => newChecked.indexOf(k.id) > -1 ).map(k => k.ten))
        onChange(dskhuvuc.filter(k => newChecked.indexOf(k.id) > -1 ));
      };

    return <Collapse in={open} timeout="auto" unmountOnExit  style={{
        padding:"0 20px"
      }}>
    <FormGroup>
        {(dskhuvuc || []).map(s => <FormControlLabel control={<Checkbox key={s.id} value ={s.id} onChange={evt => handleToggle(evt)} checked={checked.indexOf(s.id) > -1} />} label={s.ten}/>)}
    </FormGroup>
    </Collapse>
}