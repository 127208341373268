import { InputAdornment, List, ListItem, ListItemAvatar, ListItemText, TextField } from "@mui/material";
import { useState,  useRef } from "react";
import clsx from 'clsx';
import ArrowDropdownIcon from "@mui/icons-material/ArrowDropDown"
import { grey } from "@mui/material/colors";

export default function SearchDropDown(props){
    const {options, onSearch, icon, onChange, className, style, id,value, label, searchable, disabled, fullWidth} = props;
    
    const [searchText, setSearchText] = useState("");
    const [activeIndex, setActiveIndex] = useState();
    const [keyCode, setKeyCode] = useState();
    const [selectedOption, setSelectedOption] = useState({})
    const [open, setOpen] = useState(false)
    const dropDownContent = useRef()
    const searchBox = useRef()
    const dropdown = useRef()

    const selectedO = options && options.length >  0 && value ? options.filter(o => o.key === value)[0] || {}: {};

    const handleClick = (event,  opt) => {
        event.preventDefault();
        onChange(opt.key);
        setSearchText();
        setSelectedOption(opt);
        setActiveIndex()
        setOpen(false);
    }

    const handleKeyDown= (event) => {
        if(event.keyCode === 40) {
            event.preventDefault();
            dropDownContent.current.focus();
            setActiveIndex(0);
            
        }
    }
    document.addEventListener("click", event => {
        const path = event.path || (event.composedPath && event.composedPath());
        if (!path.includes(dropDownContent.current) && !path.includes(searchBox.current) && !path.includes(dropdown.current)){
            setOpen(false)
        }
    });

    const searchOptions = searchText ? options.filter(o => o.text.toLowerCase().indexOf(searchText.toLowerCase())> -1) : options;

    return <div id={`searchable-box-${id || ""}`}  ref={dropdown} className={clsx("MuiFormControl-root MuiTextField-root dropdown",{
        [className || ""]: true
        })}
        style={style}
     >

        <TextField
            key={`searchbox-${id}`}
            label={label}
            autoComplete={false}
            autoCorrect={false}
            autoSave={false}
            disabled={disabled}
            fullWidth={fullWidth}
            variant="standard"
            value={searchText || selectedO.text || ""}
            onInput={e => {
                setActiveIndex(-1);
                setSelectedOption({});
                setSearchText(e.target.value);
            }}
            onKeyDown={e => handleKeyDown(e)}
            ref={searchBox}
            onFocus={evt => setOpen(true)}
            InputProps= {{
                endAdornment: disabled ? null : <InputAdornment position="end" onClick={e => setOpen(true)}><ArrowDropdownIcon style={{color: grey[600], cursor: "pointer"}}/></InputAdornment>,
            startAdornment: icon ? <InputAdornment position="start">{icon}</InputAdornment>: null
            }}
        />
        <div className={clsx({
            hidden: !open
        })} tabIndex={0} ref={dropDownContent} onKeyDown={event => {
            switch(event.keyCode) {
                case 40:
                    if(activeIndex < options.length -1) {
                        setActiveIndex(activeIndex + 1);
                    }
                    break;
                case 38:
                    if(activeIndex> 0) {
                        setActiveIndex(activeIndex - 1);
                    }
                    break;
                case 13:
                    setOpen(false)
                    if(activeIndex) {
                        const selectedOpt = searchOptions[activeIndex];
                        setSearchText(selectedOpt.text);
                        onChange(selectedOpt.key);
                    }
                    break;
            }
        }}>
        <List 
          className="dropdown-content">
            {searchOptions.map((o,idx) => <ListItem 
            tabIndex={idx + 1}
            onClick={evt => handleClick(evt, o, idx)} key={o.key} id={idx} className={idx === activeIndex || o.key === selectedOption.key || (!activeIndex && o.key === value) ? "active": ""} autoFocus={idx === activeIndex}>
                {icon != null && <ListItemAvatar>
                    {icon}
                </ListItemAvatar>}
                <ListItemText
                primary={o.text}
                secondary={o.secondary ? o.secondary: null}
                />
            </ListItem>)}
        </List>
        </div>
        
    </div>
}