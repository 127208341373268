import { Dialog, TextField,DialogTitle, DialogContent,DialogActions, Button, DialogContentText } from "@mui/material"
import { red } from "@mui/material/colors";
import React, { useEffect, useState } from "react"
import lang from "../../../asset/Language.json";
import UserService from "../../../services/UserService";


export default function ChangePassword(props){
    const {onClose, user} = props;
    const [pwd, setPwd] = useState();
    const [pwdConfirm, setPwdConfirm] = useState();
    const [invalidPwd, setInvalidPwd] = useState(false);
    const [pwdNotMatch, setPwdNotMatch] = useState(false);
    const [changePassword, setChangePassword] = useState(false);
    const [error, setError] = useState();
    const [changePasswordSuccess,setChangePasswordSuccess] = useState(false);
    
    const onhandleInvalidUser = (onOk) =>{
        const pwdOk = pwd && pwdConfirm && pwdConfirm === pwd && pwd.length > 7;
        setPwdNotMatch(pwdConfirm !== pwd);
        setInvalidPwd(!pwdOk);

        if(onOk && pwdOk) {
            onOk();
        }
    }
    const uSv = new UserService();
    const loggedUser = user ? JSON.parse(user)[0] : uSv.apiService.userLoggedIn;
    useEffect(async () => {
        if(!changePassword) {
            return;
        }
        const res = await uSv.resetPassword({
            userName: loggedUser.usrName,
            password: pwd,
            userId: parseInt(loggedUser.usrId)
        });
        
        if(res.error) {
            setError(true);
            return;
        }
        
        setChangePasswordSuccess(true);
        return () => {
           
        }
    },[changePassword]);
    

    return <Dialog open={true} >
        <DialogTitle id="draggable-dialog-title">
          {lang.generaltext.changepassword}
          <br/>
          <b>{loggedUser.usrName}</b>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {error && <span style={{
                color: red["A400"]
            }}>{lang.generaltext.changepasswordFail}</span>}
          {!changePasswordSuccess && <div className="flex-column">
                <TextField id="password" 
                onInput={e=> setPwd(e.target.value) } 
                error={invalidPwd} type="password" fullWidth={true}
                variant="outlined" 
                label={lang.DangKyTaiKhoan.matkhau} 
                placeholder={lang.DangKyTaiKhoan["matkhau-placeholder"]} 
                required={true}
                autoComplete="off"
                onBlur={e => onhandleInvalidUser() }
                onFocus={e => { setInvalidPwd(false); setPwdNotMatch(false)}}/>
                <span className="v-space"></span>
                <TextField id="password2" 
                onInput={e=> setPwdConfirm(e.target.value) } 
                error={invalidPwd} type="password" fullWidth={true}
                variant="outlined" 
                label={lang.DangKyTaiKhoan.matkhaulai} 
                placeholder={lang.DangKyTaiKhoan["matkhau-placeholder"]} 
                required={true}
                autoComplete="off"
                onBlur={e => onhandleInvalidUser() }
                onFocus={e => { setInvalidPwd(false); setPwdNotMatch(false)}}/>
            </div>}
            {changePasswordSuccess && <div>
                {lang.generaltext.changepasswordSuccess}
                </div>}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        {!changePasswordSuccess && <Button disabled={invalidPwd || !pwd || !pwdConfirm || pwdNotMatch} variant="outlined" onClick={evt => onhandleInvalidUser(() => {
            setChangePassword(true);
        })}>{"OK"}</Button>}
        <Button variant="outlined" autoFocus onClick={onClose}>
            {changePasswordSuccess ? "CLOSE" : "CANCEL"}
          </Button>
        </DialogActions>
    </Dialog>
}