export const Thoigian = (time1, time2) => {
    const startTime = new Date(time1);
    const endTime = new Date(time2);
    let m = startTime.getHours()*60 + startTime.getMinutes(); 
    let m1  = endTime.getHours()*60 + endTime.getMinutes();

    m1 += (endTime.getDate() - startTime.getDate())*24;

    let h = parseInt((m1*1 - m*1)/60);
    let hm = m1*1 - m*1 - h*60;

    return `${h < 10 ? ("0" + h) : h}:${hm<10 ? ("0" + hm): hm}`;
}

export const getStatus = (status) => {
    switch(status) {
        case "0":
        case 0:
            return "New";
        case "1":
        case 1:
            return "Confirm";
        case "2":
        case 2:
            return "Approved";
        case "3":
        case 3:
            return "Rejected";

    }
}