import { InputAdornment, TextField } from "@mui/material"
import React, { useEffect, useState } from "react"
import lang from "../../../asset/Language.json"
import SearchDropDown from "../../../Components/SearchDropDown";
import Dialog from "../../../Components/ConfirmDialog"
import KhuVucService from "../../../services/KhuVucService";
import LotrinhService from "../../../services/LotrinhService";


export default function DongiaBuoi(props){
    const {dgia, open, onClose, setClose, setError, dsKhuvuc, row, setRow} = props;
    const [khunggio,setKhunggio] = useState(dgia.khunggio);
    const [sobuoi1, setSobuoi1] = useState(dgia.sobuoi1)
    const [sobuoi2, setSobuoi2] = useState(dgia.sobuoi2)
    const [dongia, setDongia] = useState(dgia.dongia)
    const [khuvuc, setKhuvuc] = useState(dgia.khuvuc)
    const [refresh, setRefresh] = useState();
    const [dsKhunggio, setDsKhunggio] = useState([])
    const [dongiaChanged, setDongiaChanged] = useState(false)

    useEffect(async() => {
        if(!dongiaChanged) {
            return;
        }
        setDongiaChanged(false);
        const sv = new KhuVucService();
        const data = {
            id: dgia.Id,
            khuvuc: khuvuc,
            khunggio: khunggio,
            sobuoi1: sobuoi1,
            sobuoi2: sobuoi2,
            dongia: dongia
        }
        const r = dgia.Id ? await sv.updateDongia(data) : await sv.addDongia(data);
        if(r.error) {
            setError(true);
            return;
        }
        setRow(Object.assign(row, {
            Dongiabuoi: r
        }));
        setClose({});
    },[dongiaChanged])

    useEffect(async() =>{
        const sv = new LotrinhService();
        const kg = await sv.getAllKhunggio();
        if(kg.error) {
            setError(true);
            return;
        }
        setDsKhunggio(kg);
    },[refresh])

    const content = <div style={{
        width:250
    }}>
        <SearchDropDown
            id="dongia_buoi_khuvuc"
            className ="inp-fld"
            style={{
                marginRight:35,
                width:250
            }}
            label={lang.lotrinh.khuvuc} 
            disabled = {dgia.khuvuc}
            type="number"
            value={khuvuc || ' '}
            options = {(dsKhuvuc).map((option) => ({
            key: option.Id,
            text: `${option.Ten}`
            }))}
            onChange= {value => setKhuvuc(value)} 
            />

        <SearchDropDown
            id="dongia_buoi_khunggio"
            className ="inp-fld"
            style={{
                marginRight:35,
                width:250
            }}
            label={lang.lotrinh.khunggio} 
            type="number"
            value={khunggio || ' '}
            options = {(dsKhunggio).map((option) => ({
            key: option.id || option.Id,
            text: `${option.startTime} - ${option.endTime}`
            }))}
            onChange= {value => setKhunggio(value)} 
            />
        <div className="flex-nowrap">
        <TextField
            id="dongia_buoi_sobuoi1"
            className ="inp-fld"
            label={`${lang.generaltext.sobuoi}1`} 
            variant="standard"
            type="number"
            value={sobuoi1}
            onInput={evt => setSobuoi1(evt.target.value)}
            InputProps={{
            endAdornment: <InputAdornment position="end">{lang.generaltext.buoi}</InputAdornment>
            }}
            />
            <span className="h-space" style={{
                width:80,
                textAlign:"center"
            }}>{` - `}</span>
        <TextField
            id="dongia_buoi_sobuoi2"
            className ="inp-fld"
            label={`${lang.generaltext.sobuoi}2`} 
            variant="standard"
            type="number"
            value={sobuoi2}
            onInput={evt => setSobuoi2(evt.target.value)}
            InputProps={{
            endAdornment: <InputAdornment position="end">{lang.generaltext.buoi}</InputAdornment>
            }}
            />
        </div>
        
        <TextField
            id="dongia_buoi_dongia"
            className ="inp-fld"
            label={lang.lotrinh.dongia} 
            variant="standard"
            type="number"
            value={dongia}
            onInput={evt => setDongia(evt.target.value)}
            InputProps={{
                endAdornment: <InputAdornment position="end">vnd</InputAdornment>
            }}
            />
    </div>

    return <Dialog
    title={dgia.Id ? lang.generaltext.capnhatdongiabuoi: lang.generaltext.themdongiabuoi}
    className="dialog-a-visible"
    okText ={lang.buttons.luu}
    cancelText={lang.buttons.boqua}
    open={open}
    msgContent={content}
    onClose={evt => setClose({})}
    onYes={evt => setDongiaChanged(true)}
    />
}