import { Box, Button } from "@mui/material"
import React, { useEffect, useRef, useState } from "react"
import DataTable from "../../Components/DataTable"
import RefreshIcon from '@mui/icons-material/RefreshOutlined';

import lang  from "../../asset/Language.json"
import SearchFilter from "../../Components/SearchLotrinhBar/searchFilter"
import LotrinhService from "../../services/LotrinhService";
import BangLotrinhChitiet from "./BangLotrinhChitiet";


export default function LotrinhNhanvien(props){
    const {cusId} = props;
    const pageSize = 15
    const [refresh, setRefresh] = useState(false);
    const [rows, setRows] = useState([]);
    const [pageIndex, setPageIndex] = useState(0);
    const [showLoading, setShowLoading] = useState(false)
    const [selectedNV, setSelectedNV] = useState()
    const [openBanglotrinhchitiet, setOpenBanglotrinhchitiet] = useState (false)
    const [data, setData] = useState({result: [], summaryData: {}});
    const [totals, setTotals] = useState()

    const curSearchReq = useRef();
    const handleSearch = async (fromDate1, toDate1, pageIndex,khuvucMaster, selectedStatuses, saleName, selectedSubKV) => {
        const sv =new LotrinhService();
    
        curSearchReq.current =  {
            fromDate: fromDate1,
            toDate: toDate1,
            kvm: khuvucMaster ? khuvucMaster.id : 0,
            statuses: selectedStatuses,
            saleName: saleName || "",
            subKV: selectedSubKV && selectedSubKV.length > 0 ? selectedSubKV.map(k => k.id) : undefined
        };
        const r = await sv.search(pageIndex + 1, pageSize, curSearchReq.current);

        if(r.error || !r.result) {
            return;
        }

        setRows(r.result.map(o => {
            const h = parseInt(o.Tongthoigian/60);
            const m = o.Tongthoigian - parseInt(o.Tongthoigian/60)*60;

            const h1 = parseInt(o.TongthoigianTca/60);
            const m1 = o.TongthoigianTca - parseInt(o.TongthoigianTca/60)*60;
          return  Object.assign(o, {
            Tongthoigian: `${h< 10 ? "0" : ""}${h}:${m<10 ? "0": ""}${m}`,
            TongthoigianTca: `${h1< 10 ? "0" : ""}${h1}:${m1<10 ? "0": ""}${m1}`
        });
        }));
        setTotals(r.total);

    }

    const columns=[{
        field: ["Name"],
        headerName: lang.lotrinh.nhanvienSale,
        width: 160
    },
    {
        field: "SongayDangky",
        headerName: lang.lotrinh.songaydangky,
        width:100
    },
    {
        field: "SongayThucte",
        headerName: lang.lotrinh.songaytt
    },
    {
        field: "Tongthoigian",
        headerName: lang.lotrinh.thoigian
    },
    {
        field: "TongthoigianTca",
        headerName: lang.lotrinh.tangca
    },
    {
        field: "Sokm",
        headerName: lang.lotrinh.sokm    
    },
    {
        field: "Sokmvuot",
        headerName: lang.lotrinh.sokmvuot
    }]

    useEffect(async() => {
        if(!selectedNV) {
            return;
        }
        
        setOpenBanglotrinhchitiet(true);
    },[selectedNV])
    
    return <React.Fragment>
          {<Box 
            className="main-content" style={openBanglotrinhchitiet ? { 
                paddingRight:300,
                display: "none"
            } : {
            paddingRight:300
            }}
            
        >
            <div  className="page-header">
            <div className="page-title"><span>{lang.lotrinh.lotrinhantoan}</span></div>
            <Button variant="text" className="btn-text" title="Refresh" onClick={evt => setRefresh(!refresh)}><RefreshIcon/>Refresh</Button>
            </div>
            <SearchFilter cusId={cusId}  
                onSearch = {handleSearch}
                refresh = {refresh}
                pageIndex = {pageIndex}
            />
            <DataTable
            rows = {rows}
            columns ={columns}
            rowCount = {totals}
            pageSize={pageSize}
            rowsPerPageOptions={[5]}
            checkboxSelection ={false}
            disableSelectionOnClick={false}
            disableColumnMenu={true}
            pagination={true}
            paginationMode={'server'}
            loading={showLoading}
            onRowClick = {data => {
               setSelectedNV(data.row)
            }}
            onPageChange={page => setPageIndex(page)}
            />
        </Box>}
        {openBanglotrinhchitiet && 
            <BangLotrinhChitiet req={curSearchReq.current} selectedNV={selectedNV}  goback = {evt =>{
                 setSelectedNV();
                 setOpenBanglotrinhchitiet(false)
                }}/>
        }
    </React.Fragment>  
}