import { Stack, Typography } from "@mui/material";
import React from "react"
import lang from "../asset/Language.json"
import LotrinhTimeLine from "./LotrinhTimeLine";
import { getStatus, Thoigian } from "../Utils/util";

export default function LotrinhInfo(props) {
    const {startTime, endTime, distance, startLocation, endLocation, note, status} = props.lotrinh;
    const sokm = parseFloat((distance*1/1000).toFixed(3));

    return ( 
       <>
        <Stack direction="column">
            <Typography component="div">
                <span>{ lang.lotrinh.trangthai}:&nbsp;</span>
                <b>{getStatus(status)}</b>            
            </Typography>
            <Typography component="div">
                <span>{ lang.lotrinh.tongthoigian}:&nbsp;</span>
                <b>{endTime ? Thoigian(`${startTime}Z`,`${endTime}Z`) : ""}</b>
            </Typography>
            <Typography component="div">
                <span>{ lang.lotrinh.quangduong}:</span>
                <b>{` ${sokm.toLocaleString()} (km)`}</b>
            </Typography>
        </Stack>
        {LotrinhTimeLine(endTime ? [{
            time: new Date(`${startTime}Z`),
            location: startLocation
        },{
            time: new Date(`${endTime}Z`),
            location: endLocation
        }] : [{
            time: new Date(`${startTime}Z`),
            location: startLocation
        }])}
        { note && <Stack>
            <span><b>Note:&nbsp;</b>{note || ""}</span>
        </Stack>}
        </>
    )
}