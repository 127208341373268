import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import { grey } from '@mui/material/colors';
import LotrinhChitiet from './LotrinhChitiet';
import LotrinhService from '../../services/LotrinhService';
import { Box, Button, Slide } from '@mui/material';
import StaticDateRangePickerDemo from './DateRangeSelect';
import Paper from '@mui/material/Paper';
import lang from "../../asset/Language.json"
import { getStatus } from '../../Utils/util';

export default function DanhSachLotrinh(props) {
    const {showDateRange, setShowDateRange, setView} = props;
    const[openLotrinhDetail, setOpenLotrinhDetail] = React.useState({});
    const [lotrinhs, setLotrinhs] = React.useState([])
    const [selectedDates,setSelectedDates] = React.useState([new Date(new Date().getFullYear(),new Date().getMonth(),1),new Date()])
    const [getLotrinhs, setGetLotrinhs] = React.useState()
    const containerRef = React.useRef(null);
    const [selectedMonth, setSelectedMonth] = React.useState(new Date())

    React.useEffect(async () => {
        const sv = new LotrinhService();
        const fromDate1 = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(),1,0,0,1).toISOString();
        const toDate1 = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1,0,23,59,59).toISOString();
       
        const r = await sv.getLotrinhListView(fromDate1, toDate1);
        if(r.error) {
            //show toast message
            return;
        }
        setLotrinhs(r);

    },[getLotrinhs])

    const icon = (
        <Paper elevation={0} sx={{width:"100%", position:"absolute", height:"100%"}}>
          <StaticDateRangePickerDemo value={selectedMonth} setValue={setSelectedMonth}/>
          <div className="flex-row" style={{
              justifyContent: "flex-end",
              padding:8
          }}>
          <Button variant="outlined" sx={{marginRight:"8px"}} onClick={evt => setShowDateRange(false)}>CANCEL</Button>
          <Button variant="outlined" onClick={evt => {
              setShowDateRange(false)
              setGetLotrinhs(!getLotrinhs)
              }}>OK</Button>
          </div>
        </Paper>
      );

  return (
      <Box
      ref={containerRef}
      sx={{ height:"calc(100% - 60px)",display:"flex"}}
      >
          {(!lotrinhs || lotrinhs.length <=0) && !showDateRange && <div style={{
              flex: 1,
              display:"flex",
              justifyContent:"center",
              alignItems: "center"
          }}><span>{lang.lotrinh.khongcolotrinh}</span> </div>}
            {!(openLotrinhDetail && openLotrinhDetail.id) && (lotrinhs && lotrinhs.length > 0) && <List sx={{ width: '100%', maxWidth: "100%", bgcolor: 'background.paper', alignItems: "center", justifyContent: "center" }}>
                {lotrinhs.map((l,idx) => <React.Fragment>
                    {idx > 0 && <Divider variant="inset" component="li" sx={{marginLeft:0}} />}
                    <ListItem 
                    onClick={evt => setOpenLotrinhDetail(l)}
                    alignItems="flex-start">
                        <div className="flex-row" style={{
                            alignItems:"center"
                        }}>
                    <ListItemText
                    primary={new Date(`${l.ngay}Z`).toLocaleString("vi-vn",{day: '2-digit',month:'2-digit',year:'numeric'})}
                    />
                    
                        <span>{getStatus(l.status)}</span>
                        <ArrowForwardIcon style={{
                            color: grey[500],
                            width:20
                        }}/>
                    </div>

                    
                    </ListItem>
                </React.Fragment>)}
            </List>}
            {openLotrinhDetail && openLotrinhDetail.id && <LotrinhChitiet 
            setOpenLotrinhDetail = {setOpenLotrinhDetail}
            setGetLotrinhs={evt => setGetLotrinhs(!getLotrinhs)}
            lotrinhid={openLotrinhDetail.id}/>}
            {showDateRange && <Slide
            direction="up" in={showDateRange}
            container={containerRef.current}>
              
                {icon}
            </Slide>}
      </Box>
    
  );
}
