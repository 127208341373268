import React from "react";
import PropTypes from 'prop-types';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableHeader from "./TableHeader";
import TableBodyRows from "./TableBodyRows";
import TableFooterCustom from "./TableFooter";
import { useState } from "react";

export default function DataTable(props) {
    const {rows, columns, summaryRows, size, onPageChange, paginationMode, pageSize, rowCount, pagination, onRowClick, onRowDoubleClick, rowHeaders, tableCellStyle} = props;
    const [page, setPage] = useState(0);

    const handleChangePage = (event, page) => {
        setPage(page);
        if(paginationMode === 'server' && onPageChange) {
            onPageChange(page);
            return;
        }
    }

    return <TableContainer component={Paper} style={{
        boxShadow:"none",
        width:"auto",
        maxWidth:"100%",
        overflow: "auto"
    }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table" size={size}>
            <TableHeader columns={rowHeaders || [columns]}/>
            <TableBodyRows 
            rows={pagination && paginationMode !== 'server' && pageSize > 0 ? rows.slice(page * pageSize, page * pageSize + pageSize) : rows} 
            columns={columns}
            page={page}
            rowsPerPage={pageSize || 10}
            onRowClick={onRowClick}
            onRowDoubleClick={onRowDoubleClick}
            tableCellStyle ={tableCellStyle || {}}
            summaryRows = {summaryRows || []}
            />
            {pagination && rowCount > 0 && <TableFooterCustom
            key={"table-footer"} 
            totalRows={rowCount || rows.length}
            handleChangePage={handleChangePage}
            rowsPerPage={pageSize || 10}
            page={page}
            totalColumn = {(columns || []).length}
            useRowsPerPageOption={false}
            />}
        </Table>
    </TableContainer>
}

DataTable.proTypes = {
    rows: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    rowCount: PropTypes.number,
    pageSize: PropTypes.number,
    paginationMode: PropTypes.string,
    onPageChange: PropTypes.func,
    pagination: PropTypes.bool,
    rowHeaders:PropTypes.array,
    tableCellStyle: PropTypes.object
}