import { Box, Button, InputAdornment, Stack, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import lang from "../../asset/Language.json"
import LotrinhService from "../../services/LotrinhService";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ConfirmDialog from "../../Components/ConfirmDialog";
import MobileTimePicker from '@mui/lab/MobileTimePicker';
import LotrinhInfo from "../../Components/LotrinhInfo";

export default function LotrinhChitiet(props){
    const { goback, lotrinh} = props;
    const [updateConfirm, setUpdateConfirm] = useState();
    const [updated, setUpdated] = useState();
    const [note, setNote] = useState();
    const [startTime, setStartTime] = useState(new Date(`${lotrinh.startTime}Z`));
    const [endTime, setEndTime] = useState(new Date(`${lotrinh.endTime}Z`));
    const [distance, setDistance] = useState(lotrinh.distance*0.001);
    const [invalidEndTime, setInvalidEndTime] = useState()
    const [invalidStartTime, setInvalidStartTime] = useState()

    const formUpdateLotrinh = () => {

        const ltStartTime = new Date(`${lotrinh.startTime}Z`);
        const ltEndTime =  new Date(`${lotrinh.endTime}Z`);
        return <div>
        <Stack direction="column" spacing ={1}>
                <b>{lang.lotrinh.chinhsualotrinhConfirm}</b>
                <Stack direction ="row" spacing={2}>
                <MobileTimePicker
                label={lang.lotrinh.thoigianbd}
                value={startTime || ltStartTime}
                minTime={new Date(ltStartTime.getFullYear(), ltStartTime.getMonth(), ltStartTime.getDate(),1,0,0)}
                maxTime={new Date(ltStartTime.getFullYear(), ltStartTime.getMonth(), ltStartTime.getDate(),(endTime || ltEndTime).getHours(),0,0)}
                onChange={(newValue) => {
                    setStartTime(newValue);
                }}
                ampm={false}
                onError={evt => setInvalidStartTime(true)}
                onAccept={evt => setInvalidStartTime(false)}
                renderInput={(params) => <TextField {...params} />}
                />
                <MobileTimePicker
                label={lang.lotrinh.thoigiankt}
                value={endTime || ltEndTime}
                ampm={false}
                minTime={new Date(ltStartTime.getFullYear(), ltStartTime.getMonth(), ltStartTime.getDate(),(startTime || ltStartTime).getHours(),(startTime || ltStartTime).getMinutes(),(startTime || ltStartTime).getSeconds())}
                maxTime={new Date(ltStartTime.getFullYear(), ltStartTime.getMonth(), ltStartTime.getDate(),23,59,59)}
                onError={evt => setInvalidEndTime(true)}
                onAccept={evt => setInvalidEndTime(false)}
                onChange={(newValue) => {
                    setEndTime(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
                />
                </Stack>
                <TextField
                    label ={lang.lotrinh.quangduong}
                    value = {parseFloat((distance*1).toFixed(3))}
                    onInput= {evt => setDistance(evt.target.value*1)}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">km</InputAdornment>
                    }}
                    type="number"
                    min={1}
                    max={200}
                    error= {distance <= 0 || distance > 200}
                />
                <TextField
                label={lang.lotrinh.lydochinhsualotrinh}
                value={note}
                onInput={evt => setNote(evt.target.value)}
                multiline
                rows={3}
                required
                maxRows={5}
                error={note && note.length > 199}
                />
        </Stack>
    </div>}

    const lotrinhRef = useRef(lotrinh)

    const resetvalue = () => {
        setStartTime(new Date(`${lotrinhRef.current.startTime}Z`));
        setEndTime(new Date(`${lotrinhRef.current.endTime}Z`));
        setDistance(lotrinhRef.current.distance*0.001);
        setUpdateConfirm(false);
    }

    useEffect(async() => {
        if(!updated) {
            return;
        }
        const sv = new LotrinhService();
        const data = {
            id: lotrinh.id,
            distance: parseInt(distance*1000),
            startTime: startTime.toISOString(),
            endTime: endTime.toISOString(),
            note:note || "",
            status: 1
        }
        await sv.update(data)
        lotrinhRef.current = Object.assign(lotrinhRef.current, data, {
            startTime: data.startTime.replace('Z',''),
            endTime: data.endTime.replace('Z','')
        });
        setUpdated(false);
        setUpdateConfirm(false);
        setNote();
        
       
    },[updated])

    return <Box sx={{
        justifyContent: "flex-start",
        maxWidth:360,
        margin:"0 auto",
        textAlign: "left",
        width:"100%",
        padding: goback ? "0 15px" : "15px"
    }}>
        {goback && <Button sx={{
            padding: "15px 0"
        }} variant="text" className="btn-text" onClick={evt => goback()}><KeyboardBackspaceIcon/>{lang.generaltext.back}</Button>}
        {lotrinh && <LotrinhInfo lotrinh={lotrinhRef.current}/>}
        {lotrinh && lotrinh.status != 2 && <Stack direction="row" position="fixed" bottom={0} left={0} width={"100%"}>
            <Button variant="contained" sx={{flex:1, borderRadius: 0, color:"#FFF", height:45}}
            onClick={evt => setUpdateConfirm(true)}
        >{lang.lotrinh.chinhsualotrinh}</Button>
           
           <ConfirmDialog
            cancelText={lang.buttons.boqua}
            okText = {lang.buttons.xacnhan}
            open={updateConfirm}
            disabledYes={!note || !distance || distance <= 0 || distance > 200 || invalidEndTime || invalidStartTime || note.length > 199}
            msgContent={formUpdateLotrinh()}
            onClose={evt => resetvalue()}
            onYes = {evt => setUpdated(true)}
            />
        </Stack>}
    </Box>
}