export const UserTypeEnum = Object.freeze({
    Admin: 1,
    Driver: 6,
    Nhanvien: 2,
    Customer: 3,
    Sale: 4,
    Unknown: 0,
    UNKNOWN : "UNKNOWN",
    ADMINISTRATOR : "ADMINISTRATOR",
    NHANVIEN : "NHANVIEN",
    CUSTOMER : "CUSTOMER",
    SALE : "SALE",
    DRIVER : "DRIVER"
});

export const UserTypes = [{
        key: 6,
        text: "Tài xế"
      },
      {
    key: 4,
    text: "Nhân viên Sale"
  },{
    key: 2,
    text: "Sale Admin"
  },{
        key: 1,
        text: "Administrator"
      }]

