import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { IconButton } from '@mui/material';
import lang from "../../asset/Language.json"
import LogoutIcon from "@mui/icons-material/ExitToAppOutlined"
import CalendarIcon from '@mui/icons-material/EventAvailable'

import PassworkeyIcon from "@mui/icons-material/VpnKey"

export default function SlideMenu(props) {
  const { open, setOpen, userName, setOpenUserMenu, setOpenChangePassForm, openPlan } = props;

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });



  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={evt => setOpen(false)}
      onKeyDown={evt => setOpen(false)}
    >
      <List>
        <ListItem button key={"userInfo"}>
          <ListItemIcon>
            <IconButton sx={{
              fontSize: 12,
              backgroundColor: "#ccc",
              width: 35,
              height: 35,
              textTransform: "uppercase",
              fontWeight: "bold"
            }}>
              {userName.substr(0, 2)}
            </IconButton>
          </ListItemIcon>
          <ListItemText primary={userName} secondary={lang.generaltext.nvsale} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem onClick={evt => {
          setOpenUserMenu(false)
          setOpenChangePassForm(true)
        }}>
          <ListItemIcon>
            <PassworkeyIcon />
          </ListItemIcon>
          <ListItemText primary={lang.generaltext.changepassword} />
        </ListItem>
        <Divider />
        <ListItem onClick={evt => document.location.reload(true)} button key={"logout"}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={lang.generaltext.dangxuat} />
        </ListItem>
        <Divider />
        <ListItem onClick={evt => {openPlan(true)}}>
          <ListItemIcon>
            <CalendarIcon/>
          </ListItemIcon>
          <ListItemText primary={lang.functions.nhanvien_workingplan}></ListItemText>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <SwipeableDrawer
      anchor={"left"}
      open={open}
      onClose={evt => setOpen(false)}
      onOpen={evt => setOpen(true)}
    >
      {list()}

    </SwipeableDrawer>
  );
}
