import Form from  "../../../Components/Form"
import lang from "../../../asset/Language.json"
import React from "react"

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    "text-primary": {
        color: "#73ca2f"
    },
    "cursor-pointer": {
        color: "#73ca2f",
        cursor: "pointer"
    }
});

const ForgotPwd = (props) => {
    const { onNavigate } = props;
    const classes = useStyles();
    const sendEmail = () => {
        //call to send mail activate code
    }

    return <Form 
        title={lang.ForgotPwd.title} 
        subtitle={<div>
            <span  >{lang.ForgotPwd.subtitle}</span>&nbsp;
            <span  className={classes["text-primary"]} >{lang.ForgotPwd.subtitle1}</span>
        </div>}
        fields={[
            {
                label: lang.ForgotPwd.emailPlaceholder
            }
        ]} 
        buttonLabel={lang.ForgotPwd.submitbutton}
        onSubmit={sendEmail}
        footer={<div>
            <span>{lang.ForgotPwd.dacotaikhoan}</span>&nbsp;
            <span className={classes["cursor-pointer"]} onClick={evt => onNavigate("login")} color="primary">{lang.ForgotPwd.dangnhap}</span>
        </div>}/>
}

export default ForgotPwd;