import {useCallback, useEffect, useState} from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import "react-big-calendar/lib/css/react-big-calendar.css"
import { format } from "date-fns";
import LeaveDialog from "./LeaveDialog";
import LeavingService from "../../../services/LeavingService";

moment.locale('ko', {
  week: {
      dow: 1,
      doy: 1,
  },
});
// Setup the localizer by providing the moment (or globalize, or Luxon) Object
// to the correct localizer.
const localizer = momentLocalizer(moment) // or globalizeLocalizer


export default function MyCalendar(props)  {
  const [myEvents, setEvents] = useState([])
  const [openLeaveDialog, setOpenLeaveDialog] = useState(false);
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  

  const customDayPropGetter = (date) => {
    const weekday = format(date, "EEE");
    if (weekday && ["Sat", "Sun"].indexOf( weekday) > -1) 
      return {
        className: "rbc-off-weekend",
      }
    else return {}
  }
  
  const onNavigate = async (date) => {
    
    const month = date.getMonth();
    const year = date.getFullYear();
    const fromDate1 = new Date(year, month,1,0,0,1).toISOString();
    const toDate1 = new Date(year, month + 1,0,23,59,59).toISOString(); 
    const leaveService = new LeavingService();
    const leaves = await leaveService.getLeaveDays({
      fromDate: fromDate1,
      toDate: toDate1,
      userId: 0
    });
  
    if(!leaves.error) {
      const events = leaves.map(l => ({
        start: new Date(`${l.fromDate}Z`),
        end: new Date(`${l.toDate}Z`),
        title: l.leaveNote
      }));
      setEvents((prev) => [...prev, ...events])
    }
  }
  

  
  const handleSelectEvent = useCallback(
    (event) => window.alert(event.title),
    []
  )
  
  const handleSelectSlot = useCallback(
    ({ start, end }) => {
      setStartDate(start);
      setEndDate(end);
      setOpenLeaveDialog(true);
    },
    []
  )

  const handleLeaveSaveChanges = (start, end, title, leaveType) => {
    setOpenLeaveDialog(false);
    if (title) {
      const services = new LeavingService();
      services.addHolidays({
        fromDate: start,
        toDate: end,
        leaveNote: title,
        leaveType: leaveType,
        userId: 0
      }).then((res) => {
        if(!res.error) {
          setEvents((prev) => [...prev, { start, end, title }])
        }
      });
    }
  }

  useEffect(async() => {
    await onNavigate(new Date());
  }, [])

  return <div className="myCustomHeight" style={{height: 600}}>
  <Calendar
     events={myEvents}
     localizer={localizer}
     onSelectEvent={handleSelectEvent}
     onSelectSlot={handleSelectSlot}
     dayPropGetter={customDayPropGetter}
    startAccessor="start"
    endAccessor="end"
    views = {{month: true, day: false, week:false}}
    selectable
    onNavigate = {onNavigate}
  />
  { openLeaveDialog && <LeaveDialog open = {openLeaveDialog} closeDialog={setOpenLeaveDialog} startDate = {startDate} endDate={endDate} setEndDate={setEndDate} setStartDate = {setStartDate} saveChanges = {handleLeaveSaveChanges}/>}
</div>
}