import ApiService from "./ApiService";
import {ApiMaster} from "../Contants/UrlContant";

export default class MasterDataService {
    constructor(){
        this.api = new ApiService();
    }

    async GetDanhSachProvince(){
        
        const result = await this.api.get(ApiMaster.getProvinces);
        if(result  && result .data) {
            return result.data.filter(p=> p.isEnable == true).map(p => ({
                key: p.provinceID,
                text: p.provinceName,
                nameExtension: p.nameExtension
            }));
            
        }
        return [];
    }

    async GetDanhSachQuan(province_id) {
        const d = await this.api.get(ApiMaster.getDistricts,{province_id: province_id});
        if(d && d.data) {
            return d.data.filter(d1 => d1.isEnable == true).map(d1 =>({
                key: d1.districtID,
                text: d1.districtName,
                nameExtension: d1.nameExtension
            }));
        }
        return [];
    }

    async GetDanhSachPhuong(district_id) {
        const w = await this.api.get(ApiMaster.getWards, {district_id: district_id});
        if(w && w.data) {
            return w.data.filter(d1 => d1.isEnable == true).map(d1 =>({
                key: d1.wardCode,
                text: d1.wardName,
                nameExtension: d1.nameExtension
            }));
        }
        return [];
    }


}