import lang from "../../../asset/Language.json"
import { Button, TextField, Typography } from "@mui/material";
import React from "react"
import { makeStyles } from '@mui/styles';
import UserService from "../../../services/UserService";

const useStyles = makeStyles({
  header: {
    "padding-bottom": "20px"
},
formContentCol1: {
  display:"flex",
  "flex-direction": "column",
  
},
formContentCol2: {
  display:"flex",
  "flex-wrap": "wrap",
  "justify-content": "space-between"
},
formField: {
    "margin-bottom": "15px!important"
},
submitBtn: {
    height: "45px",
    color: "#fff",
    "font-size": "18px",
    "margin-bottom": "20px!important"
},
    "text-primary": {
        color: "#73ca2f",
        cursor: "pointer"
    },
    loginFailed: {
      color: "red"
    }
});

const Login = (props) => {
   const[usrName, setUsrName] = React.useState('');
   const[pwd, setPwd] = React.useState('');
   const[invalidUser, setInvalidUser] = React.useState(false);
   const[invalidPwd, setInvalidPwd] = React.useState(false);
   const[loginFailed, setLoginFailed] = React.useState(false);

    const { onNavigate } = props;
    const uService = new UserService();
    const doLogin = async (evt) => {
        ///todo: base onrole to navigate to correct view here
        evt.preventDefault();
        if(!usrName) {
          setInvalidUser(false);
        }
        if(!pwd) {
          setInvalidUser(false);
        }

        if(invalidPwd || invalidUser) {
          return;
        }
        const result = await uService.login({
          userName: usrName,
          password: pwd
        });

        if(result && result.token) {
          uService.apiService.saveToken(result.token);
          const u = uService.apiService.userLoggedIn;
          onNavigate(u.data.view);
          return;
        }

        setLoginFailed(true);
    }
   
    const classes = useStyles();

    return <div className={classes.formContentCol1}>
    <div className={classes.header}>
        <Typography variant="h4" component="h2">{lang.LoginPage.Title}</Typography>
        {loginFailed ? <span className={classes.loginFailed}>{lang.LoginPage.LoginFailedMsg}</span>: null}
    </div>
    <div className={classes.formContentCol1}>
      <TextField id="usrName" 
      onChange={e => setUsrName(e.target.value)} 
      error={invalidUser} type="email" 
      fullWidth={true} variant="outlined" 
      label={lang.LoginPage.UserName} 
      placeholder={lang.LoginPage.UserNamePlaceholder} 
      autoComplete="off"
      className={classes.formField}
      onBlur={e => setInvalidUser(!e.target.value)}
      onFocus={e => {setInvalidUser(false); setLoginFailed(false);}}
      autoFocus={true}
      />
      <TextField id="password" 
      onChange={e=> setPwd(e.target.value) } 
      error={invalidPwd} type="password" fullWidth={true}
      variant="outlined" 
      label={lang.LoginPage.Password} 
      placeholder={lang.LoginPage.PassworPlaceholder} 
      autoComplete="off"
      className={classes.formField}
      onBlur={e => setInvalidPwd(!pwd)}
      onFocus={e => { setInvalidPwd(false); setLoginFailed(false);}}/>
    </div>
    <Button className={classes.submitBtn} variant="contained" color="primary" onClick={e => doLogin(e)}>{lang.LoginPage.SubmitButton}</Button>
    <div className="flex-column">
        <span className="cursor-pointer" onClick={evt => onNavigate("forgotPwd")} >{lang.LoginPage.Quanmatkhau}</span>
    </div>
</div>;
}

export default Login;