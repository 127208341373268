import ApiService from "./ApiService";
import {CustomerApi, ApiUserUrl} from "../Contants/UrlContant";

class CustomerService {
    constructor(){
        this.api = new ApiService();
    }

    async registerCustomer(data){
        return await this.api.post(CustomerApi.registerCustomer, data);
    }
    async getListCustomer() {
        return await this.api.get(CustomerApi.getAll);
    }
    async saveCustomer(data) {
        return await this.api.post(CustomerApi.update, data);
    }

    async createAccount(data) {
        return await this.api.post(ApiUserUrl.createAccount, data);
    }

    async updateAccount(data) {
        return await this.api.post(ApiUserUrl.updateAccount, data);
    }

    async blockAccount(data) {
        return await this.api.post(ApiUserUrl.blockAccount, data);
    }

    async addCustomer(data) {
        return await this.api.post(CustomerApi.addNew, data);
    }

    async deleteCustomer(id) {
        return await this.api.post(`${CustomerApi.delete}${id}`);
    }
}

export default CustomerService;