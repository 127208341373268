import { Box, Button, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import lang from "../../asset/Language.json"
import LotrinhService from "../../services/LotrinhService";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { grey, red } from "@mui/material/colors";
import ConfirmDialog from "../../Components/ConfirmDialog";
import LotrinhTimeLine from "../../Components/LotrinhTimeLine"
import { Thoigian, getStatus } from "../../Utils/util";

export default function LotrinhChitiet(props){
    const {lotrinhid, setOpenLotrinhDetail, setGetLotrinhs} = props;
    const [lotrinh, setLotrinh] = useState([{}])
    const [approveConfirm, setApproveConfirm] = useState();
    const [rejectConfirm, setRejectConfirm] = useState();
    const [approved, setApproved] = useState();
    const [rejected, setRejected] = useState();
    const [rejectedNote, setRejectedNote] = useState();
    const [note, setNote] = useState();

    useEffect(async() => {
        const sv = new LotrinhService();
        const r = await sv.getLotrinhDetail(lotrinhid);
        if(r.error) {
            //
            return;
        }
        if(r[0].endTime) {
            r[0].thoigian = Thoigian(`${r[0].startTime}Z`,`${r[0].endTime}Z`);
        }

        setLotrinh(r);
    },[])
    
    useEffect(async() => {
        if(!approved) {
            return;
        }
        const sv = new LotrinhService();
        await sv.confirm({
            lotrinhId: lotrinhid,
            status: 2,
            note:note || ""
        })
        setApproveConfirm(false);
        setOpenLotrinhDetail(false)
        setGetLotrinhs(true);
        
    },[approved])

    useEffect(async() => {
        if(!rejected) {
            return;
        }
        const sv = new LotrinhService();
        await sv.confirm({
            lotrinhId: lotrinhid,
            status: 3,
            note: rejectedNote
        })
        setRejectConfirm(false);
        setOpenLotrinhDetail(false)
        setGetLotrinhs(true);
        
    },[rejected])

    return <Box sx={{
        justifyContent: "flex-start",
        maxWidth:360,
        margin:"0 auto",
        textAlign: "left",
        width: "100%",
        padding: "0 15px"
    }}>
        <Button sx={{
            padding: "15px 0"
        }} variant="text" className="btn-text" onClick={evt => setOpenLotrinhDetail(false)}><KeyboardBackspaceIcon/>{lang.generaltext.back}</Button>
        {<Stack
        direction={{ xs: 'column', sm: 'column' }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
        sx={{textAlign: "left"}}
        >
        <div>
            <span>{ lang.lotrinh.trangthai}:&nbsp;</span>
            <b>{getStatus(lotrinh[0].status)}</b>
        </div>
        <div>
            <span>{ lang.lotrinh.tongthoigian}:&nbsp;</span>
            <b>{lotrinh[0].thoigian}</b>
            </div>
        <div>
            <span>{ lang.lotrinh.quangduong}:</span>
            <b>{` ${(lotrinh[0].distance*0.001).toLocaleString()} (km)`}</b>
        </div>
        
        </Stack>}
        {LotrinhTimeLine(lotrinh[0].endTime ? [{
            time: new Date(`${lotrinh[0].startTime}Z`),
            location: lotrinh[0].startLocation
        },{
            time: new Date(`${lotrinh[0].endTime}Z`),
            location: lotrinh[0].endLocation
        }] : [{
            time: new Date(`${lotrinh[0].startTime}Z`),
            location: lotrinh[0].startLocation
        }])}
        {lotrinh[0].note && <Stack>
            <span><b>Note:&nbsp;</b>{`${ lotrinh[0].note || ""}`}</span>
        </Stack>}
        {lotrinh && lotrinh[0].status != 2 && <Stack direction="row" position="fixed" bottom={0} left={0} width={"100%"}>
            <Button variant="contained" sx={{flex:1, borderRadius: 0, color:"#FFF", height:45}}
            onClick={evt => setApproveConfirm(true)}
        >{lang.lotrinh.chapthuan}</Button>
            {lotrinh && lotrinh[0].status != 3 && <Button variant="contained" sx={{flex:1, borderRadius: 0, color: red[300], backgroundColor: grey[400] }}
            onClick={evt => setRejectConfirm(true)}
            >{lang.lotrinh.tuchoi}</Button>}
            <ConfirmDialog
            cancelText={lang.buttons.boqua}
            okText = {lang.buttons.xacnhan}
            open={approveConfirm}
            disabledYes={lotrinh[0].status == 3 && !note}
            msgContent={lotrinh[0].status == 3 ? <div>
                <span>{lang.lotrinh.approveConfirm}</span>
                <TextField
                fullWidth
                multiline
                required
                placeholder={lang.lotrinh.lydochapthuanlotrinhbituchoi}
                onInput={evt => setNote(evt.target.value)}
                />
            </div> : lang.lotrinh.approveConfirm}
            onClose={evt => setApproveConfirm(false)}
            onYes = {evt => setApproved(true)}
            />

            <ConfirmDialog
            cancelText={lang.buttons.boqua}
            okText = {lang.buttons.xacnhan}
            open={rejectConfirm}
            msgContent={<div>
                <span>{lang.lotrinh.rejectConfirm}</span>
                <TextField
                fullWidth
                multiline
                maxRows = {4}
                required
                placeholder={lang.lotrinh.lydoreject}
                onInput={evt => setRejectedNote(evt.target.value)}
                />
            </div>}
            onClose={evt => setRejectConfirm(false)}
            onYes = {evt => setRejected(true)}
            disabledYes ={!rejectedNote}
            />
        </Stack>}
    </Box>
}