import React from "react"
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { TimelineOppositeContent } from "@mui/lab";
import { blue } from "@mui/material/colors";

export default function LotrinhTimeLine(lotrinhdiems){

    return <Timeline sx={{
            width:"95%",
            paddingLeft: 0
            }}>
            {(lotrinhdiems || []).map(d => <TimelineItem>
                            <TimelineOppositeContent sx={{
                                flex:0,
                                whiteSpace:"nowrap"
                            }} color = "text.secondary">
                            <div>{new Date(d.time).toLocaleString("vi-vn", { day:'2-digit',month:'2-digit'})}</div>
                            <div>{new Date(d.time).toLocaleString("vi-vn", { hour:'2-digit',minute:'2-digit', hour12:false})}</div>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot sx={{backgroundColor: blue[500]}}/>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{
                                wordBreak: "break-all"
                            }}>{d.location}</TimelineContent>
                        </TimelineItem>
            )}
            </Timeline>
}