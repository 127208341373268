import * as React from 'react';
import TextField from '@mui/material/TextField';
import DatePicker from '@mui/lab/DatePicker';

export default function MonthYearPicker(props) {
  const {label, value, setValue, views} = props;
  const d = new Date();
  return (
      <DatePicker
          views={ views || ['year', 'month']}
          label={label || ""}
          minDate={new Date(d.getFullYear() - 1, 0, 1)}
          maxDate={new Date()}
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={(params) => <TextField {...params} helperText={null} fullWidth />}
        />
  );
}
