import React, {useState, useRef, useEffect} from "react"
import DataGrid from "../../Components/DataTable";
import lang from "../../asset/Language.json"
import LotrinhService from "../../services/LotrinhService";
import SearchFilter from "../../Components/SearchLotrinhBar/searchFilter"
import { SimCardDownload } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { lightGreen, orange } from "@mui/material/colors";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import KhuVucService from "../../services/KhuVucService";

export default function BaocaoTongHop(props){
    const {cusId} = props;
    const [pageIndex, setPageIndex] = useState(0)
    const [rows, setRows] = useState([]);
    const [totalRow, setTotalRow] = useState(0)
    const [selectedRow, setSelectedRow] = useState()
    const [showLoading, setShowLoading] = useState(false)
    const [refresh, setRefresh] = useState(false);
    const [error, setError] = useState(false);
    const [exportPDF, setExportPDF] = useState()
    const [exportExcel, setExportExcel] = useState()
    const [khuvucGia,setKhuvucGia] = useState({})
    const [phuphiXang, setPhuphiXang] = useState("")

    const pageSize = 15;

    const columns = [
        {
          field:"stt",
          headerName: lang.lotrinh.stt,
          rowSpan: 3,
          width: 40   
        },
        {
          field: 'saleName',
          headerName: lang.lotrinh.hoten,
          rowSpan: 3,
          sortable: false,
          width: 300,
          
        },
        {
          field: 'soNgayDangky',
          headerName: lang.lotrinh.songaydangky,
          sortable: false,
          width: 60,
          
        },
        {
          field: 'soNgayThucte',
          headerName: lang.lotrinh.songaytt,
          type: "number",
          sortable: false,
          width: 80,
          
        },
        {
          field: 'soNgayTangca',
          headerName: lang.lotrinh.songaytca,
          type: "number",
          sortable: false,
          width: 80,
          
        },
        {
          field: 'totalKM',
          headerName: lang.lotrinh.tongquangduongdi,
          type: "number",
          sortable: false,
          width: 80,
          
        },
        {
          field: 'sogioTangca',
          headerName: lang.lotrinh.sogiotca,
          type: "number",
          sortable: false,
          width: 80,
          
        },
        {
          field: 'soKmvuot',
          headerName: lang.lotrinh.sokmvuot,
          type: "number",
          sortable: false,
          width: 80,
          
        },
        {
          field: 'chiphiBiendong',
          headerName: lang.lotrinh.chiphibiendong,
          type: "number",
          sortable: false,
          width: 150,
          
        },
        {
          field: 'chiphiDangky',
          headerName: lang.lotrinh.chiphidangky,
          sortable: false,
          type: "number",
          width: 150
        },
        {
          field: 'chiphiTangca',
          headerName: lang.lotrinh.chiphitca,
          sortable: false,
          type: "number",
          width: 150
        },
        {
          field: 'chiphiKmvuot',
          headerName: lang.lotrinh.kmvuot,
          sortable: false,
          width: 150,
          
        },
        {
          field: 'chiphiPhuphiXang',
          headerName: lang.lotrinh.chiphiPhuphixang,
          sortable: false,
          width: 150,
        },
        {
          field: 'hotroWifi',
          headerName: lang.lotrinh.hotroWifi,
          sortable: false,
          width: 150,
        },
        {
          field: 'tongchiphi',
          headerName: lang.lotrinh.tongchiphi,
          sortable: false,
          width: 150,
          
        }
      ];
    
      const searchCond = useRef()
      const kvmName = useRef()
      

      const handleSearchDiemLotrinh = async (fromDate1, toDate1, pageIndex, selectedKVM, selectedStatuses, saleName, selectedSubKV) => {
        if(!selectedKVM) {
          return;
        }
        setShowLoading(true);
        const shouldGetKhuvucGia = !searchCond || (selectedKVM && searchCond.kvm != selectedKVM);
        kvmName.current = selectedKVM ? selectedSubKV && selectedSubKV.length > 0 ? `${selectedKVM.ten}(${selectedSubKV.map(k => k.ten) || ""})` : selectedKVM.ten : "";
        searchCond.current = {
          fromDate: fromDate1,
          toDate: toDate1,
          kvm: selectedKVM ? selectedKVM.id : 0,
          statuses: selectedStatuses,
          saleName: saleName || "",
          subKV: selectedSubKV && selectedSubKV.length > 0 ? selectedSubKV.map(k => k.id) : undefined,
          khuvucName: kvmName.current || ""
        }
        const sv = new KhuVucService()
        const ltsv = new LotrinhService();
        const gia = await sv.get(searchCond.current.kvm, fromDate1, toDate1)
        if(!gia.error) {
          const adminName = gia.saleAdmin ? JSON.parse(gia.saleAdmin).map(a => a.name).join(", ") : ""
          setKhuvucGia(Object.assign({}, gia, {
            adminName: adminName
          }));
        }
        const r = await ltsv.BaocaoTongHop(pageIndex + 1, pageSize, searchCond.current);
        setShowLoading(false);
        if(r.error || !r.result) {
          setError(true);
          return;
        }
        const l = r.result.map((d, idx) => {
          const h = parseInt(d.soGioTangca/60);
          const m = d.soGioTangca - parseInt(d.soGioTangca/60)*60;
          let tongchiphi = (d.chiphiBiendong || 0) + (d.chiphiDangky || 0) + (d.chiphiTangca || 0) + (d.chiphiKmvuot || 0);
          const chiphiPhuphiXang = Math.round((fromDate1 >='2022-01-31T17:00:01.000Z' ? (tongchiphi - (d.chiphiTangca || 0))  : tongchiphi)*d.phuphiXang*0.01, 0);
          tongchiphi += chiphiPhuphiXang;
          if(tongchiphi > 0) tongchiphi += (d.hotroWifi || 0)
          return Object.assign(d, {
            stt: pageIndex*pageSize + idx + 1,
            sogioTangca: `${h< 10 ? "0" : ""}${h}:${m<10 ? "0": ""}${m}`,
            chiphiBiendong: d.chiphiBiendong && d.chiphiBiendong > 0 ?  d.chiphiBiendong.toLocaleString() : "-",
            chiphiDangky: d.chiphiDangky && d.chiphiDangky > 0? d.chiphiDangky.toLocaleString() : '-',
            chiphiTangca: d.chiphiTangca && d.chiphiTangca > 0 ? d.chiphiTangca.toLocaleString(): '-',
            chiphiKmvuot:  d.chiphiKmvuot &&  d.chiphiKmvuot > 0 ? d.chiphiKmvuot.toLocaleString() : '-',
            chiphiPhuphiXang: chiphiPhuphiXang > 0 ? chiphiPhuphiXang.toLocaleString() : '-',
            tongchiphi: tongchiphi > 0 ? tongchiphi.toLocaleString(): '-',
            hotroWifi:  tongchiphi > 0 && d.hotroWifi ? d.hotroWifi.toLocaleString() : '-'
          })
        })
        setPhuphiXang(gia ? gia.phuphiXang : 0)
        setRows(l);
        setTotalRow(r.total);
      }

    useEffect(async () => {
      if(!exportPDF) {
        return;
      }
      
      const sv = new LotrinhService();
      await sv.exportPDF_Baocao_Tonghop(searchCond.current);
      setExportPDF();
    }, exportPDF)

    useEffect(async () => {
      if(!exportExcel) {
        return;
      }
      const sv = new LotrinhService();
      await sv.exportExcel_baocao_tonghop(searchCond.current);
      setExportExcel();
    }, exportExcel)

    return <div className="main-content">
      <div className="page-header">
        <div className="page-title">{lang.lotrinh.baocaotonghoptitle}</div>
        <div>
        <IconButton 
        onClick={evt => setExportExcel(true)} style={{
            color: lightGreen[600],
            backgroundColor: lightGreen[100],
            marginRight:10
        }}
        title={lang.lotrinh.exportExcel}
        >
            <SimCardDownload/>
        </IconButton>
        <IconButton 
        onClick={evt => setExportPDF(true)} style={{
            color: orange[600],
            backgroundColor: orange[100]
        }}
        title={lang.lotrinh.exportlotrinhThang}
        >
            <SimCardDownload/>
        </IconButton>
        </div>
        
      </div>
      
      <div style = {{
        paddingBottom:20
      }}>{`${lang.generaltext.thang}: ${new Date( searchCond && searchCond.current ? searchCond.current.fromDate : new Date()).toLocaleString("vi-vn", {month:'2-digit'})} / ${new Date(searchCond && searchCond.current ? searchCond.current.fromDate : new Date()).toLocaleString("vi-vn", {year:'numeric'})}`}</div>
        <SearchFilter cusId={cusId} disabledSearchText = {false} 
        disabledSearchLotrinhStatus={false}
        defaultStatuses = {[2]}
        onSearch = {handleSearchDiemLotrinh}
        pageIndex = {pageIndex}
        />
        <div style={{
          textAlign: "left",
          fontSize: 20
        }}>
          {`${lang.sale_working_plan.khuvuc}: ${kvmName && kvmName.current ? kvmName.current : ""}`}
          <br/>
          {`Admin: ${khuvucGia.adminName || ""}`}
          <br/><br/>
          <div className="flex-row" style={{
            justifyContent: "space-between",
            maxWidth:600
          }}>
            <div>
              {`${lang.generaltext.sokmquydinh}: ${khuvucGia.soKmQuyDinh || ""}`} 
              <br/>
              {khuvucGia.phuphiXang ? `${lang.generaltext.phuphixang}: ${khuvucGia.phuphiXang.toFixed(2)}%` : ""}
            </div>
            <div>
              {`${lang.generaltext.dongiatca}: ${(khuvucGia.dongiaTangCaGio || 0).toLocaleString()}`} 
              <br/>
              {`${lang.generaltext.dongiakmvuot}: ${(khuvucGia.dongiaKmVuot || 0).toLocaleString()}`}
            </div>
          </div>
          
        </div>
        <DataGrid
          rows={rows}
          rowHeaders ={[
            [{headerName: "Stt", rowSpan: 3, width: 60}
            ,{headerName:lang.lotrinh.hoten, rowSpan: 3, width: 250}
            ,{headerName:lang.lotrinh.thutesudung, colSpan: 6}
            ,{headerName:lang.lotrinh.chiphi, colSpan: 6}
            ,{headerName:lang.lotrinh.tongchiphi, rowSpan:3, width:90}],
            [{headerName: lang.lotrinh.songaydangky, width:85}
              ,{headerName:lang.lotrinh.songaytt, width:85}
              ,{headerName:lang.lotrinh.songaytca, width:85}
              ,{headerName:lang.lotrinh.tongquangduongdi, width:85}
              ,{headerName:lang.lotrinh.sogiotca, width:80}
              ,{headerName:lang.lotrinh.sokmvuot, width:90}
              ,{headerName:lang.lotrinh.chiphibiendong, width:120}
              ,{headerName:lang.lotrinh.chiphidangky, width:120}
              ,{headerName:lang.lotrinh.chiphitca, width:90}
              ,{headerName:lang.lotrinh.kmvuot, width:90}
              ,{headerName:lang.lotrinh.chiphiPhuphixang.replace("{phuphiXang}", phuphiXang), width:90}
              ,{headerName:lang.lotrinh.hotroWifi, width:90}],
            [{headerName: "C"}
              ,{headerName:"D"}
              ,{headerName:"E"}
              ,{headerName:"F"}
              ,{headerName:"H"}
              ,{headerName:"G"}
              ,{headerName:"I"}
              ,{headerName:"J"}
              ,{headerName:"K"}
              ,{headerName:"L"}
              ,{headerName:"M"}]
          ]}
          columns={columns}
          pageSize={pageSize}
          rowsPerPageOptions={[5]}
          checkboxSelection ={false}
          disableSelectionOnClick={false}
          disableColumnMenu={true}
          pagination={true}
          paginationMode={'server'}
          showColumnRightBorder={true}
          showCellRightBorder={true}
          loading={showLoading}
          onPageChange={page => setPageIndex(page)}
          rowCount={totalRow}
          autoHeight={true}
          autoPageSize={true}
          rowHeight={35}
          onRowClick={rowData =>{
            setSelectedRow(rowData.row);
            
            }}
        />
          <Backdrop
            sx={{ color: '#fff',opacity: 0.3, zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={showLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
    </div>
}