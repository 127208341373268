import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import LogoutIcon from "@mui/icons-material/ExitToAppOutlined"
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CalendarIcon from '@mui/icons-material/EventAvailable'
import ManageSearchIcon from "@mui/icons-material/ManageSearchRounded"
import PassworkeyIcon from "@mui/icons-material/VpnKey"

import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import CustomerList from "../List/Customers"
import lang from "../../asset/Language.json";
import { useEffect, useState } from 'react';

import UserService from '../../services/UserService';
import { UserTypeEnum } from '../../Contants/UserTypeEnum';
import MasterDataService from '../../services/MasterDataService';
import red from '@mui/material/colors/red';
import { styled, useTheme } from '@mui/material/styles';
import logo from "../../asset/img/logo.svg";
import { Typography } from '@mui/material';
import StaffList from '../List/StaffList';
import Sale_Working_Plan from '../List/StaffList/Sale_Working_Plan';
import DanhMuc from '../List/DanhMuc';
import Lotrinh from '../Lotrinh';
import ChangeUserPassword from "../Account/ChangePassword"

const drawerWidth = 240;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function AdminScreen() {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [listItemAction, setListItemAction] = useState('tracuu_lotrinh');
  const [isDone, setIsDone] = useState(false);
  const[menuSelectedIndex, setMenuSelectedIndex] = useState(0);
  const [listCustomer, setListCustomer] = useState([]);
  const [selectedCus, setSelectCus] = useState();
  const [extraTitle, setExtraTitle] = useState();
  const [dsProvince, setDsProvince] = useState()
  const [openUserMenu, setOpenUserMenu] = useState(false);
  const [changePassword, setChangePassword] = useState()
  const [openChangePassForm, setOpenChangePassForm] = useState();

  const theme = useTheme();
  const uSV = new UserService();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectedCus = (evt) => {
    setSelectCus(parseInt(evt.target.value));
  }
  
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  
  const handleListItemClick = (evt, index, key) => {
    setMenuSelectedIndex(index);
    setListItemAction(key);
    setExtraTitle('');
  }

  useEffect(async() => {
    const msv = new MasterDataService();
    const dsp = await msv.GetDanhSachProvince();
    const dsp1 = [{key: '', text: ''}].concat(dsp);
    setDsProvince(dsp1);
  },[]);

  useEffect(async () => {
    const cus = await uSV.getListCustomer(); 
    if(!cus || cus.error || cus.length == 0) {
      //show message error
      return;
    }
    setListCustomer(cus);
    setSelectCus(cus[0].cusId);
    return () => {
      setIsDone(true);
    }
  },[]);

  const userLogger = uSV.apiService.userLoggedIn;
  const listMenuItems = [{
    label: lang.functions.lotrinh,
    key: "tracuu_lotrinh",
      icon: <ManageSearchIcon />,
      enable: [UserTypeEnum.ADMINISTRATOR, UserTypeEnum.CUSTOMER, UserTypeEnum.NHANVIEN].indexOf(userLogger.type) > -1
  },
  {
    label: lang.functions.nhanvien_workingplan,
    key:"sale_working_plan",
      icon: <CalendarIcon />,
      enable: userLogger.type === UserTypeEnum.ADMINISTRATOR
  
    },{
    label: lang.functions.danhmuc,
    key: "danhmuc",
      icon: <AssignmentIcon />,
      enable: [UserTypeEnum.ADMINISTRATOR, UserTypeEnum.CUSTOMER, UserTypeEnum.NHANVIEN].indexOf(userLogger.type) > -1
  },
  
  {
  label: lang.functions.nhanvien,
  key:"staff",
    icon: <SupervisedUserCircleIcon />,
    enable: userLogger.type === UserTypeEnum.ADMINISTRATOR

  },
  {
    label: lang.functions.khachhang,
    key:"khachhang",
    icon: <ContactMailOutlinedIcon/>,
    enable: userLogger.type === UserTypeEnum.ADMINISTRATOR
    }];
  
    
   const currentView = (func) => {
      switch(func) {
        case "danhmuc":
          return <DanhMuc/>
        case "staff":
          return <StaffList setExtraTitle={setExtraTitle} dsProvince={dsProvince} cusId={selectedCus}/>;
        case "khachhang":
          return <CustomerList setExtraTitle={setExtraTitle} dsProvince={dsProvince} />;
        case "sale_working_plan":
          return <Sale_Working_Plan/>
        case "tracuu_lotrinh":
          return <Lotrinh cusId={selectedCus}/>
      }
   }
  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography noWrap component="div" className="fullWidth" style={{
            justifyContent:"flex-start",
            display:"flex",
            alignItems:"center"
          }}>
          <span style={{
             whiteSpace: "nowrap",
             paddingRight: 10,
             color: "#fff",
             textTransform: "uppercase",
             fontWeight: "bold"
           }}>Omoto Taxi</span>
          {listCustomer && ["khachhang"].indexOf(listItemAction) == -1 && <TextField
            className="customer-list-appbar"
            style={{
              
              width: "250px",
              display:"none",
              height: 45,
              maxWidth: "300px",
              borderRadius: "40px",
              color: "#fff",
              backgroundColor:"#fff"
            }}
            id="customerBox"
            select
            value= {selectedCus}
            onChange={handleSelectedCus}
            SelectProps={{
                native: true,
            }}
            helperText=""
            variant="filled"
            color="primary"
            >
            {listCustomer.map((option) => (
                <option key={option.cusId} value={option.cusId}>
                {option.cusName}
                </option>
            ))}
            </TextField>}
            </Typography>
          {(
            <div className="flex-nowrap">
              {userLogger.usrName}
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <AccountCircle/>
                </ListItemIcon>
                <ListItemText>{userLogger.usrName}</ListItemText>
                </MenuItem>
                <MenuItem onClick={evt => {
                  setOpenUserMenu(false)
                  setOpenChangePassForm(true)
                  }}>
                <ListItemIcon>
                    <PassworkeyIcon/>
                </ListItemIcon>
                  <ListItemText>{lang.generaltext.changepassword}</ListItemText>
                </MenuItem>
                <MenuItem onClick={evt => document.location.reload(true)}>
                <ListItemIcon>
                  <LogoutIcon/>
                </ListItemIcon>
                <ListItemText>{lang.generaltext.dangxuat}</ListItemText>
                </MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
      variant="permanent"
      open={open}
      >
        <DrawerHeader>
        <div className="fullWidth">
          <img src={logo} width="30px"/>
          </div>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List >
          {listMenuItems.filter(l => l.enable).map((item, index) => (
            <ListItem button key={item.key || ""} 
            onClick={e => handleListItemClick(e,index, item.key)}
            title={item.label}
            >
              <ListItemIcon style={{color: menuSelectedIndex === index ? "#73ca2f" : red[300]}}>{item.icon || <img width={item.iconWith || "25px"} src={item.src}/>}</ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1 }} style={{
        paddingLeft: 0,
        paddingRight: 0
      }} >
        <DrawerHeader />
        {currentView(listItemAction)}
      </Box>
      {openChangePassForm && <ChangeUserPassword
      onClose={evt => setOpenChangePassForm(false)}
      />  }
    </Box>
  );
}
