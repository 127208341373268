import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EditModeIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add'
import RefreshIcon from '@mui/icons-material/RefreshOutlined';

import { amber,brown, lightGreen, orange, purple, red, teal} from '@mui/material/colors';
import KhuVucService from '../../../services/KhuVucService';
import lang from "../../../asset/Language.json";
import KhuvucDetail from './KhuvucDetail';
import ErrorMessage from "../../../Components/AlertMessage";
import DongiaBuoi from './DongiaBuoi';
import DeleteDongiaBuoi from './DeleteDongiaBuoi';
import SubKhuvuc from './SubKhuvuc';
import DeleteSubKhuvuc from './DeleteSubKhuvuc';
import DeleteKhuvucMaster from './DeleteKhuvucMaster';
import { Button } from '@mui/material';
import KhuvucSaleAdmin from './KhuvucSaleAdmin';
import XoaKhuvucSaleAdmin from './XoaKhuvucSaleAdmin';

function Row(props) {
  const { myRow, dsKhuvuc, setError,setRows,isAdmin } = props;
  const [open, setOpen] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [row, setRow] = useState(myRow);
  const[refresh, setRefresh] = useState(true);
  
  const renderDongiabuoi = (dongiabuoi) => {
    let kg;
    let kgText;
    let color = [purple[100], amber[100], orange[400], brown[400], teal[300]];
    let colori = -1;
    return  <Table size="small" aria-label="purchases">
      <TableHead>
        <TableRow>
          <TableCell>{lang.generaltext.khunggio}</TableCell>
          <TableCell>{lang.generaltext.sobuoi}</TableCell>
          <TableCell align="right">{`${lang.generaltext.dongia} (VND)`}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          dongiabuoi.sort((a,b) => {
            return  a.khunggioName.localeCompare(b.khunggioName)
          }).map((r,idx) => {
            if(kg !== r.khunggio) {
              kg = r.khunggio;
              kgText = r.khunggioName;
              colori++;
            }
            else {
              kgText ="";
            }
            
            return (
            <TableRow key={r.Id} style={{
              backgroundColor: color[colori]
            }}>
              <TableCell component="th" scope="row" style={{
              border:0
            }}>
                {kgText}
              </TableCell>
            <TableCell>{r.sobuoi1} - {r.sobuoi2}</TableCell>
              <TableCell align="right">{r.dongia.toLocaleString()}</TableCell>
              {isAdmin && <TableCell style={{
                width: 30,
                padding:0
              }}>
                  <IconButton onClick={evt => setDialogData({
                    id: "dongiabuoi",
                    data: r
                  })}  >
                            <EditModeIcon style={{
                              width:20,
                              height:20,
                              color: teal[400]
                            }}/>
                  </IconButton>
              </TableCell>}
              {isAdmin && <TableCell style={{
                width: 30,
                padding:0
              }}>
                  <IconButton onClick={evt => setDialogData({
                    id: "xoadongiabuoi",
                    data: r
                  })} >
                            <DeleteIcon style={{
                              width:20,
                              height:20,
                              color: red[400]
                            }}/>
                  </IconButton>
              </TableCell>}
            </TableRow>
          )
          
        })
        }
      </TableBody>
    </Table>
    
  }

  const renderSubKhuvuc = (subkhuvuc) => {
    return  <Table>
      <TableBody>
      {subkhuvuc.sort((a,b) => {
      return  a.Ten.localeCompare(b.Ten)}).map((r,idx) => <TableRow>
        <TableCell style={{
          width:100
        }}>
          {r.Ten}
        </TableCell>
        {isAdmin && <TableCell style={{
          width: 30,
          padding:0
        }}>
            <IconButton onClick={evt => setDialogData({
              id: "subkhuvuc",
              data: r
            })}  >
                      <EditModeIcon style={{
                        width:20,
                        height:20,
                        color: teal[400]
                      }}/>
            </IconButton>
        </TableCell>}
        {isAdmin && <TableCell style={{
          width: 30,
          padding:0
        }}>
            <IconButton onClick={evt => setDialogData({
              id: "xoasubkhuvuc",
              data: r
            })} >
                      <DeleteIcon style={{
                        width:20,
                        height:20,
                        color: red[400]
                      }}/>
            </IconButton>
        </TableCell>}
      
      </TableRow>)}
      </TableBody>
    </Table>
    
  }

  const renderSaleAdmin = (saleAdmin) => {
    return  <Table>
      <TableBody>
      {saleAdmin.sort((a,b) => {
      return  a.name.localeCompare(b.name)}).map((r,idx) => <TableRow>
        <TableCell style={{
          width:100
        }}>
          {`${r.name} (${r.email})`}
        </TableCell>
        
        {isAdmin && <TableCell style={{
          width: 30,
          padding:0
        }}>
            <IconButton onClick={evt => setDialogData({
              id: "xoaSaleAdmin",
              data: r
            })} >
                      <DeleteIcon style={{
                        width:20,
                        height:20,
                        color: red[400]
                      }}/>
            </IconButton>
        </TableCell>}
      
      </TableRow>)}
      </TableBody>
    </Table>
    
  }


  const renderDialog = () => {
    switch(dialogData.id){
      case "khuvucdetail":
        return <KhuvucDetail setError= {setError} open={true} khuvuc={dialogData.data} setClose={setDialogData} setRow={setRow}/>;
      case "dongiabuoi":
        return <DongiaBuoi setError= {setError} open={true} setClose={setDialogData} dgia={dialogData.data} dsKhuvuc={dsKhuvuc} row={row} setRow={setRow}/>
      case "xoadongiabuoi":
        return <DeleteDongiaBuoi setError= {setError} open={true} setClose={setDialogData} dgia={dialogData.data} row={row} setRow={setRow}/>
      case "subkhuvuc":
        return <SubKhuvuc setError= {setError} open={true} setClose={setDialogData} data={dialogData.data} row={row} setRow={setRow} dsKhuvuc={dsKhuvuc}/>
      case "xoasubkhuvuc":
        return <DeleteSubKhuvuc setError= {setError} open={true} setClose={setDialogData} data={dialogData.data} row={row} setRow={setRow}/>
      case "xoakhuvuc":
        return <DeleteKhuvucMaster setError= {setError} open={true} data={dialogData.data} setClose={setDialogData} setRow={setRows} rows={dsKhuvuc}/>
      case "saleAdmin":
        return <KhuvucSaleAdmin setError= {setError} open={true} khuvuc ={row} saleAdmin={row.Admin} setClose={setDialogData} setRow={setRow}/>
      case "xoaSaleAdmin":
        return <XoaKhuvucSaleAdmin setError= {setError} open={true} khuvuc ={row} deletedAdmin={dialogData.data} setClose={setDialogData} setRow={setRow}/>
      default:
        return;
    }
  }
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} style={open ? {
        backgroundColor: lightGreen[100]
      }:{}}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" style={{
          textAlign:"left",
          width:"100%"
        }}>
          {row.Ten}
        </TableCell>
        {isAdmin && <TableCell>
        <IconButton title={`${lang.generaltext.updatetext}`} 
          style={{width: 30, height:30}}
          onClick={event => setDialogData({id: "khuvucdetail", data: row})}
          >
              <EditModeIcon style={{
                width:20,
                height:20,
                color: teal[400]
              }}/>
        </IconButton>
        </TableCell>}
        {isAdmin && <TableCell>
        <IconButton title={`${lang.generaltext.deletedText}`} 
          style={{width: 30, height:30}}
          onClick={event => setDialogData({id: "xoakhuvuc", data: row})}
          >
              <DeleteIcon style={{
                width:20,
                height:20,
                color: red[400]
              }}/>
        </IconButton>
        </TableCell>}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, alignItems:"flex-start", justifyContent:"space-between" }} className="flex-wrap">
              <div>
                <div className="flex-column dg-kv">
                  <div className="flex-row sp-bt max450">
                  <h4>{lang.generaltext.dongia}</h4>
                  </div>
                  <div className="flex-row sp-bt max450">
                    <span>{lang.generaltext.sokmquydinh}:</span>
                    <span><b>{row.SokmQuydinh}</b>&nbsp;km</span>
                    
                  </div>
                  <div className="flex-row sp-bt max350">
                  <span>{lang.generaltext.dongiatca}:</span>
                  <span><b>{row.DongiaTangcaGio.toLocaleString()}</b> VND</span>
                  </div>
                  <div className="flex-row sp-bt max350">
                    <span>{lang.generaltext.dongiakmvuot}:</span>
                    <span><b>{row.DongiaKmVuot.toLocaleString()}</b> VND</span>
                  </div>
                  <div className="flex-row sp-bt max350" style={{
                    paddingTop:30
                  }}>
                  <span>{lang.generaltext.dongiabuoi}:</span>
                  {isAdmin && <span style={{
                        color: teal[400]}}>
                    {lang.generaltext.themdongiabuoi}
                  <IconButton onClick={evt => setDialogData({
                    id: "dongiabuoi",
                    data: {
                      khuvuc: row.Id,
                    }
                  })} style={{width: 30, height:30}}>
                      <AddIcon style={{
                        width:20,
                        height:20,
                        color: teal[400]
                      }}/>
                    </IconButton>
                  </span>}
                  
                  </div>
                  
                  
                </div>
                <div style={{
                  maxWidth:500
                }}>
                {renderDongiabuoi(row.Dongiabuoi || [])}
                </div>
              </div>
              {<div className="flex-column" style={{maxWidth:300}}>
                <div className="flex-row" style={{
                  alignItems: "flex-end"
                }}>
                  <h4>{lang.generaltext["sub-khuvuc"]}</h4>
                  {isAdmin && <span style={{
                        color: teal[400]}}>
                    {lang.generaltext.themsubkhuvuc}
                  <IconButton onClick={evt => setDialogData({
                    id: "subkhuvuc",
                    data: {
                      Khuvuc: row.Id,
                    }
                  })} style={{width: 30, height:30}}>
                      <AddIcon style={{
                        width:20,
                        height:20,
                        color: teal[400]
                      }}/>
                    </IconButton>
                  </span>}
                </div>
                {renderSubKhuvuc(row.Sub_Khuvuc || [])}
              </div>}
              <div className="flex-column" style={{maxWidth:400}}>
                <div className="flex-row" style={{
                  alignItems: "flex-end"
                }}>
                  <h4>{lang.generaltext["saleAdmin"]}</h4>
                  {isAdmin && <span style={{
                        color: teal[400]}}>
                    {lang.generaltext.themSaleAdmin}
                  <IconButton onClick={evt => setDialogData({
                    id: "saleAdmin",
                    data: {}
                  })} style={{width: 30, height:30}}>
                      <AddIcon style={{
                        width:20,
                        height:20,
                        color: teal[400]
                      }}/>
                    </IconButton>
                  </span>}
                </div>
                {renderSaleAdmin(row.SaleAdmin || [])}
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {renderDialog()}
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    Dongiabuoi: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        khuvuc: PropTypes.number.isRequired,
        khunggio: PropTypes.number.isRequired,
        sobuoi1: PropTypes.number.isRequired,
        sobuoi2: PropTypes.number.isRequired,
        dongia: PropTypes.number.isRequired,
        khunggioName: PropTypes.string.isRequired
      }),
    ).isRequired,
    Sub_Khuvuc: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        khuvuc: PropTypes.number.isRequired,
        khunggio: PropTypes.number.isRequired,
        sobuoi1: PropTypes.number.isRequired,
        sobuoi2: PropTypes.number.isRequired,
        dongia: PropTypes.number.isRequired,
        khunggioName: PropTypes.string.isRequired
      }),
    ).isRequired,
    Ten: PropTypes.string.isRequired,
    SokmQuydinh: PropTypes.number.isRequired,
    DongiaTangcaGio: PropTypes.string.isRequired,
    DongiaKmVuot: PropTypes.string.isRequired
  }).isRequired,
};

export default function KhuvuMaster() {
  const [rows, setRows] = useState([]);
  const [refresh, setRefresh] = useState();
  const [error, setError] = useState(false)
  const [addKhuvuc,setAddKhuvuc] = useState(false)
  
  const sv = new KhuVucService();
  const isAdmin = sv.api.isAdmin;

  useEffect(async() => {
    const r = await sv.khuVucMaster();
    if(r.error) {
      return;
    }

    setRows(r.map(k => Object.assign(k,{
      Dongiabuoi: k.Dongiabuoi? JSON.parse(k.Dongiabuoi): [],
      Sub_Khuvuc: k.Sub_Khuvuc ? JSON.parse(k.Sub_Khuvuc): [],
      FromDate: k.FromDate ? new Date(`${k.FromDate}Z`) : new Date(),
      SaleAdmin: k.SaleAdmin ? JSON.parse(k.SaleAdmin) : []
    })))
  },[refresh])
  return (
    <React.Fragment>
      
      <ErrorMessage error={error} setError={setError}/>
      <div className="page-header">
      <div className="page-title"><span>{lang.lotrinh.dsKhuvucMaster}</span></div>
      <div >
      {isAdmin && <Button variant="text" className="btn-text" onClick={evt => setAddKhuvuc(true)}><AddIcon />{lang.generaltext.themkhuvuc}</Button>}
        <Button variant="text" className="btn-text" onClick={evt => setRefresh(!refresh)}><RefreshIcon/>Refresh</Button>
      </div>
      </div>
      
      <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableBody>
          {rows.map((row) => (
            <Row key={row.Ten} myRow={row} dsKhuvuc={rows || []} setError={setError} setRows={setRows} isAdmin= {isAdmin}/>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    {addKhuvuc && <KhuvucDetail setError= {setError} open={true} khuvuc={{}} setClose={evt => setAddKhuvuc(false)} setRow={setRows} dsKhuvuc={rows}/>}
    </React.Fragment>
    
  );
}
