import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import lang from "../../asset/Language.json";
import { LocalizationProvider, TimePicker } from "@mui/lab";
import React, { useEffect, useState } from "react";
import Dialog from "../../Components/ConfirmDialog";
import { isEqual, pick } from "lodash";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LotrinhService from "../../services/LotrinhService";

export default function UpdateLotrinh(props) {
  const { lotrinh, open, setUpdateLotrinh, setRefresh, saleId } = props;
  const ltStartTime = new Date(
    `$${lotrinh.StartTime || lotrinh.OriginalStartTime}Z`
  );
  const ltEndTime = lotrinh.EndTime
    ? new Date(`${lotrinh.EndTime || lotrinh.OriginalEndTime}Z`)
    : null;

  const [startTime, setStartTime] = useState(
    new Date(`${lotrinh.StartTime || lotrinh.OriginalStartTime}Z`)
  );
  const [endTime, setEndTime] = useState(
    new Date(`${lotrinh.EndTime || lotrinh.OriginalEndTime}Z`)
  );
  const [distance, setDistance] = useState(
    lotrinh?.updatedSokm || lotrinh?.sokm
  );
  const [invalidEndTime, setInvalidEndTime] = useState();
  const [invalidStartTime, setInvalidStartTime] = useState();
  const [note, setNote] = useState();
  const [updated, setUpdated] = useState();
  const [status, setStatus] = useState(lotrinh?.status);
  const [deleted, setDeleted] = useState(false);
  const [startAddress, setStartAddress] = useState(lotrinh?.startAddress);
  const [endAddress, setEndAddress] = useState(lotrinh?.endAddress);

  const canSave = () => {
    const lt = Object.assign(
      {},
      pick(lotrinh, ["id", "ngay", "StartTime", "EndTime", "status", "startAddress", "endAddress"]),
      {}
    );
    const cur = {
      id: lotrinh.id,
      ngay: lotrinh.ngay,
      StartTime: startTime.toISOString().split(".")[0],
      EndTime: lt.EndTime ? endTime.toISOString().split(".")[0] : null,
      status: parseInt(status),
      startAddress: lt.startAddress,
      endAddress: lt.endAddress
    };
    const isChanged =
      !isEqual(lt, cur) || distance !== (lotrinh.updatedSokm || lotrinh.sokm) || startAddress !== lotrinh.startAddress || endAddress !== lotrinh.endAddress;
    return isChanged && !invalidEndTime && !invalidStartTime;
  };

  useEffect(async () => {
    if (!updated) {
      return;
    }
    const sv = new LotrinhService();
    const data = {
      id: lotrinh.lotrinhId,
      distance: parseInt(distance * 1000),
      startTime: startTime.toISOString(),
      endTime: endTime.toISOString(),
      note: note || "",
      status: status,
      startAddress: startAddress,
      endAddress: endAddress
    };

    await sv.adminUpdate(data);
    setUpdated(false);
    setUpdateLotrinh(false);
    setRefresh(true);
  }, [updated]);

  useEffect(async () => {
    if (!deleted) return;

    const sv = new LotrinhService();
    await sv.delete(lotrinh.lotrinhId, saleId);
    setDeleted(false);
    setUpdateLotrinh(false);
    setRefresh(true);
  }, [deleted]);

  const content = (
    <div>
      <Stack direction="column" spacing={1}>
        <Typography variant="h5" style={{ paddingBottom: 20 }}>
          {lang.lotrinh.chinhsualotrinhConfirm}
        </Typography>
        <Stack direction="row" spacing={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              label={lang.lotrinh.thoigianbd}
              value={startTime}
              minTime={
                new Date(
                  ltStartTime.getFullYear(),
                  ltStartTime.getMonth(),
                  ltStartTime.getDate(),
                  1,
                  0,
                  0
                )
              }
              maxTime={
                new Date(
                  ltStartTime.getFullYear(),
                  ltStartTime.getMonth(),
                  ltStartTime.getDate(),
                  (endTime || ltEndTime)?.getHours(),
                  0,
                  0
                )
              }
              onChange={(newValue) => {
                setStartTime(newValue);
              }}
              ampm={false}
              onError={(evt) => setInvalidStartTime(true)}
              onAccept={(evt) => setInvalidStartTime(false)}
              renderInput={(params) => (
                <TextField
                  onKeyDown={(evt) => {
                    evt.preventDefault();
                  }}
                  {...params}
                />
              )}
            />
            <TimePicker
              label={lang.lotrinh.thoigiankt}
              value={endTime}
              ampm={false}
              minTime={
                new Date(
                  ltStartTime.getFullYear(),
                  ltStartTime.getMonth(),
                  ltStartTime.getDate(),
                  (startTime || ltStartTime).getHours(),
                  (startTime || ltStartTime).getMinutes(),
                  (startTime || ltStartTime).getSeconds()
                )
              }
              maxTime={
                new Date(
                  ltStartTime.getFullYear(),
                  ltStartTime.getMonth(),
                  ltStartTime.getDate(),
                  23,
                  59,
                  59
                )
              }
              onError={(evt) => setInvalidEndTime(true)}
              onAccept={(evt) => setInvalidEndTime(false)}
              onChange={(newValue) => {
                if (isNaN(newValue)) {
                  return;
                }
                setEndTime(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  onKeyDown={(evt) => {
                    evt.preventDefault();
                  }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Stack>
        <TextField
          label={lang.lotrinh.quangduong}
          value={parseFloat((distance * 1).toFixed(3))}
          onInput={(evt) => setDistance(evt.target.value * 1)}
          InputProps={{
            endAdornment: <InputAdornment position="end">km</InputAdornment>,
          }}
          type="number"
          min={1}
          max={200}
          error={distance <= 0 || distance > 200}
        />
        <TextField
          label={lang.lotrinh.diadiemdon}
          value={startAddress}
          onInput={(evt) => setStartAddress(evt.target.value)}
        />
        <TextField
          label={lang.lotrinh.diadiemtra}
          value={endAddress}
          onInput={(evt) => setEndAddress(evt.target.value)}
        />
        <TextField
          label={lang.lotrinh.lydochinhsualotrinh}
          value={note}
          onInput={(evt) => setNote(evt.target.value)}
          multiline
          rows={3}
          required
          maxRows={5}
          error={note && note.length > 199}
        />
        <Stack>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              {lang.lotrinh.trangthai}
            </FormLabel>
            <RadioGroup
              column
              value={status?.toString()}
              onChange={(evt) => {
                setStatus(evt.target.value);
              }}
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="0"
                disabled={status > 0}
                control={<Radio />}
                label={lang.lotrinh.lotrinhmoi}
              />
              <FormControlLabel
                value="1"
                control={<Radio />}
                label={lang.lotrinh.lotrinhDaHoanthanh}
              />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label={lang.lotrinh.lotrinhApproved}
              />
              <FormControlLabel
                value="3"
                control={<Radio />}
                label={lang.lotrinh.lotrinhRejected}
              />
            </RadioGroup>
          </FormControl>
        </Stack>
      </Stack>
    </div>
  );

  return (
    <Dialog
      className="dialog-a-visible"
      okText={lang.buttons.luu}
      cancelText={lang.buttons.boqua}
      disabledYes={!canSave()}
      open={open}
      onClose={(evt) => setUpdateLotrinh(false)}
      onYes={(evt) => setUpdated(true)}
      msgContent={content}
      extraButton={lotrinh.status === 3}
      extraText="Delete"
      onExtra={(evt) => setDeleted(true)}
    />
  );
}
