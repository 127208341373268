import { useEffect, useState } from "react";
import { UserTypeEnum } from "../../Contants/UserTypeEnum";
import UserService from "../../services/UserService";
import KhuVucService from "../../services/KhuVucService";
import LotrinhService from "../../services/LotrinhService";
import { Box, Button, InputAdornment, Stack, TextField } from "@mui/material";
import SearchDropDown from "../../Components/SearchDropDown";
import Icon from "@mui/icons-material/AccountCircle"
import lang from "../../asset/Language.json"
import { lightGreen, red } from "@mui/material/colors";
import { isEmpty, isEqual, pick } from "lodash";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

export default function SalePlanWorking(props) {

    const { onClose } = props;
    const [taixe, setTaixe] = useState(0)
    const [dsTaixe, setDsTaixe] = useState([])
    const [dsKhuvuc, setDsKhuvuc] = useState([])
    const [ready, setReady] = useState();
    const [songaydangky, setSongaydangky] = useState(0)
    const [khunggio, setKhunggio] = useState()
    const [dsKhunggio, setDsKhunggio] = useState([])
    const [selectedKhuVuc, setSelectedKhuvuc] = useState()
    const [editable, setEditable] = useState("");
    const [error, setError] = useState();
    const [myPlan, setMyPlan] = useState({});
    const [saveChanges, setSaveChanges] = useState(false);
    const [workingPlace, setWorkingPlace] = useState("")
    const [reportEmail, setReportEmail] = useState("")


    const allowToModifyPlan = () => {
        const today = new Date().getDate();
        return today < 6;
    };

    const toModifyPlan = allowToModifyPlan();



    const getNhanVien = async (type, cusId, ten) => {
        const usv = new UserService();
        if (type == UserTypeEnum.Driver) {
            const r = await usv.driverAvailable(taixe);
            if (r.error) {
                // setError(true);
                return;
            }
            return r;
        }
        const r = await usv.searchStaff(1, 500, {
            chucvu: type,
            cusId: cusId,
            ten: ten
        });
        if (r.error) {
            //setError(true);
            return;
        }
        return r.result;
    }

    const canSave = () => {
        const lt = { ...pick(myPlan, ["id", "driver", "khuvuc", "workingday", "khunggio", "workingPlace", "workingplace", "reportEmail", "reportemail"]), };
        const cur = {
            id: myPlan.id,
            driver: taixe,
            khuvuc: selectedKhuVuc,
            workingday: songaydangky,
            khunggio: khunggio,
            reportEmail: reportEmail,
            workingPlace: workingPlace
        };
        const isChanged = !isEqual(lt, cur);
        return myPlan.id && taixe && selectedKhuVuc && isChanged;
    }

    useEffect(async () => {
        //get tai xe
        const sv = new UserService();
        const today = new Date();
        const fromDate1 = new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, 1).toISOString();
        const toDate1 = new Date(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 59).toISOString();

        const myPlan = await sv.getWorkingPlan(fromDate1, toDate1, 0, "", null);
        if (myPlan.error || myPlan.length == 0) {
            setError(true);
            return;
        }
        setTaixe(myPlan[0].driver);
        setSelectedKhuvuc(myPlan[0].khuvuc);
        setKhunggio(myPlan[0].khunggio);
        setSongaydangky(myPlan[0].workingday);
        setWorkingPlace(myPlan[0].workingPlace);
        setReportEmail(myPlan[0].reportEmail);
        setMyPlan(myPlan[0]);


    }, [ready]);

    useEffect(async () => {
        if (isEmpty(myPlan)) {
            return;
        }
        //get tai xe
        const ksv = new KhuVucService();
        const ltsv = new LotrinhService();

        Promise.all([
            getNhanVien(UserTypeEnum.Driver, 0),
            ksv.getAllSubKhuvucByKhuvuc(0),
            ltsv.getAllKhunggio()
        ]).then(r => {

            if (r[0].error || r[1].error || r[2].error) {
                setError(true);
                return;
            }
            setDsTaixe(r[0]);
            setDsKhuvuc(r[1]);
            setDsKhunggio(r[2]);
        });
    }, [myPlan]);

    useEffect(async () => {
        if (!saveChanges) {
            return;
        }
        setSaveChanges(false);
        const sv = new UserService();
        const data = {
            id: myPlan.id,
            driver: taixe,
            khuvuc: selectedKhuVuc,
            khunggio: khunggio,
            workingday: songaydangky,
            workingPlace: workingPlace,
            reportEmail: reportEmail
        };

        const r = await sv.updateWorkingPlan(data);

        if (r.error) {
            setError(true);
            return;
        }
    }, [saveChanges])


    return <Box sx={{
        justifyContent: "flex-start",
        maxWidth: 360,
        margin: "0 auto",
        textAlign: "left",
        width: "100%",
        padding: "0 15px"
    }}>
        <div className="flex-wrap">
            <div style={{
                flex: 1,
                paddingTop: 10
            }}>
                <Button sx={{
                    padding: "15px 0",
                    marginBottom: "30px"
                }} variant="text" className="btn-text" onClick={evt => onClose(false)}><KeyboardBackspaceIcon />{lang.generaltext.back}</Button>
                <div className="flex-row">
                    <SearchDropDown
                        className="inp-fld"

                        style={{
                            width: "100%"
                        }}
                        fullWidth={true}
                        disabled={editable != "1"}
                        label={lang.lotrinh.khuvuc}
                        type="number"
                        value={selectedKhuVuc}
                        options={(dsKhuvuc).map((option) => ({
                            key: option.id,
                            text: `${option.ten}`,
                            secondary: option.khuvucName || ""
                        }))}
                        onChange={value => setSelectedKhuvuc(value)}
                    />
                </div>
                <div className="flex-row">
                    <SearchDropDown
                        className="inp-fld"
                        style={{
                            width: "100%"
                        }}
                        label={lang.lotrinh.khunggio}
                        type="number"
                        fullWidth={true}
                        disabled={editable != "1"}
                        value={khunggio}
                        options={(dsKhunggio || []).map((option) => ({
                            key: option.id,
                            text: `${option.startTime} - ${option.endTime}`
                        }))}
                        onChange={value => setKhunggio(value)}
                    />
                </div>
                <div className="flex-row" style={{
                    marginBottom: 20
                }}>

                    <TextField
                        className="inp-fld"
                        style={{
                            width: "100%"
                        }}
                        label={lang.lotrinh.songaydangky}
                        variant="standard"
                        type="number"
                        disabled={editable != "1"}
                        value={songaydangky}
                        onInput={e => setSongaydangky(e.target.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{lang.generaltext.buoi}</InputAdornment>
                        }}
                    />
                </div>
                <div className="flex-row">
                    <SearchDropDown
                        id="searchbox_taixe"
                        className="inp-fld"
                        style={{
                            width: "100%",
                        }}
                        value={taixe}
                        fullWidth={true}
                        disabled={editable != "1"}
                        label={lang.lotrinh.taixe}
                        options={dsTaixe.map(t => ({
                            key: t.usrId,
                            text: t.name,
                            secondary: t.phoneNumber
                        }))}
                        onChange={value => setTaixe(value)}
                        icon={<Icon style={{ color: lightGreen[600] }} />}
                    />
                </div>
                <div className="flex-row">
                    <TextField
                        className="inp-fld"
                        fullWidth={true}
                        label={lang.sale_working_plan.workingplace}
                        variant="standard"
                        type="text"
                        value={workingPlace}
                        onInput={e => setWorkingPlace(e.target.value)}
                    />
                </div>
                <div className="flex-row">
                    <TextField
                        className="inp-fld"
                        fullWidth={true}
                        label={lang.sale_working_plan.reportemail}
                        variant="standard"
                        type="email"
                        value={reportEmail}
                        onInput={e => setReportEmail(e.target.value)}
                    />
                </div>
            </div>
        </div>
        <Stack direction={"row"}>
            <Button
                style={{
                    marginRight: 16
                }}
                variant="outlined"
                onClick={evt => setEditable("1")}
                disabled={editable == "1" || !toModifyPlan}
            >Update</Button>
            {editable && <Button
                disabled={!canSave()}
                onClick={evt => setSaveChanges(true)}
                variant="contained">Save</Button>}
        </Stack>
        {!toModifyPlan && <div style={{
            color: red[900],
            fontWeight: 500,
            paddingTop: 15,
            fontStyle: "italic"
        }}>{lang.sale_working_plan.changeplanNote}</div>}
    </Box>

}