import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SlideMenu from "./SlideMenu";
import DanhSachLotrinh from './DanhsachLotrinh';
import EventIcon from '@mui/icons-material/Event';
import ApiService from '../../services/ApiService';
import StartLotrinh from './StartLotrinh';
import ChangeUserPassword from "../Account/ChangePassword"

export default function DriverScreen() {

  const [openSlideMenu, setOpenSlideMenu] = React.useState(false)
  const [showDateRange, setShowDateRange] = React.useState(false);
  const [selectedItemMenu, setSelectedItemMenu] = React.useState("startLotrinh");
  const [openUserMenu, setOpenUserMenu] = React.useState(false);
  const [changePassword, setChangePassword] = React.useState()
  const [openChangePassForm, setOpenChangePassForm] = React.useState();


  const menuId = 'primary-search-account-menu';
  const api = new ApiService()

  const userName = api.userLoggedIn.usrName;

  const view = () => {
    switch(selectedItemMenu) {
      case "truyxuatlotrinh":
        return <DanhSachLotrinh showDateRange ={showDateRange} setShowDateRange ={setShowDateRange}/>
      default:
        return <StartLotrinh/>
    }
  }
  return (
    
    <Box sx={{ flexGrow: 1,marginTop:"55px" }}>
      <AppBar position="fixed">
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { sm: 'block', color:"#FFF", fontSize:14 } }}
          >
            Omoto Taxi
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { md: 'flex' } }}>
            {selectedItemMenu == "truyxuatlotrinh" && <IconButton onClick = {evt => setShowDateRange(true)}>
                <EventIcon style={{
                    color: "#fff"
                }}/>
            </IconButton>}
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={evt => setOpenSlideMenu(true)}
              color="inherit"
              sx={{
                fontSize: 10,
                backgroundColor: "#fff",
                width: 30,
                height: 30,
                textTransform: "uppercase",
                fontWeight: "bold",
                color: "#000"
            }}
            >
              {userName.substr(0,2)}
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {view()}
      <SlideMenu open={openSlideMenu} setOpen = {setOpenSlideMenu} userName = {userName} 
      setOpenUserMenu ={setOpenUserMenu}
      setOpenChangePassForm={setOpenChangePassForm} openChangePassForm = {openChangePassForm}
      setSelectedItemMenu={setSelectedItemMenu}/>
      {openChangePassForm && <ChangeUserPassword
      onClose={evt => setOpenChangePassForm(false)}
      />  }
    </Box>
  );
}
