import React from "react"
import  Typography from "@mui/material/Typography";
import { Button, TextField } from "@mui/material";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  header: {
      "padding-bottom": "20px"
  },
  formContentCol1: {
    display:"flex",
    "flex-direction": "column",
    
  },
  formContentCol2: {
    display:"flex",
    "flex-wrap": "wrap",
    "justify-content": "space-between"
  },
  formField1: {
      "margin-bottom": "15px"
  },
  formField2: {
    "margin-bottom": "15px",
    "width": "48%"
  },
  submitBtn: {
      height: "45px",
      color: "#fff",
      "font-size": "18px",
      "margin-bottom": "20px"
  },
});


const InputForm = (props) => {
    const {title, subtitle, fields, buttonLabel, footer, onSubmit, columns} = props;
    const classes = useStyles();
    return(
        <div className={classes.formContentCol1}>
            {title  || subtitle ? <div className={classes.header}>
                {title ? <Typography variant="h4" component="h2">{title}</Typography> : null}
                {subtitle? subtitle : null}
            </div>: null}
            <div className={columns == 2 ? classes.formContentCol2 : classes.formContentCol1}>
                {fields.map(f => <TextField 
                onChange={e=> f.onChange ? f.onChange(e) : null } 
                type={f.type || "text"} 
                InputProps={f.adorment}
                onBlur={e => f.onBlur ? f.onBlur(e) : null} 
                onFocus={e => f.onFocus? f.onFocus(e): null}
                className={f.class ? f.class : columns == 2 ? classes.formField2 : classes.formField1} 
                variant="outlined" label={f.label} 
                placeholder={f.placeholder} 
                error= {f.error}
                autoComplete="off"/>)}
            </div>
            {buttonLabel ? <Button className={classes.submitBtn} variant="contained" color="primary" onClick={evt => onSubmit(evt)}>{buttonLabel}</Button>: null}
            {footer ? footer : null}            
        </div>
    )
}

export default InputForm