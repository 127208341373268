import * as React from 'react';
import StaticDateRangePicker from '@mui/lab/MonthPicker';
import MonthYearPicker from '../../Components/MonthYearPicker';

export default function StaticDateRangePickerDemo(props) {
  const {value, setValue} = props
  const d = new Date();  

  return (
    <React.Fragment>
      <MonthYearPicker value={value} setValue={setValue} views={['year']} />
      <StaticDateRangePicker
        date={value}
        key="month-view-selected"
        minDate={d.setFullYear(d.getFullYear()-1, d.getMonth(), d.getDay())}
        maxDate={new Date()}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        fullWidth
      />
    </React.Fragment>
      
  );
}