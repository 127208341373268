import { Box, Button, IconButton } from "@mui/material"
import React, { useEffect, useState } from "react"
import DataTable from "../../Components/DataTable"
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import lang  from "../../asset/Language.json"
import LotrinhService from "../../services/LotrinhService";
import { lightGreen, orange, purple, red } from "@mui/material/colors";
import { SimCardDownload, Create } from "@mui/icons-material";
import UpdateLotrinh from "./UpdateLotrinh"
import UserService from "../../services/UserService"
import { UserTypeEnum } from "../../Contants/UserTypeEnum"
import { format } from "date-fns";
import LeavingService from "../../services/LeavingService"
import { isNil } from "lodash"

export default function BangLotrinhChitiet(props){
    const { selectedNV, req, goback} = props;
    const [data,setData] = useState({result:[], summaryData:{}, songaythucte: 0});
    const [refresh, setRefresh] = useState();
    const [exportDF, setExportPDF] = useState();
    const [exportExcel, setExportExcel] = useState()
    const [selectedLotrinh, setSelectedLotrinh] = useState();
    const [updateLotrinh, setUpdateLotrinh] = useState(false);
    const [generateData, setGenerateData] = useState();
    const [enabledGenerateData, setEnabledGenerateData] = useState(false);
    

    const uSV = new UserService();
    const userLogger = uSV.apiService.userLoggedIn;
 
    const columns=[
    {
        field: "id",
        sumValue: "Tổng:"
    },    
    {
        field: "Ngay",
        headerName: lang.lotrinh.ngay,
        width:100,
        sumValue: `${data.songaythucte} (${lang.lotrinh.ngay})`
    },
    {
        field: "originalStartTime",
        headerName: lang.lotrinh.starttime
    },
    {
        field: "originalEndTime",
        headerName: lang.lotrinh.endTime    
    },
    {
        field: "originalSokm",
        headerName: lang.lotrinh.endTime    
    },
    {
        field: "startTime",
        headerName: lang.lotrinh.starttime
    },
    {
        field: "endTime",
        headerName: lang.lotrinh.endTime    
    },
    {
        field: "updatedSokm",
        headerName: lang.lotrinh.endTime    
    },
    {
        field: "Status",
        headerName: lang.lotrinh.xacnhan
    },
    {
        field: "rejectedNote",
        headerName: lang.lotrinh.tangca
    },
    {
        field: "Thoigiantca",
        headerName: lang.lotrinh.tangca,
        sumValue: data.sumOverTimes
    },
    {
        field: "sokm",
        headerName: lang.lotrinh.quangduong,
        sumValue: data.sumKm
    },
    {
        field: "sokmvuot",
        headerName: lang.lotrinh.sokmvuot,
        sumValue: data.sumOverKm
    },
    {
        field: "Ghichu",
        headerName: lang.lotrinh.ghichu,
        editIcon: userLogger.type === UserTypeEnum.ADMINISTRATOR ? <Create/> : ""
    }]

    const addEmptyDays = (lastDate, iDay, date, arr, leaves) => {
        for(let d = lastDate + 1; d <iDay; d++ ) {
            const dN = new Date();
            dN.setFullYear(date.getFullYear(),date.getMonth(),d);
            const leave = HasLeaving(leaves, dN)
            arr.push({
                id: d,
                Ngay: dN.toLocaleString("vi-vn",{
                    day: "2-digit",
                    month:'2-digit',
                    year: 'numeric'
                }),
                weekday: format(dN, "EEE"),
                hasLeave: !isNil(leave),
                Ghichu: leave?.leaveNote
            })
        }
        return arr;
    }

    const HasLeaving = (leavedays, date) => {
        const hasLeave = leavedays.find(l => new Date(`${l.fromDate}Z`) <= date && new Date(`${l.toDate}Z`) >= date);
        return hasLeave;
    }
    
    useEffect(async() => {
        const sv1 =new LotrinhService();
        if(sv1.banglotrinhchitiet) {
            const r = await sv1.banglotrinhchitiet(selectedNV.nhanvien, req.fromDate, req.toDate, req.statuses.join(","));

            if(r.error) {
                return;
            }
            const leavedays = r.additional || [];
            
            let lastDate = 0;
            const m = new Date(req.toDate);
            const month = parseInt(m.toLocaleString("vi-vn",{
                month:'2-digit'
            }))
            const daysInMonth = new Date(m.getFullYear(),month,0).getDate();
            let sumOverTimes = 0;
            r.sumKm = 0;
            r.sumOverKm = 0;
            let newResult = r.result.reduce((acc,i) =>{
                const ngay = new Date(`${i.ngay}Z`);
                const iDay = ngay.getDate();
                if(iDay > lastDate) {
                    acc = addEmptyDays(lastDate,iDay,ngay,acc, leavedays);
                    lastDate = iDay;
                }
                const h = parseInt(i.thoigianinMinutes/60);
                const m = i.thoigianinMinutes - parseInt(i.thoigianinMinutes/60)*60;

                const h1 = parseInt(i.overtimeinMinutes/60);
                const m1 = i.overtimeinMinutes - parseInt(i.overtimeinMinutes/60)*60;
                sumOverTimes += i.overtimeinMinutes;
                r.sumKm += i.sokm;
                r.sumOverKm += i.sokmvuot;
                const leave = HasLeaving(leavedays, ngay);

                acc.push(Object.assign(i,{
                    id: iDay,
                    Ngay: ngay.toLocaleString("vi-vn",{
                        day: "2-digit",
                        month:'2-digit',
                        year: 'numeric'
                    }),
                    StartTime: i.startTime,
                    EndTime: i.endTime,
                    OriginalEndTime: i.originalEndTime,
                    OriginalStartTime: i.originalStartTime,
                    originalStartTime: ngay.toLocaleString("vi-vn",{
                        hour: "2-digit",
                        minute:'2-digit',
                        hour12:false
                    }),
                    originalEndTime: i.originalEndTime ? new Date(`${i.originalEndTime}Z`).toLocaleString("vi-vn",{
                        hour: "2-digit",
                        minute:'2-digit',
                        hour12:false
                    }) : "",
                    startTime: i.ngay == i.startTime ? "" : new Date(`${i.startTime}Z`).toLocaleString("vi-vn",{
                        hour: "2-digit",
                        minute:'2-digit',
                        hour12:false
                    }),
                    endTime: i.endTime && i.endTime != i.originalEndTime ? new Date(`${i.endTime}Z`).toLocaleString("vi-vn",{
                        hour: "2-digit",
                        minute:'2-digit',
                        hour12:false
                    }) : "",
                    updatedSokm: i.updatedSokm == i.originalSokm ? "" : i.updatedSokm,
                    Thoigian: `${h<10 ? "0": ""}${h}:${m<10 ? "0":""}${m}`,
                    Thoigiantca: `${h1<10 ? "0": ""}${h1}:${m1<10 ? "0":""}${m1}`,
                    Color: i.Status === 3 ? red[500] : "",
                    Ghichu: `${i.Ghichu || ""} ${i.RejectedNote || ""}`,
                    Status: i.status === 3 ? <ClearIcon style={{
                        width:15,
                        height:15
                    }}/> : i.status === 2 ? <CheckIcon style={{
                        width:15,
                        height:15
                    }}/> : "",
                    weekday: format(ngay, "EEE"),
                    hasLeave: !isNil(leave)
                }));
                return acc;
            },[])

            if(lastDate < daysInMonth) {
                newResult = addEmptyDays(lastDate,daysInMonth + 1,m,newResult, leavedays);
            }
            const sh1 = parseInt(sumOverTimes/60);
            const sm1 = sumOverTimes - parseInt(sumOverTimes/60)*60;
            r.sumOverTimes = `${sh1<10 ? "0": ""}${sh1}:${sm1<10 ? "0":""}${sm1}`
            r.songaythucte = r.result.length;
            r.result = newResult; 
            r.sumKm = r.sumKm.toLocaleString();

            setData(r);
        }
    },[refresh])

    useEffect(async() =>{
        if(!exportDF){
            return;
        }
        setExportPDF(false);
        const sv = new LotrinhService();
        const todate = new Date(req.toDate);
        const month = todate.getMonth() + 1;
        const year = todate.getFullYear();
        await sv.exportPDF_Banglotrinhchitiet(selectedNV.nhanvien, req.fromDate, req.toDate, req.statuses.join(","), `${data.summaryData.nhom}_T${month}.${year}_${data.summaryData.name}`)
        

    },[exportDF])

    useEffect(async () => {
        if(!exportExcel) {
          return;
        }
        setExportExcel(false);
        const sv = new LotrinhService();
        const todate = new Date(req.toDate);
        const month = todate.getMonth() + 1;
        const year = todate.getFullYear();
        await sv.exportExcel_Banglotrinhchitiet(selectedNV.nhanvien, req.fromDate, req.toDate, req.statuses.join(","), `${data.summaryData.nhom}_T${month}.${year}_${data.summaryData.name}`);
      }, [exportExcel])

    useEffect(async() => {
        const days = data.result.filter(d => d.selected === true && !d.lotrinhId).map(d => d.id);
        if(days.length <= 0) {
            return;
        }

        const sv1 = new LotrinhService();
        const todate = new Date(req.toDate);
        const month = todate.getMonth() + 1;
        const year = todate.getFullYear();
        await sv1.generateData({
            saleId: selectedNV.nhanvien,
            thang: month,
            nam: year,
            ngays: days.join(",")
        });
        setRefresh(!refresh);
    }, [generateData]);

    return <Box 
        className="main-content" 
        
    >
        
        <div  className="page-header">
            <Button variant="text" className="btn-text" onClick={evt => goback()}><KeyboardBackspaceIcon/>Go back</Button>
        <div className="page-title"><span>{lang.banglotrinhchitiet.title}</span></div>
        <div>
        <IconButton onClick={evt => setExportExcel(true)} style={{
            color: lightGreen[600],
            backgroundColor: lightGreen[100],
            marginRight:10
        }}
        title={lang.lotrinh.exportExcel}
        >
            <SimCardDownload/>
        </IconButton>
        <IconButton onClick={evt => setExportPDF(true)} style={{
            color: orange[600],
            backgroundColor: orange[100]
        }}
        title={lang.lotrinh.exportlotrinhThang}
        >
            <SimCardDownload/>
        </IconButton>
        {userLogger.type === UserTypeEnum.ADMINISTRATOR && enabledGenerateData && <IconButton onClick={evt => setGenerateData(!generateData)} style={ {
            color: purple[600],
            backgroundColor: purple[100],
            marginLeft:10
        }}>
            <Create/>   
        </IconButton>}
        </div>
        

        </div>
        <div className="flex-column align-left" style={{
            paddingBottom:30,
            lineHeight:2
        }}>
            <div className="flex-row"><span className="lb-m">{lang.banglotrinhchitiet.nhanvien}:</span><b>{data.summaryData.name}</b></div>
            <div className="flex-row"><span className="lb-m">{lang.StaffList.SDT}:</span><span>{data.summaryData.phoneNumber}</span></div>
            <div className="flex-nowrap" style={{
                alignItems: "flex-start"
            }}>
                <div className="flex-row" style={{
                    flexWrap: "nowrap"
                }}><span className="lb-m" >{lang.StaffList.Diachi}:</span><span style={{
                    width: "calc(100% - 180px)",
                    textAlign: "left"
                }}>{data.summaryData.address}</span></div>
                <div className="flex-row"><span className="lb-m">{lang.banglotrinhchitiet.songayduadon}:</span><span><b>{data.summaryData.workingday}({lang.lotrinh.ngay})</b></span></div>
            </div>
            
            <div className="flex-nowrap" >
            <div className="flex-row"><span className="lb-m">{lang.banglotrinhchitiet.nhom}:</span><b>{data.summaryData.nhom}</b></div>
        <div className="flex-row"><span className="lb-m">{lang.sale_working_plan.khunggio}:</span><b>{data.summaryData.khunggio}</b></div>
            </div>
            <div className="flex-nowrap">
            <div className="flex-row"><span className="lb-m">{lang.banglotrinhchitiet.taixe}:</span><b>{data.summaryData.taixe}</b></div>
            <div className="flex-row"><span className="lb-m">{lang.banglotrinhchitiet.sokmtrongngay}:</span><b>{data.summaryData.sokmQuydinh}(km)</b></div>
            </div>
        </div>
        <DataTable
        tableCellStyle={{
            paddingTop:3,
            paddingBottom:3
        }}
        rowHeaders ={[
            [{headerName: "Stt", rowSpan: 2, width: 60}
            ,{headerName:lang.lotrinh.ngay, rowSpan: 2, width: 100}
            ,{headerName:lang.lotrinh.dataApp, colSpan: 3}
            ,{headerName:lang.lotrinh.dieuchinhData, colSpan: 3}
            ,{headerName:lang.lotrinh.xacnhanlotrinh, colSpan:2},
            ,{headerName: lang.lotrinh.tonghopdata, colSpan:3}
            ,{headerName: lang.lotrinh.ghichu, rowSpan:2}
            ]
            ,[{headerName:lang.lotrinh.starttime, width:85}
            ,{headerName:lang.lotrinh.endTime, width:85}
            ,{headerName:lang.lotrinh.quangduong, width:85}
            ,{headerName:lang.lotrinh.starttime, width:85}
            ,{headerName:lang.lotrinh.endTime, width:85}
            ,{headerName:lang.lotrinh.quangduong, width:85}
            ,{headerName:lang.lotrinh.statusText, width:100}
            ,{headerName:lang.lotrinh.lydotuchoi, width:250}
            ,{headerName:lang.lotrinh.sogiotca, width:85}
            ,{headerName:lang.lotrinh.quangduong, width:85}
            ,{headerName:lang.lotrinh.sokmvuot, width:85}
            ]
          ]}
        size="small"
        rows = {data.result}
        columns ={columns}
        totalCount = {data.result.length}
        pageSize={data.result.length}
        summaryRows={[columns]}
        onRowClick = {dataRow => {
            if(dataRow.row?.lotrinhId) {
                setSelectedLotrinh(dataRow.row);
                setUpdateLotrinh(true);
            }
            else if(userLogger.type === UserTypeEnum.ADMINISTRATOR) {
                dataRow.row.selected  = !dataRow.row.selected;
                const seletedDays = data.result.filter(d => d.selected === true).map(d => d.id);
                setEnabledGenerateData(seletedDays.length > 0);
            }

        }}
        />
        {userLogger.type === UserTypeEnum.ADMINISTRATOR && selectedLotrinh && updateLotrinh && <UpdateLotrinh lotrinh={selectedLotrinh} open = {updateLotrinh}  setUpdateLotrinh = {setUpdateLotrinh} setRefresh = { () => setRefresh(!refresh)} saleId= {selectedNV.nhanvien} />}
    </Box>
}