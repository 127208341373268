import React, { useEffect, useState } from "react"
import Dialog from "../../../Components/ConfirmDialog"
import lang from "../../../asset/Language.json"
import KhuVucService from "../../../services/KhuVucService";

export default function DeleteSubKhuvuc(props){
    const {open, setClose, data, setError, row, setRow} = props;
    const [xoaSubKhuvuc, setXoaSubKhuvuc] = useState(false)

    useEffect(async() =>{
        if(!xoaSubKhuvuc) {
            return;
        }
        setXoaSubKhuvuc(false);
        const sv = new KhuVucService();
        const r = await sv.deleteSubKhuvuc(data.Id);
        if(r.error || !r){
            setError(true)
            return
        }
        setRow(Object.assign(row,{
            Sub_Khuvuc: row.Sub_Khuvuc.filter(d => d.Id != data.Id)
        }));
        setClose({});
    },[xoaSubKhuvuc])

    return <Dialog
    open={open}
    cancelText={lang.buttons.boqua}
    okText={lang.buttons.co}
    onClose={evt => setClose({})}
    msgContent={lang.lotrinh.xoasubkhuvucconfirm.replace("{0}",data.Ten)}
    onYes = {evt => setXoaSubKhuvuc(true)}
    />
}