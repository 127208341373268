import { Refresh } from "@mui/icons-material"
import { Box, Button } from "@mui/material"
import React, { useEffect, useState } from "react"
import lang from "../../asset/Language.json"
import LotrinhService from "../../services/LotrinhService"
import LotrinhChitiet from "./LotrinhChitiet"
import LotrinhToday from "./LotrinhToday"
import NoSleep from "nosleep.js"

export default function StartLotrinh(){
    const[startLotrinh, setStartLotrinh] = useState()
    const [lotrinhToday,setLotrinhToday] = React.useState([]);
    const [shouldStart, setShouldStart] = React.useState();
    const [location, setLocation] = useState();
    const [refresh, setRefresh] = useState();

    const getLocation = (position) => {
        if(position.coords) {
            setLocation(`${position.coords.latitude},${position.coords.longitude}`);
        }
        else {
            setLocation("");
        }
        setStartLotrinh(true);
    }

    useEffect(async()=> {
        const sv = new LotrinhService();
        const r = await sv.lotrinhToday();
        if(r.error) {
          return;
        }
        if(!r || r.length == 0) {
          setShouldStart(true);
          setLotrinhToday([]);
          return;
        }
        setLotrinhToday(r);
    },[startLotrinh, refresh]);

    useEffect(async() =>{
        if(!startLotrinh){
            return;
        }
        const sv = new LotrinhService();
        const r = await sv.checkin({
            location: location
        });
        if(r.error){
            return;
        }
        
        setShouldStart(false);
        setLotrinhToday(r);
        const nosleep = new NoSleep()
        nosleep.enable()
    },[startLotrinh])

    const view = () => {
        if(lotrinhToday.length > 0 && lotrinhToday[0].endTime)
            return <LotrinhChitiet lotrinh={lotrinhToday[0]}/>;
        if(lotrinhToday.length > 0)
            return <LotrinhToday lotrinh={lotrinhToday[0]} startLotrinh={evt => setRefresh(!refresh)}/>;
        if(shouldStart)
            return <Box sx={{
                height:"calc(100% - 60px)",
                display:'flex',
                alignItems:"center",
                justifyContent: "center"
                }}>
                <Button variant="contained" sx={{
                    color:"#FFF",
                    borderRadius:15,
                    width: "80%",
                    height:40
                }} 
                onClick={evt => {
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition(getLocation,getLocation);
                      } 
                }}
                >{lang.generaltext.startlotrinh}</Button>
            </Box>

        return <Box></Box>
    }
    return view();
}