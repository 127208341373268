import { Box, Button, InputAdornment, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import lang from "../../asset/Language.json"
import LotrinhService from "../../services/LotrinhService";
import ConfirmDialog from "../../Components/ConfirmDialog";
import LotrinhTimeLine from "../../Components/LotrinhTimeLine"
import MobileTimePicker from '@mui/lab/MobileTimePicker';
import { Thoigian, getStatus } from "../../Utils/util";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import NoSleep from "nosleep.js"

function calculdateDistance(point1, point2) {
    var lat1 = point1.latitude;
    var radianLat1 = lat1 * (Math.PI / 180);

    var lng1 = point1.longitude;
    var radianLng1 = lng1 * (Math.PI / 180);

    var lat2 = point2.latitude;
    var radianLat2 = lat2 * (Math.PI / 180);

    var lng2 = point2.longitude;
    var radianLng2 = lng2 * (Math.PI / 180);

    var earth_radius = 3959; // or 6371 for kilometers

    var diffLat = (radianLat1 - radianLat2);
    var diffLng = (radianLng1 - radianLng2);

    var sinLat = Math.sin(diffLat / 2);
    var sinLng = Math.sin(diffLng / 2);

    var a = Math.pow(sinLat, 2.0) + Math.cos(radianLat1) * Math.cos(radianLat2) * Math.pow(sinLng, 2.0);

    var distance = earth_radius * 2 * Math.asin(Math.min(1, Math.sqrt(a)));

    return parseInt(distance.toFixed(3)*1000);
}


export default function LotrinhToday(props){
    const {goback, startLotrinh,lotrinh } = props
    const soKm = parseFloat((lotrinh.distance*0.001).toFixed(3));

    const [ketthucLotrinhConfirm, setKetthucLotrinhConfirm] = useState();
    const [ketthucLotrinh, setKetthucLotrinh] = useState();
    const [note, setNote] = useState();
    const [distance, setDistance] = useState(soKm);
    const [endTime, setEndTime] = useState();
    const [watchId, setWatchId] = useState();
    const [invalidEndTime, setInvalidEndTime] = useState()
    const [watchlocation, setWatchlocation] = useState()
    const [wInfo, setWInfo] = useState()

    const locationRef = useRef()
    const distanceRef = useRef(0);
    const lotrinhRef = useRef()
    const userRef = useRef()
    

    const resetvalue = () => {
        setEndTime();
        setDistance(lotrinhRef.current.distance);
        setNote(lotrinhRef.current.note || "")
        setKetthucLotrinhConfirm(false);
        setWatchlocation(!watchlocation);
    }

    const storePreviousValueAndShowoup = () => {
        lotrinhRef.current = {
            endTime : endTime,
            distance : distance,
            note: note
        }
        setKetthucLotrinhConfirm(true);
    }


    const watchPosition = (position) => {
        if(position.coords)
        {
           const sv = new LotrinhService();
           userRef.current = sv.api.userLoggedIn.usrName;
           if(!position.coords.speed || position.coords.speed <= 0){
                locationRef.current = position.coords;
                return;
            }
            
            const d =  calculdateDistance(locationRef.current, position.coords);
            distanceRef.current += d;
            
            if(distanceRef.current >= 200) {
                setWInfo({
                    distance: parseInt(distanceRef.current),
                    coords: position.coords
                });
            }
        }
    }

    useEffect(() => {
        if(!wInfo) {
            return;
        }
        const sv = new LotrinhService();
        sv.checkin({
            location: `${wInfo.coords.latitude},${wInfo.coords.longitude}`,
            lotrinhId: lotrinh.id,
            distance: wInfo.distance
        });
        const newD = parseFloat(((wInfo.distance*0.001) + distance*1).toFixed(3));
        locationRef.current = wInfo.coords;
        distanceRef.current = 0;
        setDistance(newD);
    },[wInfo])

    useEffect(() => {
        const sv = new LotrinhService();
        userRef.current = sv.api.userLoggedIn.usrName;
        
        const ngay = new Date(`${lotrinh.ngay}Z`)
        
        if(!ngay || ngay.getDate() != new Date().getDate()){
            return;
        }
        if(endTime || lotrinh.endTime) {
            return;
        }
        const nosleep = new NoSleep()
        nosleep.enable()
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position)=> {
                locationRef.current = position.coords;
            });

            const watchId = navigator.geolocation.watchPosition(watchPosition, () => {}, {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
            });
            setWatchId(watchId);
        } 
        
       
    },[watchlocation])
    

    const handleKetthucLotrinh = () => {
        if (navigator.geolocation && watchId){
            navigator.geolocation.clearWatch(watchId);
        }
        setKetthucLotrinhConfirm(false);
        setKetthucLotrinh(true);
    }
    
    useEffect(async() =>{
        if(!ketthucLotrinh){
            return;
        }
        const sv = new LotrinhService();
        const startTime = new Date(`${lotrinh.startTime}Z`)
        let endTime1 = endTime || new Date();
        if(startTime.getDate() < endTime1.getDate()) {
            endTime1 = new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate(),23,59,0);
        }
        const r = await sv.checkin({
            location: locationRef.current ? `${locationRef.current.latitude},${locationRef.current.longitude}` : "",
            lotrinhId: lotrinh.id,
            status: 1,
            distance: parseInt(distance*1000),
            note: note || "",
            checkinTime: (endTime1 || new Date()).toISOString()
        });
        if(r.error){
            return;
        }
        
        if(startLotrinh){
            startLotrinh()
        }
        else if(goback) {
            goback()
        }
        
    },[ketthucLotrinh])

    const formKetThucLotrinh = () =>{
        const startTime = new Date(`${lotrinh.startTime}Z`);
        let endTime1 = endTime || new Date();
        if(startTime.getDate() < endTime1.getDate()) {
            endTime1 = new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate(),23,59,0);
        }
        
        distanceRef.current = distance;

        return <div>
        <Stack direction="column" spacing ={2}>
                <b>{lang.lotrinh.ketthuclotrinhConfirm}</b>
                <Stack direction ="row" spacing={2}>
                <MobileTimePicker
                label={lang.lotrinh.thoigianbd}
                value={startTime}
                ampm={false}
                disabled
                renderInput={(params) => <TextField {...params} />}
                />
                <MobileTimePicker
                label={lang.lotrinh.thoigiankt}
                value={endTime1}
                ampm={false}
               minTime={new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate(),startTime.getHours(),startTime.getMinutes(),startTime.getSeconds())}
                maxTime={new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate(),23,59,59)}
                onError={evt => setInvalidEndTime(true)}
                onAccept={evt => setInvalidEndTime(false)}
                onChange={(newValue) => {
                    setEndTime(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
                />
                </Stack>
                <TextField
                    label ={lang.lotrinh.quangduong}
                    value = {parseFloat((distance*1).toFixed(3))}
                    onInput= {evt => setDistance(evt.target.value*1)}
                    type="number"
                    InputProps={{
                        endAdornment: <InputAdornment position="end">km</InputAdornment>
                    }}
                    min={1}
                    max={200}
                    error= {distance <= 0 || distance > 200}
                />
        </Stack>
    </div>}
    return <Box sx={{
        justifyContent: "flex-start",
        maxWidth:360,
        margin:"0 auto",
        textAlign: "left",
        width:"100%",
        padding: goback ? "0 15px" : "15px"
    }}>
        {goback && <Button sx={{
            padding: "15px 0"
        }} variant="text" className="btn-text" onClick={evt => {
            if (navigator.geolocation && watchId){
                navigator.geolocation.clearWatch(watchId);
            }
            goback()
            }}><KeyboardBackspaceIcon/>{lang.generaltext.back}</Button>}
        {<Stack
        direction={{ xs: 'column', sm: 'column' }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
        sx={{textAlign: "left"}}
        >
        <div>
            <span>{ lang.lotrinh.trangthai}:&nbsp;</span>
            <b>{getStatus(lotrinh.status)}</b>
        </div>
        <div>
            <span>{ lang.lotrinh.tongthoigian}:&nbsp;</span>
            <b>{lotrinh.endTime ? Thoigian(`${lotrinh.startTime}Z`,`${lotrinh.endTime}Z`) : ""}</b>
            </div>
        <div>
            <span>{ lang.lotrinh.quangduong}:</span>
            <b>{` ${(distance*1).toLocaleString()} (km)`}</b>
        </div>
        
        </Stack>}
        {LotrinhTimeLine(lotrinh.endTime ? [{
            time: new Date(`${lotrinh.startTime}Z`),
            location: lotrinh.startLocation
        },{
            time: new Date(`${lotrinh.endTime}Z`),
            location: lotrinh.endLocation
        }] : [{
            time: new Date(`${lotrinh.startTime}Z`),
            location: lotrinh.startLocation
        }])}
        {!lotrinh.endTime && <Stack direction="row" position="fixed" bottom={0} left={0} width={"100%"}>
            <Button variant="contained" sx={{flex:1, borderRadius: 0, color:"#FFF", height:45}}
            onClick={evt => storePreviousValueAndShowoup()}
        >{lang.generaltext.ketthucLotrinh}</Button>
              <ConfirmDialog
            cancelText={lang.buttons.boqua}
            okText = {lang.buttons.xacnhan}
            open={ketthucLotrinhConfirm}
            msgContent={formKetThucLotrinh()}
            onClose={evt => resetvalue()}
            onYes = {handleKetthucLotrinh}
            disabledYes={!distance || distance <= 0 || distance > 200 || invalidEndTime }
            />
            
        </Stack>}
       
        {wInfo && wInfo.coords && userRef && userRef.current == "thuytest123" && <Stack>
            <Typography>Latitude:{wInfo.coords.latitude}</Typography>
            <Typography>Longitude:{wInfo.coords.longitude}</Typography>
        <Typography>Speed:{wInfo.coords.speed}</Typography>
        </Stack>}
    </Box>
}