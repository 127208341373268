import ApiService from "./ApiService";
import { KhuVucApi} from "../Contants/UrlContant";

class KhuVucService {
    constructor(){
        this.api = new ApiService();
    }

   
    async get(id, fromDate, toDate) {
        return await this.api.get(`${KhuVucApi.get}${id}/?fromDate=${fromDate}&toDate=${toDate}`);
    }

    async getAll() {
        return await this.api.get(`${KhuVucApi.getAll}`);
    }

    async update(data) {
        return await this.api.post(`${KhuVucApi.update}`, data);
    }

    async setKhuvucAdmin(data) {
        return await this.api.post(`${KhuVucApi.setKhuvucAdmin}`, data)
    }
    async add(data) {
        return await this.api.post(`${KhuVucApi.addNew}`, data);
    }

    async delete(id) {
        return await this.api.post(`${KhuVucApi.delete}${id}`);
    }

    async khuVucMaster(){
        return await this.api.get(`${KhuVucApi.khuvucMaster}`);
    }

    async updateDongia(data) {
        return await this.api.post(`${KhuVucApi.updateDongia}`, data);
    }

    async addDongia(data) {
        return await this.api.post(`${KhuVucApi.addDongia}`, data);
    }

    async deleteDongia(id) {
        return await this.api.post(`${KhuVucApi.deleteDongia}?id=${id}`);
    }

    async updateSubKhuvuc(data) {
        return await this.api.post(`${KhuVucApi.updateSubKhuvuc}`, data);
    }

    async addSubKhuvuc(data) {
        return await this.api.post(`${KhuVucApi.addSubKhuvuc}`, data);
    }

    async deleteSubKhuvuc(id) {
        return await this.api.post(`${KhuVucApi.deleteSubKhuvuc}?id=${id}`);
    }

    async getAllSubKhuvucByKhuvuc(khuvuc){
        return await this.api.get(`${KhuVucApi.getAllSubKhuvuc}?khuvuc=${khuvuc}`)
    }
}

export default KhuVucService;