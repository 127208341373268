import axios from "axios";
import jwt from "jwt-decode";
import { UserTypeEnum } from "../Contants/UserTypeEnum";
const httpClient = axios.create();
httpClient.defaults.timeout = 300000;

class ApiService {
    constructor(){

    }

    injectHeader() {
        //get token from the cookie
        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8"
        };
        const token = this.getToken;
        if(token && token != "undefined") {
            headers.Authorization = `Bearer ${token}`;
        }
        return headers;
    }
    get getToken(){
        const token = sessionStorage.getItem("omoto.Token");
        return token;
    }
    
    saveToken(token) {
        sessionStorage.setItem("omoto.Token", token);
    }

    get userLoggedIn() {
        const token = this.getToken;
        const data = jwt(token);
        return Object.assign({}, data,{
            data : JSON.parse(data.data)
        });
    }

    get isAdmin(){
        const userLoggedIn = this.userLoggedIn;
        return userLoggedIn.type === UserTypeEnum.ADMINISTRATOR
    }

    async get(url, data) {
        return await httpClient.get(url, {
            headers: this.injectHeader(),
            params: data
        })
        .then(res => {
            return res.data;
        })
        .catch(e => {
            return {
                error: true,
                errorMsg: e.response && e.response.data ? e.response.data : e
            };
        });
    }

    async post(url, data) {
        return await httpClient.post(url, data,{
            headers: this.injectHeader()
        })
        .then(res => {
            return res.data;
        })
        .catch(e => {
            return {
                error: true,
                errorMsg: e.response && e.response.data ? e.response.data : e
            };
        });
    }

    async upload (url, data, callback) {
        const headers = this.injectHeader();
        return await axios.post(url, data, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${this.getToken}`
            },
            onUploadProgress: callback,
            timeout: 150000
        }).then(result => {
            return result.data;
        })
        .catch(e => {
            return {
                error: true,
                errorMsg: e.response && e.response.data ? e.response.data : e
            };
        });
    }

    async exportData(url, data, fileName) {
       return axios({
            url: url, 
            method: 'POST',
            responseType: 'blob', // important
            headers: this.injectHeader(),
            data: data,
            timeout: 150000
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${fileName}`); //or any other extension
            document.body.appendChild(link);
            link.click();
            return {
                data: true
            };
        })
        .catch(e => {
            return {
                error: true,
                errorMsg: e.response && e.response.data ? e.response.data : e
            };
        });
        

    }
    
    async put(url, data) {
        const config = this.injectHeader();
        return await axios.put(url,data, config).then(res => {
            return res.data;
        })
        .catch(e => {
            return {
                error: true,
                errorMsg: e.response && e.response.data ? e.response.data : e
            };
        });;
    }

    async delete(url, data) {
        return await axios.delete(url,{
            headers: this.injectHeader
        }).then(res => {
            return res.data;
        })
        .catch(e => {
            return {
                error: true,
                errorMsg: e.response && e.response.data ? e.response.data : e
            };
        });;
    }
}

export default ApiService;