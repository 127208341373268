import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react"
import SearchDropDown from "../../../Components/SearchDropDown";
import lang from "../../../asset/Language.json"
import Dialog from "../../../Components/ConfirmDialog"
import KhuVucService from "../../../services/KhuVucService";

export default function SubKhuvuc(props){
    const {open, setClose,row, setRow, data, dsKhuvuc, setError} = props;
    const [ten, setTen] = useState(data.Ten)
    const [subKhuvucChanged, setSubKhuvucChanged] = useState(false)
    const [khuvuc, setKhuvuc] = useState(data.Khuvuc)


    useEffect(async() =>{
        if(!subKhuvucChanged){
            return;
        }
        setSubKhuvucChanged(false)
        const sv = new KhuVucService();
        const reqData = {
            Id: data.Id,
            Ten: ten,
            Khuvuc: khuvuc
        }
        const r = data.Id ? await sv.updateSubKhuvuc(reqData) : await sv.addSubKhuvuc(reqData)
        if(r.error) {
            setError(true);
            return;
        }
        const updatedR = Object.assign(row, {
            Sub_Khuvuc: (data.Id ? row.Sub_Khuvuc.filter(d => d.Id!=data.Id) : row.Sub_Khuvuc).concat([{
                Id: r.id,
                Khuvuc:r.khuvuc,
                Ten: r.ten
            }])
        })
        setRow(updatedR)
        setClose({})
    },[subKhuvucChanged])

    const content = <div>
        <SearchDropDown
            id="dialog_sub_khuvuc_khuvuc"
            className ="inp-fld"
            style={{
                marginRight:35,
                width:250
            }}
            label={lang.lotrinh.khuvuc} 
            disabled = {data.Khuvuc}
            type="number"
            value={khuvuc || ' '}
            options = {(dsKhuvuc).map((option) => ({
            key: option.Id,
            text: `${option.Ten}`
            }))}
            onChange= {value => setKhuvuc(value)} 
            />
        <TextField
        id="dialog_sub_khuvuc_ten_subkv"
        label={lang.generaltext["sub-khuvuc"]}
        value={ten || ' '}
        variant="standard"
        onInput={evt => setTen(evt.target.value)}
        />
    </div>

    return <Dialog
    className="dialog-a-visible"
    title={data.Id ? lang.generaltext.capnhatSubkhuvuc : lang.generaltext.addSubkhuvuc}
    open={open}
    msgContent={content}
    okText={lang.buttons.luu}
    cancelText={lang.buttons.boqua}
    onClose={evt => setClose({})}
    onYes={evt => setSubKhuvucChanged(true)}
    />
}