import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { isString } from 'lodash';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function DraggableDialog(props) {
  const {open, onClose, title, msgContent, onYes, okText, cancelText, disabledYes, fullScreen, maxWidth, className, extraButton, extraText, onExtra} = props;
  return (
    <div>
      <Dialog
        className={className || ""}
        open={open}
        fullWidth={fullScreen}
        scroll={'paper'}
        PaperComponent={PaperComponent}
        aria-labelledby=""
        
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{color: "#333"}}>
            {isString(msgContent) ? <span>{msgContent}</span> : msgContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={onClose} color="secondary" variant="outlined">
            {cancelText || "CANCEL"}
          </Button>
          {extraButton && extraText && <Button onClick={onExtra} color="secondary" variant="outlined">{extraText}</Button>}
          <Button disabled={disabledYes} onClick={onYes} color="secondary" variant="outlined">{okText || "OK"}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
