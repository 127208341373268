import React, { useEffect, useState } from "react"
import Dialog from "../../../Components/ConfirmDialog"
import lang from "../../../asset/Language.json"
import KhuVucService from "../../../services/KhuVucService";

export default function DeleteDongiaBuoi(props){
    const {open, setClose, dgia, setError, row, setRow} = props;
    const [xoaDongia, setXoaDongia] = useState(false)

    useEffect(async() =>{
        if(!xoaDongia) {
            return;
        }
        setXoaDongia(false);
        const sv = new KhuVucService();
        const r = await sv.deleteDongia(dgia.Id);
        if(r.error || !r){
            setError(true)
            return
        }
        setRow(Object.assign(row,{
            Dongiabuoi: row.Dongiabuoi.filter(d => d.Id != dgia.Id)
        }));
        setClose({});
    },[xoaDongia])

    return <Dialog
    open={open}
    cancelText={lang.buttons.boqua}
    okText={lang.buttons.co}
    onClose={evt => setClose({})}
    msgContent={lang.lotrinh.xoadongiaconfirm.replace("{0}",`${dgia.khunggioName}|${dgia.sobuoi1}-${dgia.sobuoi2}`)}
    onYes = {evt => setXoaDongia(true)}
    />
}