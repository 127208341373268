import React, { useEffect, useRef, useState } from "react"
import DataTable from "../../../Components/DataTable";
import lang from "../../../asset/Language.json"
import UserService from "../../../services/UserService";
import ReadMoreIcon from "@mui/icons-material/ReadMore"
import RefreshIcon from '@mui/icons-material/RefreshOutlined';

import LotrinhDetail from "./Working_Plan_Detail";
import { Button, IconButton } from "@mui/material";
import SearchFilter from "../../../Components/SearchLotrinhBar/searchFilter";
import { SimCardDownload } from "@mui/icons-material";
import { lightGreen } from "@mui/material/colors";

export default function Sale_Working_Plan(props){
    const {cusId, dsProvince} = props;
    const [refresh, setRefresh] = useState();
    const [error, setError] = useState();
    const [rows, setRows] = useState([]);
    const [openPlanDetail, setOpenPlanDetail] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [exportExcel, setExportExcel] = useState()

    const columns =[{
        field: "stt",
        headerName: lang.sale_working_plan.stt,
        width: 30
    },{
        field: "saleName",
        headerName: lang.sale_working_plan.hoten,
        width: 200
    },
    {
        field: "khuvucName",
        headerName: lang.sale_working_plan.khuvuc,
        width: 150
    },
    {
        field: "workingTime",
        headerName: lang.sale_working_plan.khunggio,
        width: 100
    },
    {
        field: "workingday",
        headerName: lang.sale_working_plan.songaydk,
        width: 80
    },
    {
        field: "taixeName",
        headerName: lang.sale_working_plan.taixe
    },
    {
        field: "workingPlace",
        headerName: lang.sale_working_plan.workingplace
    },
    {
        field: "reportEmail",
        headerName: lang.sale_working_plan.reportemail
    }];

    const shouldChangePlan = useRef(false);
    const searchCond = useRef()
    const kvmName = useRef()

    const onFilterChange = async (fromDate1, toDate1, pageIndex, selectedKVM, selectedStatuses, searchStr, selectedSubKV) => {
        const sv = new UserService();
        const r = await sv.getWorkingPlan(fromDate1, toDate1, selectedKVM ? selectedKVM.id : 0, searchStr || "", selectedSubKV && selectedSubKV.length > 0 ? selectedSubKV.map(k => k.id) : null);
        if(r.error) {
            setError(true);
            return;
        }

        kvmName.current = selectedKVM ? selectedSubKV && selectedSubKV.length > 0 ? `${selectedKVM.ten}(${selectedSubKV.map(k => k.ten) || ""})` : selectedKVM.ten : "";
        searchCond.current = {
          fromDate: fromDate1,
          toDate: toDate1,
          kvm: selectedKVM ? selectedKVM.id : 0,
          subKV: selectedSubKV && selectedSubKV.length > 0 ? selectedSubKV.map(k => k.id) : undefined,
          khuvucName: kvmName.current || ""
        }

        setRows(r.map((e,idx) => Object.assign(e, {
            id: e.id || e.Id,
            stt: idx + 1
        })));
        shouldChangePlan.current = new Date(fromDate1).toLocaleString("vi-vn", {
            year: 'numeric',
            month: '2-digit'
        }) == new Date().toLocaleString("vi-vn", {
            year: 'numeric',
            month: '2-digit'
        })
    }

    useEffect(async () => {
        if(!exportExcel) {
          return;
        }
        const sv = new UserService();
        await sv.exportExcel_sale_working_plan(searchCond.current);
        setExportExcel();
      }, exportExcel)
    
    return <div className="main-content" >
        
        <div className="page-header" >
        <div className="page-title"><span>{lang.sale_working_plan.title}</span></div>
        <IconButton
            onClick={evt => setExportExcel(true)} style={{
            color: lightGreen[600],
            backgroundColor: lightGreen[100],
            marginRight:10
        }}
        title={lang.lotrinh.exportExcel}
        >
            <SimCardDownload/>
        </IconButton>
        <Button key="refresh-working-plan"  className="btn-text" title={lang.StaffList.refreshNhanvien} variant="text" onClick={evt => setRefresh(!refresh)}>
            <RefreshIcon/> 
            Refresh
        </Button>
        </div> 
        <SearchFilter cusId={cusId} disabledSearchText = {false} 
        disabledSearchLotrinhStatus={true}
        onSearch = {onFilterChange}
        pageIndex = {1}
        refresh = {refresh}
        dsKhuVucExtra={[{
            id: -1,
            ten: lang.sale_working_plan.chuacoplan,
            divider: true
        },{
            id: 0,
            ten: "All"
        }]}
        />
        {<DataTable 
        tableCellStyle={{
            textAlign: "left"
        }}
        pageSize={18} 
        rows={rows} 
        columns={columns.concat(shouldChangePlan && shouldChangePlan.current ? [
            {
                field: "",
                width: 25,
                selected_icon: <ReadMoreIcon style={{
                    height:15
                }} onClick={evt => {
                    setOpenPlanDetail(true);
                }}/>,
            }] : [])} 
        pagination={true} 
        rowCount={rows.length} 
        onRowClick={rowData =>{
            setSelectedRow(rowData.row);
            }}
        paginationMode="client"/>}
    {openPlanDetail && <LotrinhDetail setError={setError} setOpenPlanDetail={setOpenPlanDetail} open={openPlanDetail} lotrinh={selectedRow} 
            cusId={cusId} onChange={evt => {
                setOpenPlanDetail(false)
                setRefresh(!refresh)
                }}/>}
    </div> 
}
