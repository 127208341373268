import React, { useEffect, useState } from "react"
import lang from "../../../asset/Language.json"
import Dialog from "../../../Components/ConfirmDialog"
import KhuVucService from "../../../services/KhuVucService";

export default function XoaKhuvucSaleAdmin(props){
    const { deletedAdmin, open, setClose, setError,setRow, khuvuc } = props;
    
    const [changed, setChanged] = useState()

    useEffect(async()=> {
        if(!changed){
            return;
        }
        setChanged()
        const sv = new KhuVucService();
        
        const req = {
            id: khuvuc.Id,
            admin: khuvuc.SaleAdmin.filter(s => s.usrID != deletedAdmin.usrID).map(s => s.usrID).join(",")
        }
        const r  = await sv.setKhuvucAdmin(req)
        if(r.error) {
            setError(true);
            return;
        }

        setRow(Object.assign(khuvuc, {
            SaleAdmin: r ? JSON.parse(r) : "",
            Admin: req.admin
        }));
        setClose({});
    },[changed])
      

    return <Dialog
    className="dialog-a-visible"
    title={lang.generaltext.xoaSaleAdmin}
    open={open}
    okText ={lang.buttons.co}
    cancelText={lang.buttons.boqua}
    msgContent = {lang.generaltext.xoaSaleAdminConfirm.replace("{0}",deletedAdmin.name)}
    onClose= {evt => setClose({})}
    onYes ={evt => setChanged(true)}
    />


}