import ApiService from "./ApiService";
import {LeavingApi} from "../Contants/UrlContant";

class LeavingService {
    constructor() {
        this.api = new ApiService();
    }

    async addHolidays(data) {
        return await this.api.post(LeavingApi.addHolidays, data);
    }

    async removeHolidays(data) {
        return await this.api.post(LeavingApi.removeHolidays, data);
    }

    async addLeaveDays(data) {

    }

    async updateLeaveDays(data) {

    }

    async removeLeaveDays(data) {

    }

    async getLeaveDays(data) {
        const url = `${LeavingApi.getHolidays}?startDate=${data.fromDate}&endDate=${data.toDate}&userId=${data.userId || 0}`
        return await this.api.get(url);
    }

    async getLeaveTypes() {
        return await this.api.get(LeavingApi.getLeaveTypes);
    }
}

export default LeavingService;