import ApiService from "./ApiService";
import {apiStaff, ApiUserUrl, CusStaffApi, DepartmentApi, CustomerApi, ExportApi} from "../Contants/UrlContant";

class UserService {
    constructor(){
        this.apiService = new ApiService();
    }

    async login(data) {
        const result = await this.apiService.post(ApiUserUrl.login, data);
        if(result && result.data) {
            return result.data;
        }
        return result;
    }

    async createAccount(data){
        return await this.apiService.post(ApiUserUrl.createAccount, data);
    }

    async getListCustomer(data) {
        return await this.apiService.get(CustomerApi.getAll, data);
    }

    async addStaff(data) {
        return await this.apiService.post(apiStaff.add, data);
    }

    async updateStaff(data) {
        return await this.apiService.post(apiStaff.update, data);
    }

    async getAllStaff(page, pageSize, shipper) {
        return await this.apiService.get(`${apiStaff.getAll}/${page}/${pageSize}?staffName=${shipper}`);
    }

    async getAllInDept(deptId, page, pageSize, shipper) {
        return await this.apiService.get(`${apiStaff.getAllInDept}/${page}/${pageSize}/${deptId}?staffName=${shipper}`);
    }

    async getStaff(usrId) {
        return await this.apiService.get(`${apiStaff.getStaff}/${usrId}`);
    }

    async searchStaff(page, pageSize, cond) {
        return await this.apiService.post(`${apiStaff.search}${page}/${pageSize}`, cond);
    }

    async getDepartments() {
        return await this.apiService.get(`${DepartmentApi.getAll}`);
    }

    async deleteStaff(id) {
        return await this.apiService.post(`${apiStaff.delete}${id}`);
    }

    async createStaffAccount(data){
        return await this.apiService.post(apiStaff.createAccount, data);
    }
    async updateAccount(data) {
        return await this.apiService.post(`${apiStaff.updateAccount}`, data);
    }

    async deleteAccount(data) {
        return await this.apiService.post(`${apiStaff.blockAccount}`, data);
    }

    async resetPassword(data) {
        return await this.apiService.post(`${apiStaff.resetPassword}`, data);
    }

    async addNhanVien(data) {
        return await this.apiService.post(`${CusStaffApi.addNew}`, data);
    }

    async updateNhanvien(data)
    {
        return await this.apiService.post(`${CusStaffApi.update}`, data)
    }

    async deleteNhanvien(id){
        return await this.apiService.post(`${CusStaffApi.delete}${id}`);
    }

    async getNhanvien(id){
        return await this.apiService.get(`${CusStaffApi.get}?id=${id}`);
    }

    async getAllNhanvien(cusId, page, pageSize, staffName){
        return await this.apiService.get(`${CusStaffApi.getAll}${cusId}/${page}/${pageSize}?staffName=${staffName || ""}`)
    }

    async getWorkingPlan(fromDate, toDate, selectedKVM, searchStr, selectedSubKV){
        let url = `${apiStaff.getWorkingPlan}?startDate=${fromDate}&endDate=${toDate}&kvm=${selectedKVM}`
        if(searchStr){
            url += `&saleName=${searchStr}`
        }
        if(selectedSubKV) {
            
            url += `&subKV=${selectedSubKV.join("&subKV=")}`
        }
        
        return await this.apiService.get(url);
    }

    async updateWorkingPlan(data) {
        return await this.apiService.post(`${apiStaff.updateWorkingPlan}`, data);
    }

    async driverAvailable(excudedId) {
        return await this.apiService.get(`${apiStaff.driverAvailable}${excudedId || 0}`);
    }

    async exportExcel_sale_working_plan(data) {
        return await this.apiService.exportData(`${ExportApi.sale_working_plan}`,data,"SaleWorkingPlan.xlsx");
    }

}

export default UserService;