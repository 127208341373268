import { useEffect, useState } from "react"
import lang from "../../../asset/Language.json"
import SearchDropDown from "../../../Components/SearchDropDown";
import Dialog from "../../../Components/ConfirmDialog"
import { isEqual, pick } from "lodash";
import DatePicker from '@mui/lab/DatePicker';
import { TextField } from "@mui/material";
import LeavingService from "../../../services/LeavingService";

export default function LeaveDialog(props){
    const {open, closeDialog,  leave, saveChanges, startDate, setStartDate, endDate, setEndDate} = props;
    const [leaveTypes, setLeaveTypes] = useState([]);
    const [selectedType, setSelectedType] = useState(1);
    const [leaveNote, setLeaveNote] = useState();
    
    const canSave = () => {
        const lt = Object.assign({}, pick(leave, ["leaveType","leaveNote", "leaveDate"]), {
        
        });
        const cur = {
            leavType: selectedType,
                   };
        const isChanged = !isEqual(lt,cur);
        return true;//leave && selectedType && isChanged;
    }

    const leaveName = (id) => {
        return leaveNote || leaveTypes.find(l => l.id === id)?.leaveName;
    }

    const d = new Date();

    useEffect(async() => {
        const leaveService = new LeavingService();
        const types = await leaveService.getLeaveTypes();
        setLeaveTypes(types || []);
    },[])


    const content = <div className="flex-wrap">
    <div style={{
        flex: 1
    }}>
         <div className="flex-row">
         <SearchDropDown
         className ="inp-fld"
         label={lang.Leaves.leaveType} 
         type="number"
         value={selectedType}
         options = {(leaveTypes).map((option) => ({
         key: option.id,
         text: `${option.leaveName}`,
         secondary: option.LeaveDescription || ""
         }))}
         onChange={value => setSelectedType(value)} 
         disabled = {true}
         />
        <div className="flex-row">
          <div style={{
            marginRight: 35,
            width:250
        }}>
          <DatePicker
          views={ ['year', 'month', 'day']}
          className="inp-fld"
          label={"From:"}
          
          minDate={d.setFullYear(d.getFullYear()-1, d.getMonth(), d.getDay())}
          value={startDate}
          onChange={(newValue) => {
            setStartDate(newValue);
          }}
          renderInput={(params) => <TextField {...params} helperText={null} fullWidth />}
        />
        
          </div>
          <div style={{
            marginRight: 35,
            width:250
        }}>
<DatePicker
          views={ ['year', 'month', 'day']}
          label={"To:"}
          className="inp-fld"
          minDate={d.setFullYear(d.getFullYear()-1, d.getMonth(), d.getDay())}
          value={endDate}
          onChange={(newValue) => {
            setEndDate(newValue);
          }}
          renderInput={(params) => <TextField {...params} helperText={null} fullWidth />}
        />
        
          </div>
        </div>
        <div className="flex-row">
        <TextField
            className ="inp-fld"
            label={lang.Leaves.Description}
            key="LeaveDescription"
            fullWidth
            rows={3}
            multiline = {true}
            value = {leaveNote || ''}
            onInput={event => setLeaveNote(event.target.value)}
        />
        </div>
         </div>
    </div>
</div>

return <Dialog
className="dialog-a-visible"
okText ={lang.buttons.luu}
cancelText={lang.buttons.boqua}
disabledYes={!canSave()}
open={open}
onYes={evt => {saveChanges(startDate, endDate, leaveName(selectedType), selectedType)} }
onClose={evt => closeDialog(false)}
msgContent={content}
/>
}