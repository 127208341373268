import { DatePicker } from "@mui/lab";
import { InputAdornment, Stack, TextField } from "@mui/material"
import { isEqual } from "lodash";
import React, { useState, useEffect } from "react"
import lang from "../../../asset/Language.json"
import Dialog from "../../../Components/ConfirmDialog"
import KhuVucService from "../../../services/KhuVucService";

export default function KhuvucDetail(props){
    const {khuvuc, open, setClose, setError, setRow, dsKhuvuc} = props;
    const [ten,setTen] = useState(khuvuc.Ten);
    const [dongiatca, setDongiatca] = useState(khuvuc.DongiaTangcaGio)
    const [dongiakmvuot,setDongiakmvuot] = useState(khuvuc.DongiaKmVuot)
    const [sokmqd, setSokmqd] = useState(khuvuc.SokmQuydinh)
    const [khuvucChanged, setKhuvucChanged] = useState(false)
    const [phuphiXang, setPhuphiXang] = useState(khuvuc.PhuphiXang)
    const [fromDate, setFromDate] = useState(khuvuc.FromDate)
    const [dongiaBuoiVuot, setDongiaBuoiVuot] = useState(khuvuc.DongiaBuoiVuot)
    const [hotroWifi, setHotroWifi] = useState(khuvuc.HotroWifi)
    
    
    const isChangedDongia = () => {
        
        return khuvuc && khuvuc.FromDate && (khuvuc.FromDate.getFullYear() != new Date().getFullYear() || khuvuc.FromDate.getMonth() != new Date().getMonth()) && !isEqual({
            dongiaTangcaGio: parseInt(khuvuc.DongiaTangcaGio),
            dongiaKmVuot: parseInt(khuvuc.DongiaKmVuot),
            sokmQuydinh: parseInt(khuvuc.SokmQuydinh),
            phuphiXang: parseFloat(khuvuc.PhuphiXang || 0),
            dongiaBuoiVuot: parseInt(khuvuc.DongiaBuoiVuot || 0),
            hotroWifi: parseInt(khuvuc.HotroWifi || 0)
        },{
            dongiaTangcaGio: parseInt(dongiatca),
            dongiaKmVuot: parseInt(dongiakmvuot),
            sokmQuydinh: parseInt(sokmqd),
            phuphiXang: parseFloat(phuphiXang || 0),
            dongiaBuoiVuot: parseInt(dongiaBuoiVuot || 0),
            hotroWifi: parseInt(hotroWifi || 0)
        });
       
    }

    const isChanged = () => {
        return !isEqual({
            ten: khuvuc.Ten,
            dongiaTangcaGio: parseInt(khuvuc.DongiaTangcaGio),
            dongiaKmVuot: parseInt(khuvuc.DongiaKmVuot),
            sokmQuydinh: parseInt(khuvuc.SokmQuydinh),
            phuphiXang: parseFloat(khuvuc.PhuphiXang || 0),
            dongiaBuoiVuot: parseInt(khuvuc.DongiaBuoiVuot || 0),
            hotroWifi: parseInt(khuvuc.HotroWifi || 0)

        },{
            ten: ten,
            dongiaTangcaGio: parseInt(dongiatca),
            dongiaKmVuot: parseInt(dongiakmvuot),
            sokmQuydinh: parseInt(sokmqd),
            phuphiXang: parseFloat(phuphiXang || 0),
            dongiaBuoiVuot: parseInt(dongiaBuoiVuot || 0),
            hotroWifi: parseInt(hotroWifi || 0)
        });
    }

    useEffect(async() => {
        if(!khuvucChanged) {
            return;
        }
        setKhuvucChanged(false);
        const sv = new KhuVucService();
        const reqData = {
            id: khuvuc.Id,
            ten: ten,
            dongiaTangcaGio: dongiatca,
            dongiaKmVuot: dongiakmvuot,
            sokmQuydinh: sokmqd,
            phuphiXang: phuphiXang,
            hotroWifi: hotroWifi,
            dongiaBuoiVuot: dongiaBuoiVuot,
            fromDate: fromDate
        }
        const r = khuvuc.Id ? await sv.update(reqData) : await sv.add(reqData);
        if(r.error) {
            setError(true);
            return;
        }
        const updatedR = {
            Ten: ten,
            DongiaTangcaGio: dongiatca,
            DongiaKmVuot: dongiakmvuot,
            SokmQuydinh: sokmqd,
            PhuphiXang: phuphiXang,
            HotroWifi: hotroWifi,
            DongiaBuoiVuot: dongiaBuoiVuot,
            FromDate: fromDate
        };
        setRow(khuvuc.Id ? Object.assign(khuvuc, updatedR): dsKhuvuc.concat([Object.assign(updatedR, {
            Dongiabuoi:[],
            Sub_Khuvuc:[],
            Id: r.id
        })]));
        setClose({});
      }, [khuvucChanged])
    
      
    const content = <div style={{
        width:500,
        paddingTop:10,
        overflowY:"hidden"
    }}>
        <Stack direction="row" spacing={5}>
        <TextField
            className ="inp-fld"
            label={lang.lotrinh.tenkhuvuc}
            key="tenkhuvuc"
            fullWidth
            value = {ten || ' '}
            onInput={event => setTen(event.target.value)}
        />
        </Stack>
        
        <Stack direction="row" spacing={5}>
        <TextField
            className ="inp-fld"
            label={lang.generaltext.sokmquydinh} 
            type="number"
            value={sokmqd}
            onInput={event=> setSokmqd(event.target.value)}
            InputProps={{
                endAdornment: <InputAdornment position="end">km</InputAdornment>
            }}
            />
        <TextField
            className ="inp-fld"
            label={lang.generaltext.phuphixang} 
            type="number"
            value={phuphiXang}
            InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>
            }}
            />
        
        </Stack>
        <Stack direction="row" spacing={5}>
        <TextField
            className ="inp-fld"
            label={lang.lotrinh.dongiatca} 
            type="number"
            value={dongiatca}
            onInput={event => setDongiatca(event.target.value)}
            InputProps={{
                endAdornment: <InputAdornment position="end">vnd</InputAdornment>
            }}
            />
        <TextField
            className ="inp-fld"
            label={lang.lotrinh.dongiakmvuot} 
            type="number"
            value={dongiakmvuot}
            onInput={event => setDongiakmvuot(event.target.value)}
            InputProps={{
                endAdornment: <InputAdornment position="end">vnd</InputAdornment>
            }}
            />
        </Stack>
        <Stack direction="row" spacing={5}>
        <TextField
            className ="inp-fld"
            label={lang.generaltext.dongiaBuoiVuot} 
            type="number"
            value={dongiaBuoiVuot}
            onInput={event => setDongiaBuoiVuot(event.target.value)}
            InputProps={{
                endAdornment: <InputAdornment position="end">vnd</InputAdornment>
            }}
            />
        <TextField
            className ="inp-fld"
            label={lang.generaltext.hotroWifi} 
            type="number"
            value={hotroWifi}
            onInput={event => setHotroWifi(event.target.value)}
            InputProps={{
                endAdornment: <InputAdornment position="end">vnd</InputAdornment>
            }}
            />
        </Stack>
        {khuvuc && khuvuc.Id && <Stack style={{
            display: "none"
        }}>
            <DatePicker
            label={"Apply new changes from"}
            value={fromDate}
            disabled = {!isChangedDongia()}
            minDate={isChangedDongia() ? new Date(new Date().getFullYear(),new Date().getMonth(),1) : khuvuc.FromDate ? new Date(khuvuc.FromDate): new Date()}
            onChange={(newValue) => {
                setFromDate(newValue);
              }}
            renderInput={(params) => <TextField {...params} helperText={`Applied from: ${new Date(khuvuc.FromDate).toLocaleDateString()}`} />}
            />
        </Stack>}
    </div>;

    return <Dialog
    title={khuvuc.Id ? lang.generaltext.capnhatkhusetting: lang.generaltext.themkhuvuc}
    open={open}
    disabledYes={!isChanged()}
    okText ={lang.buttons.luu}
    cancelText={lang.buttons.boqua}
    msgContent = {content}
    onClose= {evt => setClose({})}
    onYes ={evt => setKhuvucChanged(true)}
    />


}