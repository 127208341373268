import { Checkbox, Collapse, FormControlLabel, FormGroup, List, ListItem, ListItemText} from "@mui/material";
import React, { useState } from "react"
import lang from "../../asset/Language.json"
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';


export default function LotrinhStatuses(props){
    const {onChange, checked} = props;
    const [open, setOpen] = useState(true)

    const dsStatuses = [{
        key: 0,
        text: lang.lotrinh.lotrinhmoi
    },{
      key: 1,
      text: lang.lotrinh.lotrinhDaHoanthanh
      },
      {
          key: 2,
          text: lang.lotrinh.lotrinhApproved
      },
      {
      key: 3,
      text: lang.lotrinh.lotrinhRejected
  }];

  const handleToggle = (event) => {
    const {
        target: { value },
      } = event;
      const v = parseInt(value);
    const currentIndex = checked.indexOf(v);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(v);
    } else {
      newChecked.splice(currentIndex, 1);
    }
      onChange(newChecked);
  };
    
    return <List>
            <ListItem key="lotrinh-status-filter" onClick={evt => setOpen(!open)}>
                <ListItemText primary={lang.lotrinh.lotrinhstatus}  />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit  style={{
              padding:"0 20px"
            }}>
              <FormGroup>
        {dsStatuses.map(s => <FormControlLabel control={<Checkbox key={s.key} value ={s.key} onChange={evt => handleToggle(evt)} checked={checked.indexOf(s.key) > -1} />} label={s.text} />)}
            </FormGroup>
                  
              </Collapse>
    </List>
}