import React, { useEffect, useState } from "react"
import Dialog from "../../../Components/ConfirmDialog"
import lang from "../../../asset/Language.json"
import KhuVucService from "../../../services/KhuVucService";

export default function DeleteKhuvucMaster(props){
    const {open, setClose, data, setError, rows, setRow} = props;
    const [xoaKhuvuc, setXoaKhuvuc] = useState(false)

    useEffect(async() =>{
        if(!xoaKhuvuc) {
            return;
        }
        setXoaKhuvuc(false);
        const sv = new KhuVucService();
        const r = await sv.delete(data.Id);
        if(r.error || !r){
            setError(true)
            return
        }
        setRow(rows.filter(r => r.Id != data.Id));
        setClose({});
    },[xoaKhuvuc])

    return <Dialog
    open={open}
    cancelText={lang.buttons.boqua}
    okText={lang.buttons.co}
    onClose={evt => setClose({})}
    msgContent={lang.lotrinh.xoakhuvucMasterconfirm.replace("{0}",data.Ten)}
    onYes = {evt => setXoaKhuvuc(true)}
    />
}