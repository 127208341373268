import React, {useEffect, useState} from "react"
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { isMobile } from "react-device-detect";
import { Button, TextField } from "@mui/material";
import lang from "../../../asset/Language.json";
import CustomerService from "../../../services/CustmerService";
import MasterDataService from "../../../services/MasterDataService";
import Dialog from "../../../Components/ConfirmDialog"

const useStyles = makeStyles({
    header: {
      "padding-bottom": "20px"
  },
  fullWidth: {
    display:"flex",
    "flex-direction": "column",
    padding: "30px",
    flex: 1,
    maxWidth:750,
    backgroundColor: "#fff"
    
  },
  flexrow: {
    display:"flex",
    "flex-wrap": "wrap",
    "align-items": "start",
    justifyContent: "start",
    "& .MuiFormControl-root": isMobile ? {
      width: "100%"
    }: {
      "margin-right": "30px"
    }
  },
  flex: {
    flex: "1 1 auto",
    padding:"20px 0 0 0"
  },
  alignLeft: {
    "text-align": "left"
    
  },
  formField: {
      "margin-bottom": "15px"
  },
  detailContent: {
    maxwidth:"750px",
    width: "100%"
  },
  flexEnd: {
    display: "flex",
    justifyContent: "end",
    padding: "20px 0 0 0"
  },
  nopadding:{
    padding:0
  },
  flexNoWrap:{
    display:"flex",
    padding: "20px"
  },
  paddingTop: {
    paddingTop: 30
  },
  dropdown:{
    display: "flex",
    "& .MuiFormControl-root": {
      width: "250px"
    }
  }
  
  });
export default function CustomerDetail (props) {
    const {cus, onSaved} =  props;
    const row = cus || {};
    const classes = useStyles();
    const cusSV = new CustomerService();
    const msv = new MasterDataService();

    const [hoten, setHoten] = useState(row.cusName);
    const [phone, setPhone] = useState(row.cusPhone);
    const [email, setEmail] = useState(row.cusEmail);
    const [selectedQ, setSelectedQ] = useState(parseInt(row.cusDistrict || 0));
    const [selectedW, setSelectedW] = useState(row.cusWard)
    const [contact, setContact] = useState(row.cusContact);
    const [diachi, setDiachi] = useState(row.cusAddress)
    const [dsWard, setDsWard] = useState([]);
    const [dsquan, setDsquan] = useState([]);
    const [province, setProvince] = useState(parseInt(row.cusCity));
    const [saveChanges, setSaveChanges] = useState(false);
    const [ready, setReady] = useState(false)
    const [dsProvince, setDsProvince] = useState([])

    useEffect(async() => {
      const dsq = await msv.GetDanhSachProvince();
      setDsProvince(dsq);
      setProvince(row.cusCity || dsq[0].key);
    },[ready]);

    useEffect(async() => {
      if(!province) {
        return;
      }
      const dsq = await msv.GetDanhSachQuan(province);
      setDsquan(dsq);
      setSelectedQ(row.cusDistrict|| dsq[0].key);
    },[province]);


    useEffect(async() => {
        if(!selectedQ) {
          setDsWard([]);
          return;
        }
        const dsw = await msv.GetDanhSachPhuong(selectedQ);

        setDsWard(dsw);
        setSelectedW(row.cusWard);
      },[selectedQ]);
  

      

    useEffect(async() => {
        if(!saveChanges) {
            return;
        }
        setSaveChanges(false);
        const reqdata = {
            cusId: cus.cusId,
            cusName: hoten,
            cusPhone: phone,
            cusEmail: email,
            cusAddress: diachi,
            cusWard: selectedW || "",
            cusDistrict: parseInt(selectedQ || 0),
            cusCity: province || 202,
            cusCountry: 1,
            cusContact: contact || ""
        };
        
        const r = cus.cusId ? await cusSV.saveCustomer(reqdata) : await cusSV.addCustomer(reqdata);
        if(r.error) {
            //show messag error
            return;
        }
        onSaved(true);
    },[saveChanges]) 

    return <Dialog open={true}
    onClose={e=> props.onClose(false)}
    onYes={evt => setSaveChanges(true)}
    okText={lang.buttons.luu}
    msgContent ={<div className="cus-detail flex-column">
    <div className={classes.flexrow}>
      <TextField id="hoten" 
        variant="standard" 
        required={true}
        value={hoten}
        onInput={evt => setHoten(evt.target.value)}
        label={lang.StaffList.Hoten} 
        className={classes.formField}
        />
        <TextField id="phone" 
        required={true}
      variant="standard" 
      value={phone}
      onInput={evt => setPhone(evt.target.value)}
      label={lang.StaffList.SDT} 
      className={classes.formField}
      />
    </div>
    <div className={classes.flexrow} >
    <TextField id="email" 
      variant="standard" 
      value={email}
      required={true}
      onInput={evt => setEmail(evt.target.value)}
      label={lang.StaffList.email} 
      className={classes.formField}
      />
      <TextField id="contact" 
      variant="standard" 
      value={contact}
      onInput={evt => setContact(evt.target.value)}
      label={lang.Khachhang.contact} 
      className={classes.formField}
      />
    </div>
    <div className={classes.flexrow}>
      <TextField id="diachi" 
        variant="standard" 
        fullWidth={true}
        value={diachi}
        onInput={evt => setDiachi(evt.target.value)}
        label={lang.StaffList.Diachi} 
        className={classes.formField}
        />
        
    </div>
    <div className="flex-wrap">
    <div className={clsx({[classes.flexrow]: true})} style={{minWidth: 250}}>
                <TextField
                    id="dsprovince"
                    select
                    label={lang.lotrinh.province}
                    value= {province}
                    onChange={e => setProvince(e.target.value)}
                    SelectProps={{
                        native: true,
                    }}
                    helperText=""
                    fullWidth={true}
                    variant="standard"
                    color="primary"
                    >
                    {(dsProvince).map((option) => (
                        <option key={option.key} value={option.key}>
                        {option.text}
                        </option>
                    ))}
                </TextField>
                </div>
    <div className={clsx({[classes.flexrow]: true})} style={{minWidth: 250}}>
                <TextField
                    id="dsquan"
                    select
                    label={lang.lotrinh.quan}
                    value= {selectedQ}
                    onChange={e => setSelectedQ(e.target.value)}
                    SelectProps={{
                        native: true,
                    }}
                    helperText=""
                    fullWidth={true}
                    variant="standard"
                    color="primary"
                    >
                    {(dsquan).map((option) => (
                        <option key={option.key} value={option.key}>
                        {option.text}
                        </option>
                    ))}
                </TextField>
                </div>
    <div className={clsx({[classes.flexrow]: true})} style={{minWidth: 250}}>
    <TextField
        id="dsphuong"
        className={classes.customerBox}
        select
        disabled = {!selectedQ}
        label={lang.lotrinh.phuong}
        value= {selectedW}
        onChange={e => setSelectedW(e.target.value)}
        SelectProps={{
            native: true,
        }}
        fullWidth={true}
        variant="standard"
        color="primary"
        >
        {(dsWard).map((option) => (
            <option key={option.key} value={option.key}>
            {option.text}
            </option>
        ))}
    </TextField>
    </div>
    </div>
  </div>}
    />
}
