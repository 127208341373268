import React, { useState, useEffect } from "react"
import lang from "../../../asset/Language.json"
import LotrinhService from "../../../services/LotrinhService"
import DataTable from "../../../Components/DataTable"
import { Box, Button } from "@mui/material";
import { Refresh } from "@mui/icons-material";

export default function KhunggioList(props){
    const {setError} = props;
    const[rows, setRows] = useState([])
    const[refresh, setRefresh] = useState()

    useEffect(async() => {
        const sv = new LotrinhService();
        const r = await sv.getAllKhunggio();
        if(r.error) {
            setError(true);
            return;
        }
        setRows((r||[]).map((t,idx) => Object.assign(t, {
            stt: idx + 1
        })));
    },[refresh])

    const columns =[{
        field: "stt",
        headerName: lang.sale_working_plan.stt,
        width: 30
    },
    {
        field: "startTime",
        headerName: lang.lotrinh.starttime,
        width: 80
    },
    {
        field: "endTime",
        headerName: lang.lotrinh.endTime,
        width: 80
    }];


    return <Box>
            <div className="page-header">
            <div className="page-title"><span>{lang.lotrinh.dsKhunggio}</span></div>
            <Button variant="text" className="btn-text"><Refresh/>Refresh</Button>
            </div>
            
            <DataTable
        rows={rows} 
        columns={columns} 
        rowCount={rows.length} 
        pageSize={20} 
        paginationMode="client"
        pagination={true} 
        />
        </Box>
}