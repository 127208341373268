import './App.css';
import { ThemeProvider, createTheme} from '@mui/material';
import Login from './Pages/Account/Login';
import ForgotPwd from './Pages/Account/ForgotPass';
import React from "react"
import AdminScreen from './Pages/AdminSreen';
import SaleScreen from './Pages/SaleScreens';
import DriverScreen from './Pages/DriverScreens';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

const greenTheme = createTheme({
  palette: {
    primary: {
      main: "#73ca2f"
    },
    secondary: {
      main: "#29567F"
    }
  }
});


function App() {
  const [view,setView] = React.useState("login");

  const gotoView = (v) => {
    setView(v);
  }
  
  const displayView = () => {
    switch(view) {
      case "forgotPwd":
        return <ForgotPwd onNavigate={gotoView} />;
      case "administration":
      case "customer":
      case "saleadmin":
      case "user":
         return <AdminScreen onNavigate={gotoView}/>
      case "sale":
         return <SaleScreen/>
      case "driver":
        return <DriverScreen/>
      case "accessed":
        return <div>Sorry, You have no access</div>
      default:
        return <Login onNavigate={gotoView}/>;
    }
  }
  return (
    <ThemeProvider theme={greenTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className={`App ${view || "login"}`}>
          {displayView()}
        </div>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
