import React from "react";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { grey } from "@mui/material/colors";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: grey[50],
      color: theme.palette.common.black,
      fontWeight: 600,
      border: "1px solid #ccc",
      textAlign: "center"
    }
  }));
  
export default function TableHeader(props) {
    const {columns} = props;

    return <TableHead>
      {columns.map(row => <TableRow>
        {row.map(col =><StyledTableCell colSpan={col.colSpan || 1} rowSpan={col.rowSpan || 1} style={col.width ? {
      width: col.width
    }: {}} component="th" >{col.headerName}</StyledTableCell>)}    
      </TableRow>)
      }
  </TableHead>
}

TableHeader.propTypes = {
  columns: PropTypes.array.isRequired
}