import React from "react"
import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import PropTypes from 'prop-types';
import { useState } from "react";
import { grey, purple, yellow } from "@mui/material/colors";


  

export default function TableBodyRows(props) {
    const {rows, columns, rowsPerPage, page, onRowDoubleClick, onRowClick, tableCellStyle, summaryRows} = props;
    const [rowSelected, setRowSelected] = useState({});

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.body}`]: Object.assign({
        padding: "10px 16px",
        border: "1px solid #ccc",
        textAlign: "center"
      }, tableCellStyle || {}),
    }));

    const handleRowSelected = (event, row) => {
      if((row.weekday && ["Sat","Sun"].indexOf(row.weekday) > -1) || row.hasLeave ) {
        return;
      }
      event.preventDefault();
       setRowSelected(row);

      if(onRowClick) {
        onRowClick({
          event: event,
          row: row
        });
      }
    }

    const handleRowDoubleClick = (event, row) => {
      if((row.weekday && ["Sat","Sun"].indexOf(row.weekday) > -1) || row.hasLeave ) {
        return;
      }
      setRowSelected(row);
      if(onRowDoubleClick) {
        onRowDoubleClick({
          event: event,
          row: row
        });
      }
    }

    const getRowStyle = (row) => {
      if(row.selected) {
        return {backgroundColor: purple[100]}
      }
      if(row.weekday && ["Sat","Sun"].indexOf(row.weekday) > -1) {
       return {backgroundColor: grey[200]} 
      }
      else if(row.hasLeave) {
        return {backgroundColor: "lightyellow"} 
      }
      return {};
    }
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
    page > 0 ? rowsPerPage - rows.length : 0;

    return <TableBody>
            {rows.map((row, idx) => (
                <TableRow key={row.id || row.Id || idx} 
                hover
                onClick={evt => handleRowSelected(evt, row)}
                onDoubleClick = {evt => handleRowDoubleClick(evt, row)}
                selected={row.id && (rowSelected.id === row.id || row.selected)}
                style={ getRowStyle(row)}
                >
                    {columns.map(col => (
                        <StyledTableCell  >
                            {col.field ? row[col.field] : ""}
                            {col.selected_icon && rowSelected.id === row.id && row.id ? col.selected_icon : ""}
                            {col.editIcon && row.selected }
                        </StyledTableCell>)
                    )}
                </TableRow>
            ))}
            {emptyRows > 0 && (
            <TableRow 
                style={{ height: 42.3 * emptyRows }}>
              <TableCell colSpan={6}  style={{
                borderBottom:0
              }}/>
            </TableRow>
          )}
          {summaryRows.map(row => <TableRow>
              {row.map(col =><StyledTableCell colSpan={col.colSpan || 1} rowSpan={col.rowSpan || 1} style={col.width ? {
            width: col.width,
            fontWeight: "bold"
          }: {fontWeight: "bold"}} >{col.sumValue || ""}</StyledTableCell>)}    
            </TableRow>)
            }
        </TableBody>   
}

TableBodyRows.propTypes = {
    rows: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    onRowClick: PropTypes.func,
    onRowDoubleClick:PropTypes.func
}