import { Collapse, Divider, FormControlLabel, List, ListItem, ListItemText, Radio, RadioGroup } from "@mui/material";
import React, { useState, useEffect } from "react"
import lang from "../../asset/Language.json"
import KhuvucService from "../../services/KhuVucService"
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SubKhuvucCheckList from "./SubKhuvucCheckList";
import SubKhuvucDropDown from "./SubKhuvucDropdown";


export default function KhuVucMasterCheckList(props){
    const {onChange, value, selectedSubKV, setSelectedSubKV, setSelectedSubKVNames,  dsKhuVucExtra} = props;
    const [dsKhuVuc, setDsKhuVuc] = useState([]);
    const [ready, setReady] = useState();
    const [openKhuvucMater, setOpenKhuvucMaster] = useState(true)
    
    const khuvucSV = new KhuvucService();
    useEffect(async() => {
      const r = await khuvucSV.getAll();
      if(r.error) {
        //setError(true);
        return;
      }
      setDsKhuVuc(r);
    },[ready]);

    return <List>
            <ListItem key="lotrinh-status-filter" onClick={evt => setOpenKhuvucMaster(!openKhuvucMater)}>
                <ListItemText primary={lang.lotrinh.khuvuc}  />
                {openKhuvucMater ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openKhuvucMater} timeout="auto" unmountOnExit  style={{
              padding:"0 20px"
            }}>
              <RadioGroup
                    aria-label="gender"
                    defaultValue={dsKhuVuc.length > 0 ? dsKhuVuc[0].id : 0}
                    value = {value ? value.id : 0}
                    name="radio-buttons-group"
                    onChange={evt => onChange((dsKhuVucExtra || []).concat(dsKhuVuc).find(k => k.id == evt.target.value))}
                  >
                  
                  {(dsKhuVucExtra || []).concat((dsKhuVuc || [])).map(k => <>
                  <FormControlLabel value={k.id} control={<Radio />} label={k.ten} />
                  <SubKhuvucCheckList onChange={setSelectedSubKV} checked={selectedSubKV} open ={value && value.id == k.id} parentId = {k.id} />
                  {k.divider && <Divider/>}
                  </>
                  )}
                    </RadioGroup>
                  
              </Collapse>
    </List>
}