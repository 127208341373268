import * as React from 'react';
import DataGrid from "../../../Components/DataTable";
import lang from "../../../asset/Language.json"
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { TextField, InputAdornment, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import clsx from 'clsx';
import NhanvienDetail from '../../NhanVienDetail';
import UserService from "../../../services/UserService";
import RefreshIcon from '@mui/icons-material/RefreshOutlined';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import ManageAccountsIcon from '@mui/icons-material/AccountBox';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import Dialog from "../../../Components/ConfirmDialog"
import CreateAccount from '../../../Components/TaoTaiKhoan';

import {UserTypes, UserTypeEnum} from "../../../Contants/UserTypeEnum"
import { isMobile } from 'react-device-detect';
import ChangePassword from '../../Account/ChangePassword';

const columns = [
  { field: 'id',
   headerName: lang.StaffList.stt,
   width: 30,sortable: false
  },
  {
    field: 'name',
    headerName: lang.StaffList.Hoten,
    width: 150,
    sortable: false
  },
  {
    field: 'phoneNumber',
    headerName: lang.StaffList.SDT,
    width: 100,
    sortable: false
  },
  {
    field: 'utName',
    headerName: lang.StaffList.chucvu,
    width: 60,
    sortable: false
  },
  {
    field: 'deptName',
    headerName: lang.StaffList.phongban,
    sortable: false,
    width: 180,
    
  },
  {
    field: 'email',
    headerName: lang.StaffList.email,
    width: 120,
    sortable: false
  },
  {
    field: 'address',
    headerName: lang.StaffList.Diachi,
    sortable: false,
    width: 460,
    
  }
];

const useStyles = makeStyles((theme) => ({
  stretchContainer: {
      display:"flex",
      flexDirection: "column",
      flex: "1 1 auto"
    },
    customerBox: {
      "& label.MuiFormLabel-root": {
        "font-weight": "bold!important",
        "color": "#000"

      },
      "& .MuiSelect-root": {
        "font-weight": "bold!important",
        "color": "#000"
      },
    },
    customerBox1: {
      "& .MuiSvgIcon-root": {
        cursor: "pointer",
        transform: "rotate(90deg)"
      }
    },
    gridOrderHiden: {

    },
    orderDetailOpen: {

    }
}))

export default function StaffList(props) {
    const {userType, setExtraTitle, cusId} = props;
    
    const [pageIndex, setPageIndex] = useState(0);
    const[totalNV, setTotalNV] = useState(0);
    const[shipper, setShipper] = useState();
    const[openUserDetail, setOpenUserDetail] = useState(false);
    const[selectedDept, setSelectedDept] = useState();
    const[selectedUser, setSelectedUser] = useState();
    const [refresh, setRefresh] = useState(true);
    const [staffs, setStaffs] = useState([]);
    const [addNV, setAddNV] = useState();
    const [department, setDepartment] = useState([]);
    const [isRender, setIsRender] = useState(false);
    const [showMsg, setShowMsg] = useState(false);
    const [deleteStaff, setDeleteStaff] = useState(false);
    const [createAccount, setCreateAccount] = useState(false);
    const [expandSearchBar, setExpandSearchBar] = useState(true)
    const [showLoading, setShowLoading] = useState(false)

    const pageSize = 15;
    const classes = useStyles();
    const handleOpenUserDetail = (row) => {
      setSelectedUser(row);
      setOpenUserDetail(true);
    };

    const handleCloseUserDetail = (shouldRefresh) => {
      setOpenUserDetail(false);
      setAddNV(false);
      setSelectedUser(null);
      setExtraTitle("");
      if(shouldRefresh) {
        setRefresh(!refresh);
      }
      
    }

    const handleShipperChange = (evt) => {
      setShipper(evt.target.value);
      if(!evt.target.value) {
        setRefresh(!refresh);//clear search by shipper
      }
    }
    const uSv = new UserService();
    const isAdmin = uSv.apiService.userLoggedIn.type === UserTypeEnum.ADMINISTRATOR;

    useEffect(async() =>{
      if(isRender) {
        return;
      }
      const d = await uSv.getDepartments();
      if(!d || d.error) {
        console.log(d.error);
        return;
      }
      const ds = [{deptId: '', deptName: ''}].concat(d);
      setDepartment(ds);
    },[isRender]);

    useEffect( async () => {
      if(!deleteStaff) {
        return;
      }
      await uSv.deleteStaff(selectedUser.usrId);
      setDeleteStaff(false);
      setRefresh(!refresh);
      
    },[deleteStaff])

    useEffect(async() => {
      
      if(openUserDetail) {
        return;
      }
      setSelectedUser();
      setExtraTitle();
      setShowLoading(true)
      const uList = selectedDept ? await uSv.getAllInDept(selectedDept, pageIndex + 1, pageSize, shipper || "") : await uSv.getAllStaff(pageIndex + 1, pageSize, shipper || "");
      if(uList && uList.result){
        let zones = uSv.apiService.userLoggedIn.data.zones;
        const isAdmin = uSv.apiService.userLoggedIn.type === UserTypeEnum.ADMINISTRATOR;

        if(zones) {
          zones = JSON.parse(zones);
        }
        const list = uList.result.map((u,idx) => {
          const districtO = u.districts ? JSON.parse(u.districts): [];
          const role = UserTypes.filter(t => t.key == u.usrTypeId);
          const inZone = isAdmin  ||  districtO.filter(d => zones.indexOf(d.districtId)).length > 0;
          if(!inZone) {
            return null;
          }
          return Object.assign({}, u, {
          id: pageSize*pageIndex + idx + 1,
          districtNames: districtO.map(d1 => d1.districtName).join(","), districts: districtO,
          role: role.length > 0 ? role[0].text : ""
        })}).filter(u2 => u2 !== null);

        setStaffs(list);
        setTotalNV(uList.total);
        setShowLoading(false)
      }
     
    }, [pageIndex,selectedDept,refresh])
    
  return (
    <div className={clsx({[classes.stretchContainer]: true, ["staff-main-content"]: isMobile, ["main-content"]: true })}>
       <div className="page-header" >
       <div className="page-title"><span>{lang.lotrinh.danhsachnhanvien}</span></div>
              <div className={classes.flexSpace}>
                {isAdmin && <Button className="btn-icon" disabled={!selectedUser || createAccount} title={lang.StaffList.taotaikhoan} variant="contained" onClick={evt => setCreateAccount(true)}>
                  <ManageAccountsIcon/>
                </Button>}
                {isAdmin && <Button className="btn-icon" disabled={addNV || !selectedDept} title={lang.StaffList.addNV} variant="contained" onClick={evt => setAddNV(true)}>
                  <PersonAddOutlinedIcon/>
               </Button>}
                {isAdmin && <Button className="btn-icon" disabled={!selectedUser} title={lang.StaffList.xoanhanvien} variant="contained" onClick={evt => setShowMsg(true)}>
                  <DeleteIcon/>
              </Button>}
              <Button id="refreshnv" className="btn-text" title={lang.StaffList.refreshNhanvien} variant="text" onClick={evt => setRefresh(!refresh)}>
                  <RefreshIcon/>
                  Refresh
                </Button>
              </div>
            </div>
    <div style={{
      margin: "0 auto"
    }}>
    {<div className={clsx({"search-bar-con": true, ["dsnv"]: true, ["collapse"]: !expandSearchBar})}>
     <div className="search-bar">
        <div className="con-item ds">
        <TextField
            id="dsphongban"
            className={classes.customerBox}
            select
            label={lang.StaffList.phongban}
            value= {selectedDept}
            onChange={e => {
              setSelectedDept(e.target.value);
            }}
            SelectProps={{
                native: true,
            }}
            helperText=""
            fullWidth={true}
            variant="standard"
            color="primary"
            >
            {(department).map((option) => (
                <option key={option.deptId} value={option.deptId}>
                {option.deptName}
                </option>
            ))}
        </TextField>
        </div>
        <div className="con-item search">
        <TextField
        id="searchShipper"
        label={lang.StaffList.searchnhanvien}
        onInput = {handleShipperChange}
          className={clsx(classes.customerBox, {[classes.customerBox1]: true})}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start" onClick={evt =>{setRefresh(!refresh)}} >
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        variant="standard"
      />
      
        </div>
        
      </div>
      </div>
      }
       {selectedUser && <Dialog id="xoanhanvienConfirm" onClose={value => setShowMsg(false)} 
       open={showMsg} 
       title={lang.StaffList.xoastaffconfirm}
       msgContent= {selectedUser.name}
       okText = {lang.buttons.co}
       cancelText = {lang.buttons.boqua}
       onYes = {evt => {
        setShowMsg(false); 
        setDeleteStaff(true);
        }}
        
       />}
      {<DataGrid
        tableCellStyle={{
          textAlign: "left"
        }}
        rows={staffs}
        columns={columns}
        pageSize={pageSize}
        pagination={true}
        paginationMode={'server'}
        loading={showLoading}
        onPageChange={page => setPageIndex(page)}
        rowCount={totalNV}
        autoHeight={true}
        autoPageSize={true}
        rowHeight={35}
        onRowClick={rowData =>{
           setSelectedUser(rowData.row);
           setExtraTitle(rowData.row.name);
           }}
        onRowDoubleClick={row => handleOpenUserDetail(row)}
      />}
     
      {(openUserDetail || addNV) && <NhanvienDetail nhanvienInfo = { addNV ? {row:{usrTypeId: userType, deptId: selectedDept, cusId: cusId}}: selectedUser} onClose={data => handleCloseUserDetail(data)}/>}
        {createAccount && selectedUser && !selectedUser.usrAccount && <CreateAccount onClose={evt => {
          setCreateAccount(false);
          setRefresh(true);
          }} user= {selectedUser} refresh={refresh} setRefresh={setRefresh} defaultRole={selectedUser.chucvu} disabledRole={true}/>}
        
        {createAccount && selectedUser && selectedUser.usrAccount && <ChangePassword onClose={evt => {
          setCreateAccount(false);
          }} user= {selectedUser.usrAccount} />}
    </div>
    
    </div>
  );
}
