import ApiService from "./ApiService";
import { ExportApi, LotrinhApi} from "../Contants/UrlContant";

class LotrinhService {
    constructor(){
        this.api = new ApiService();
    }

   
    async get(id) {
        return await this.api.get(`${LotrinhApi.get}?id=${id}`);
    }

    async getAll() {
        return await this.api.get(`${LotrinhApi.getAll}`);
    }

    async update(data) {
        return await this.api.post(`${LotrinhApi.update}`, data);
    }

    async adminUpdate(data) {
        return await this.api.post(`${LotrinhApi.adminUpdate}`, data);
    }

    async generateData(data) {
        return await this.api.post(`${LotrinhApi.generateDataForSale}/${data.saleId}/${data.thang}/${data.nam}?ngays=${data.ngays}`);
    }

    async add(data) {
        return await this.api.post(`${LotrinhApi.addNew}`, data);
    }

    async delete(id, saleId) {
        return await this.api.post(`${LotrinhApi.delete}/${saleId}/${id}`);
    }

    async lotrinhToday() {
        return await this.api.get(`${LotrinhApi.lotrinhToday}`);
    }

    async search(pageSize, pageIndex, cond){
        return await this.api.post(`${LotrinhApi.search}/${pageSize}/${pageIndex}`, cond);
    }

    async searchDiemLotrinh(page, pageIndex, cond){
        return await this.api.post(`${LotrinhApi.searchdiemlotrinh}/${page}/${pageIndex}`, cond);
    }

    async exportBangLotrinhThang(data) {
        return await this.api.exportData(`${LotrinhApi.exportlotrinh}`, data);
    }

    async BaocaoTongHop(page,pageIndex,data){
        return await this.api.post(`${LotrinhApi.baocaotonghop}${page}/${pageIndex}`, data);
    }

    async get_Outlet_Sale_Reposibility(saleId){
        return await this.api.get(`${LotrinhApi.outlet_Sale_Resposibility}${saleId}`);
    }

    async updateKhunggio(data) {
        return await this.api.post(`${LotrinhApi.updateKhunggio}`, data);
    }

    async addKhunggio(data) {
        return await this.api.post(`${LotrinhApi.addKhunggio}`, data);
    }

    async deleteKhunggio(id) {
        return await this.api.post(`${LotrinhApi.deleteKhunggio}${id}`);
    }

    async getAllKhunggio(){
        return await this.api.get(LotrinhApi.getAllKhunggio);
    }

    async banglotrinhchitiet(id, startDate, endDate, statuses) {
        return await this.api.get(`${LotrinhApi.banglotrinchitiet}${id}?startDate=${startDate}&endDate=${endDate}&statuses=${statuses}`);
    }

    async exportPDF_Banglotrinhchitiet(id, startDate, endDate, statuses, fileName){
        return await this.api.exportData(`${ExportApi.banglotrinhchitietPDF}${id}?startDate=${startDate}&endDate=${endDate}&statuses=${statuses}`, null, `${fileName || "Bang_lotrinh_an_toan"}.pdf`);
    }

    async exportPDF_Baocao_Tonghop(data){
        return await this.api.exportData(`${ExportApi.baocaotonghopPDF}`,data,"Bao_cao_tong_hop.pdf");
    }

    async exportExcel_baocao_tonghop(data) {
        return await this.api.exportData(`${ExportApi.baocaotonghopExcel}`,data,"Baocaochiphi.xlsx");
    }

    async exportExcel_Banglotrinhchitiet(id, startDate, endDate, statuses, fileName){
        return await this.api.exportData(`${ExportApi.banglotrinhchitietExcel}${id}?startDate=${startDate}&endDate=${endDate}&statuses=${statuses}`, null, `${fileName || "Bang_lotrinh_an_toan"}.xlsx`);
    }

    async getLotrinhListView(startDate, endDate){
        return await this.api.get(`${LotrinhApi.getLotrinhListView}?startDate=${startDate}&endDate=${endDate}`);
    }

    async getLotrinhDetail(id) {
        return await this.api.get(`${LotrinhApi.getLotrinhDetail}${id}`);
    }

    async checkin(data){
        return await this.api.post(`${LotrinhApi.checkin}`, data);
    }

    async confirm(data){
        return await this.api.post(`${LotrinhApi.confirm}`, data);
    }

}

export default LotrinhService;