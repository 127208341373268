import { Button, InputAdornment, TextField } from "@mui/material"
import React, { useState, useEffect } from "react"
import lang from "../../../asset/Language.json"
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { orange, lightGreen } from "@mui/material/colors";
import UserService from "../../../services/UserService"
import { UserTypeEnum } from "../../../Contants/UserTypeEnum";
import LotrinhService from "../../../services/LotrinhService";
import KhuVucService from "../../../services/KhuVucService";
import SearchDropDown from "../../../Components/SearchDropDown";
import Icon from "@mui/icons-material/AccountCircle"
import { isEqual, pick } from "lodash";
import Dialog from "../../../Components/ConfirmDialog"

export default function LotrinhDetail(props) {
    const {  lotrinh, onChange, setError, cusId, setOpenPlanDetail, open } = props;
    const [nhanvien, setNhanvien] = useState(lotrinh.Id || lotrinh.id)
    const [taixe, setTaixe] = useState(lotrinh.driver)
    const [selectedNew, setSelectedNew] =  useState([])
    const [dsTaixe, setDsTaixe] = useState([])
    const [getMoreTaixe, setGetMoreTaixe] = useState()
    const [selectedKhuVuc, setSelectedKhuvuc] = useState(lotrinh.khuvuc || lotrinh.khuVuc)
    const [dsKhuvuc,setDsKhuvuc] = useState([])
    const [saveChanges, setSaveChanges] =  useState(false);
    const [ready, setReady] = useState();
    const[songaydangky, setSongaydangky] = useState(lotrinh.workingday)
    const[khunggio, setKhunggio] = useState(lotrinh.khunggio || "")
    const [dsKhunggio, setDsKhunggio] = useState([])
    const [workingPlace, setWorkingPlace] = useState(lotrinh.workingplace || lotrinh.workingPlace || "")
    const [reportEmail, setReportEmail] = useState(lotrinh.reportemail || lotrinh.reportEmail || "" )


    const canSave = () => {
        const lt = Object.assign({}, pick(lotrinh, ["id", "driver","khuvuc", "workingday","khunggio", "workingPlace", "workingplace", "reportEmail", "reportemail"]), {
        
        });
        const cur = {
            id: nhanvien,
            driver: taixe,
            khuvuc: selectedKhuVuc,
            workingday: songaydangky,
            khunggio:khunggio,
            workingPlace: workingPlace,
            reportEmail: reportEmail
        };
        const isChanged = !isEqual(lt,cur);
        return nhanvien && taixe && selectedKhuVuc && isChanged;
    }

    const getNhanVien = async (type, cusId, ten) => {
        const usv = new UserService();
        if(type == UserTypeEnum.Driver) {
            const r = await usv.driverAvailable(taixe);
            if(r.error) {
                setError(true);
                return;
            }
            return r;
        }
        const r = await usv.searchStaff(1,500, {
            chucvu: type,
            cusId: cusId,
            ten: ten
        });
        if(r.error) {
            setError(true);
            return;
        }
        return r.result;
    }
    

    useEffect(async() => {
        //get tai xe
        const ksv = new KhuVucService();
        const ltsv = new LotrinhService();
        Promise.all([
            getNhanVien(UserTypeEnum.Driver, 0),
            ksv.getAllSubKhuvucByKhuvuc(0),
            ltsv.getAllKhunggio()
        ]).then(r => {

        if( r[0].error || r[1].error || r[2].error) {
            setError(true);
            return;
        }
        setDsTaixe(r[0]);
        setDsKhuvuc(r[1]);
        setDsKhunggio(r[2]);
        });
    },[ready])

    useEffect(async() => {
        if(!saveChanges) {
            return;
        }
        setSaveChanges(false);
        const sv = new UserService();
        const data = {
            id: nhanvien,
            driver: taixe,
            khuvuc: selectedKhuVuc,
            khunggio: khunggio,
            workingday: songaydangky,
            workingPlace: workingPlace,
            reportEmail: reportEmail
        };

        const r = await sv.updateWorkingPlan(data);
        
        if(r.error) {
            setError(true);
            return;
        }
        onChange();
    },[saveChanges])

    const content = <div className="flex-wrap">
       <div style={{
           flex: 1
       }}>
            <div style={{
                justifyContent:"left",
                display: "flex",
                width:"100%"
            }}>
            <TextField
            key="working_detail_sale_name"
            className ="inp-fld"
            label={lang.lotrinh.nhanvienSale} 
            variant="standard"
            value={lotrinh.saleName}
            InputProps={{
                startAdornment: <InputAdornment position="start"><Icon style={{color: orange[200]}}/></InputAdornment>
            }}
            />
            </div>
            <div className="flex-row">
            <SearchDropDown
            className ="inp-fld"
            style={{
                marginRight:35,
                width:250
            }}
            label={lang.lotrinh.khuvuc} 
            type="number"
            value={selectedKhuVuc}
            options = {(dsKhuvuc).map((option) => ({
            key: option.id,
            text: `${option.ten}`,
            secondary: option.khuvucName || ""
            }))}
            onChange={value => setSelectedKhuvuc(value)} 
            />

            <SearchDropDown
            className ="inp-fld"
            style={{
                marginRight:35,
                width:250
            }}
            label={lang.lotrinh.khunggio} 
            type="number"
            value={khunggio}
            options = {(dsKhunggio||[]).map((option) => ({
            key: option.id,
            text: `${option.startTime} - ${option.endTime}`
            }))}
            onChange={value => setKhunggio(value)} 
            />
            </div>
            <div className="flex-row">
        <TextField
        className ="inp-fld"
        style={{
            marginRight: 35,
            width:250
        }}
        label={lang.lotrinh.songaydangky} 
        variant="standard"
        type="number"
        value={songaydangky}
        onInput={e => setSongaydangky(e.target.value)} 
        InputProps={{
        endAdornment: <InputAdornment position="end">{lang.generaltext.buoi}</InputAdornment>
        }}
        />
        <SearchDropDown 
        id="searchbox_taixe"
        className="inp-fld"
        value={taixe}
        label={lang.lotrinh.taixe}
        options= {dsTaixe.map(t => ({
            key: t.usrId,
            text: t.name,
            secondary: t.phoneNumber
        }))}
        onChange ={value => setTaixe(value)}
        icon ={<Icon style={{color: lightGreen[600]}}/>}
        />
        </div>
        <div className="flex-row">
        <TextField
        className ="inp-fld"
        fullWidth= {true}
        label={lang.sale_working_plan.workingplace} 
        variant="standard"
        type="text"
        value={workingPlace}
        onInput={e => setWorkingPlace(e.target.value)} 
        />
        </div>
        <div className="flex-row">
        <TextField
        className ="inp-fld"
        fullWidth= {true}
        label={lang.sale_working_plan.reportemail} 
        variant="standard"
        type="email"
        value={reportEmail}
        onInput={e => setReportEmail(e.target.value)} 
        />
        </div>
       </div>
</div>

return <Dialog
className="dialog-a-visible"
okText ={lang.buttons.luu}
cancelText={lang.buttons.boqua}
disabledYes={!canSave()}
open={open}
onYes={evt => setSaveChanges(true)}
onClose={evt => setOpenPlanDetail(false)}
msgContent={content}
/>
}