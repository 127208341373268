import React from "react";
import { useEffect, useState } from "react";
import CustomerService from "../../../services/CustmerService";
import DataGrid  from "../../../Components/DataTable";
import lang from "../../../asset/Language.json"
import RefreshIcon from '@mui/icons-material/RefreshOutlined';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import ManageAccountsIcon from '@mui/icons-material/AccountBox';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Button } from "@mui/material";
import CustomerDetail from "./CustomerDetail";
import CreateAccount from "../../../Components/TaoTaiKhoan"
import { UserTypeEnum } from "../../../Contants/UserTypeEnum";
import Dialog from "../../../Components/ConfirmDialog"


const columns = [
    { field: 'id',
     headerName: lang.StaffList.stt,
     width: 60,sortable: false
    },
    {
      field: 'cusName',
      headerName: lang.StaffList.Hoten,
      width: 300,
      sortable: false
    },
    {
      field: 'cusPhone',
      headerName: lang.StaffList.SDT,
      width: 180,
      sortable: false
    },
    {
      field: 'cusAddress',
      headerName: lang.StaffList.Diachi,
      sortable: false,
      width: 460,
      
    },
    {
      field: 'cusEmail',
      headerName: lang.Khachhang.email,
      sortable: false,
      width: 360,
      
    },
    {
      field: 'cusContact',
      headerName: lang.Khachhang.contact,
      sortable: false,
      width: 110,
      
    }
  ];
export default function CustomerList(props) {
    const { setExtraTitle} = props;
    const[totalCus, setTotalCus] = useState(0);
    const[customers, setCustomers] = useState([]);
    const[refresh, setRefresh] = useState(true);
    const[pageIndex, setPageIndex]  = useState(0)
    const [selectedCus, setSelectedCus] = useState()
    const [openDetail, setOpenDetail] = useState(false)
    const [showMsg, setShowMsg] = useState(false);
    const [deleteCus, setDeleteCus] =useState(false);
    const [createAccount, setCreateAccount] = useState(false)
    const [showLoading, setShowLoading] = useState(false)
    const [openNhanvienList, setOpenNhanvienList] = useState(false);

    const handleOpenDetail = (cus) => {
        setSelectedCus(cus);
        setOpenDetail(true);
    } 

    const pageSize = 15;
    const cSV = new CustomerService();

    useEffect( async () => {
        if(!deleteCus) {
          return;
        }
        setDeleteCus(false);
        const r  = await cSV.deleteCustomer(selectedCus.cusId)
        setSelectedCus();
        setRefresh(!refresh)
      },[deleteCus])

      
    useEffect(async() => {
        setShowLoading(true)
        const r = await cSV.getListCustomer();
        if(r.error) {
            console.log(`get customers error`);
            return;
        }

        setTotalCus(r.length);
        setCustomers(r.map((c,idx) => Object.assign({},c, {id : pageIndex*pageSize + idx + 1} )));
        setShowLoading(false)
    },[refresh]);

    return <div className="main-content">
        <div  className="page-header">
        <div className="page-title"><span>{lang.lotrinh.danhsachkhachhang}</span></div>
        <div>
        <Button className="btn-icon" disabled={!selectedCus || createAccount} title={lang.Khachhang.taotaikhoan} variant="contained" onClick={evt => setCreateAccount(true)}>
                  <ManageAccountsIcon/>
            </Button>
            <Button className="btn-icon" variant="contained" title={lang.Khachhang.addKH} onClick={evt => {
                setSelectedCus({});
                setOpenDetail(true)
                }}><PersonAddOutlinedIcon/></Button>
            <Button className="btn-icon" variant="contained" disabled={!selectedCus} onClick={evt => setShowMsg(true)}><DeleteIcon/></Button>
            <Button variant="text" className="btn-text" title="Refresh" onClick={evt => setRefresh(!refresh)}><RefreshIcon/>Refresh</Button>
        </div>
            
        </div>
        
        { <DataGrid
            tableCellStyle={{
              textAlign: "left"
          }}
            rows={customers}
            columns={columns}
            pageSize={pageSize}
            pagination={true}
            paginationMode={'client'}
            loading={showLoading}
            rowCount={totalCus}
            onRowClick={rowData =>{
            setSelectedCus(rowData.row);
            setExtraTitle(rowData.row.cusName);
            }}
            onRowDoubleClick={row => handleOpenDetail(row.row)}
        />}
        {openDetail && <CustomerDetail cus={selectedCus} onSaved ={result => {
            setOpenDetail(!result);
            if(result) {
                setRefresh(!refresh);
            }
        }} onClose={evt => setOpenDetail(false)}/>}
        {createAccount && <CreateAccount onClose={evt => {
          setCreateAccount(false);
          setRefresh(!refresh);
          }} user= {Object.assign({},selectedCus, {usrId: selectedCus.cusId, name: selectedCus.cusName, usrTypeId: UserTypeEnum.Customer})} refresh={refresh} setRefresh={setRefresh} 
          disabledRole={true} defaultRole={UserTypeEnum.Customer}/>}
          {selectedCus && <Dialog id="xoanhanvienConfirm" onClose={value => setShowMsg(false)} 
       open={showMsg} 
       title={lang.StaffList.xoastaffconfirm}
       msgContent= {selectedCus.cusName}
       okText = {lang.buttons.co}
       cancelText = {lang.buttons.boqua}
       onYes = {evt => {
        setShowMsg(false); 
        setDeleteCus(true);
        }}
        
       />}
    </div>

}