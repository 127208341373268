import lang from "../../asset/Language.json"
import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react"
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import UserService from "../../services/UserService";
import { isMobile } from "react-device-detect";
import { UserTypes } from "../../Contants/UserTypeEnum";
import Dialog from "../../Components/ConfirmDialog"

const useStyles = makeStyles({
  header: {
},
fullWidth: {
  display:"flex",
  "flex-direction": "column",
  flex: 1,
  maxWidth:750,
  backgroundColor: "#fff"
  
},
flexrow: {
  display:"flex",
  "flex-wrap": "wrap",
  "align-items": "start",
  justifyContent: "start",
  "& .MuiFormControl-root": isMobile ? {
    width: "100%"
  }: {
    "margin-right": "30px"
  }
},
flex: {
  flex: "1 1 auto",
  padding:"20px 0 0 0"
},
alignLeft: {
  "text-align": "left"
  
},
formField: {
    "margin-bottom": "15px"
},
detailContent: {
  maxwidth:"750px",
  width: "100%"
},
flexEnd: {
  display: "flex",
  justifyContent: "end",
  padding: "20px 0 0 0"
},
nopadding:{
  padding:0
},
flexNoWrap:{
  display:"flex",
  padding: "20px"
},
paddingTop: {
  paddingTop: 30
},
dropdown:{
  display: "flex",
  "& .MuiFormControl-root": {
    width: "250px"
  }
}

});

const NhanvienDetail = (props) => {
    const classes = useStyles();
    let {id, row} = props.nhanvienInfo || { row: {}};
    
    const [DOB, setDOB] = useState(row.DOB);
    const [hoten, setHoten] = useState(row.name);
    const [phone, setPhone] = useState(row.phoneNumber);
    const [email, setEmail] = useState(row.email);
    const [cmnd, setCmnd] = useState(row.personalNr);
    const [diachi, setDiachi] = useState(row.address);
    const [ward, setWard] = useState(row.ward);
    const [district, setDistrict] = useState(row.district);
    const [province, setProvince] = useState(row.province);
    const [saveChanges, setSaveChanges] = useState(false);
    const [department, setDepartment] = useState([]);
    const[selectedDept, setSelectedDept] = useState(row.deptId);
    const[selectedRole, setSelectedRole] = useState(row.chucvu || ( row.deptId == 1 ? 4 : row.deptId == 2 ? 6 : 2));
    const[error, setError] = useState(false);
    

    const uSv = new UserService();
    

    useEffect(async() =>{
      const d = await uSv.getDepartments();
      if(!d || d.error) {
        console.log(d.error);
        return;
      }
      setDepartment(d);
    },[]);

    useEffect(async () => {
      if(saveChanges) {
          //update/add staff
          const reqData = {
            usrId: row.usrId,
           name: hoten,
            email: email,
            DOB: DOB,
            chucvu:  selectedRole || 6,
            personalNr: cmnd,
            phoneNumber: phone,
            address: diachi,
            cusId: row.cusId,
            deptId: parseInt(selectedDept || row.deptId),//shipper
            district: district || 0,
            province: province || 0,
            ward: ""
          };
          const  u = row.usrId ? await uSv.updateStaff(reqData) : await uSv.addStaff(reqData);

          if(u.error) {
              setError(true);
          }
          props.onClose(true);
      }
      
      setSaveChanges(false);
      return() => {

      }
    },[saveChanges]);

    return <Dialog
    open={true}
    okText={lang.buttons.luu}
    title={lang.StaffList.NhanvienDetailTitle}
    disabledYes={!hoten || !phone}
    onYes={evt => setSaveChanges(true)}
    onClose={evt => props.onClose(false)}
    msgContent ={<div className={clsx({[classes.flexrow]: true, [classes.flex]: true})}>
    <div className={classes.fullWidth}>
    <div className={classes.detailContent}>
      <div className={classes.flexrow}>
    
        <TextField id="hoten" 
          variant="standard" 
          required={true}
          value={hoten}
          onInput={evt => setHoten(evt.target.value)}
          label={lang.StaffList.Hoten} 
          className={classes.formField}
          />
          <TextField id="phone" 
          required={true}
        variant="standard" 
        type="number"
        value={phone}
        onInput={evt => setPhone(evt.target.value)}
        label={lang.StaffList.SDT} 
        className={classes.formField}
        onFocus={e => setError(false)}
        />
      </div>
      <div className={classes.flexrow} >
      <TextField id="email" 
        variant="standard" 
        type="email"
        value={email}
        onFocus={e => setError(false)}
        onInput={evt => setEmail(evt.target.value)}
        label={lang.StaffList.email} 
        className={classes.formField}
        />
      </div>
      <div className={classes.flexrow}>
      <TextField id="cmnd" 
      type="number"
      style={{width: "45%"}}
      onFocus={e => setError(false)}
          variant="standard"
          label={lang.StaffList.CMND} 
          value={cmnd}
          onInput={evt => setCmnd(evt.target.value)}
          className={classes.formField}
          />
          <TextField
          id="chucvu"
          style={{width: "45%"}}
          className={classes.customerBox}
          select
          label={lang.StaffList.chucvu}
          value= {selectedRole}
          onChange={e => {
            setSelectedRole(e.target.value);
          }}
          SelectProps={{
              native: true,
          }}
          helperText=""
          variant="standard"
          color="primary"
          >
          {(UserTypes).map((option) => (
              <option key={option.key} value={option.key}>
              {option.text}
              </option>
          ))}
      </TextField>
          <TextField
          id="dsphongban"
          style={{width: "45%"}}
          className={classes.customerBox}
          select
          label={lang.StaffList.phongban}
          value= {selectedDept}
          onChange={e => {
            setSelectedDept(e.target.value);
          }}
          SelectProps={{
              native: true,
          }}
          helperText=""
          variant="standard"
          color="primary"
          >
          {(department).map((option) => (
              <option key={option.deptId} value={option.deptId}>
              {option.deptName}
              </option>
          ))}
      </TextField>
      
        <TextField id="diachi" 
          variant="standard" 
          onFocus={e => setError(false)}
          fullWidth={true}
          value={diachi}
          onInput={evt => setDiachi(evt.target.value)}
          label={lang.StaffList.Diachi} 
          className={classes.formField}
          />
          
      </div>
    </div>
    </div>
    
  </div>}
    />;

}

export default NhanvienDetail;